import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

export interface GuidsPayload {
	label: string
	_id: string
}

export enum ActionsTypes {
	GET_GUIDS = 'GET_GUIDS',
	GET_GUIDS_SUCCESS = 'GET_GUIDS_SUCCESS',
	GET_GUIDS_FAILED = 'GET_GUIDS_FAILED',
}
export const HelpersActions = {
	GET_GUIDS: actionCreator(ActionsTypes.GET_GUIDS),
	GET_GUIDS_SUCCESS: actionCreator<GuidsPayload[]>(ActionsTypes.GET_GUIDS_SUCCESS),
	GET_GUIDS_FAILED: actionCreator(ActionsTypes.GET_GUIDS_FAILED),
}

export type HelpersActionsTypes =
	| ReturnType<typeof HelpersActions.GET_GUIDS>
	| ReturnType<typeof HelpersActions.GET_GUIDS_SUCCESS>
	| ReturnType<typeof HelpersActions.GET_GUIDS_FAILED>
