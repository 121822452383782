import apiClient from 'services/api'
import { ChangeRolePayload, CreateTeamModel, EditTeamModel } from '../../store/teams/types'
import { TeamInviteModel } from '../../models/team-invite'

export async function getTeam(refId: string) {
	return apiClient
		.get(`/team/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function userTeams() {
	return apiClient
		.get(`/userTeams`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function playerTeams(refId: string) {
	return apiClient
		.get(`/playerTeams/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function createTeam(team: CreateTeamModel) {
	return apiClient
		.post(`/team`, team)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function editTeam(team: EditTeamModel) {
	return apiClient
		.put(`/team/${team.refId}`, team)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function invitePlayers(players: TeamInviteModel) {
	return apiClient
		.post(`/invite`, players)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No invite players data')
		})
		.catch(err => {
			throw err
		})
}

export async function changeRole(change: ChangeRolePayload) {
	return apiClient
		.put(`/profile/role/${change.profileRefId}`, { role: change.newRole })
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No change role response data')
		})
		.catch(err => {
			throw err
		})
}

export async function kickPlayer(refId: string) {
	return apiClient
		.delete(`/profile/remove/${refId}`, {})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No change role response data')
		})
		.catch(err => {
			throw err
		})
}

export async function deleteTeam(refId: string) {
	return apiClient
		.delete(`/team/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}
