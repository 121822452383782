import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { MatchActions, MatchState } from './types'

const matchesInitialState: MatchState = {
	currentMatch: null,
	matches: [],
	competitionMatches: [],
	loading: false,
	error: null,
}

const MatchesReducer = reducerWithInitialState(matchesInitialState)
	// LOADING CASES
	.case(MatchActions.GET_MATCH, state => ({ ...state, loading: true }))
	.case(MatchActions.GET_TEAM_MATCHES, state => ({ ...state, loading: true }))
	.case(MatchActions.UPDATE_MATCH, state => ({ ...state, loading: true }))
	.case(MatchActions.GET_COMPETITION_MATCHES, state => ({ ...state, loading: true }))
	.case(MatchActions.ADD_MATCH_REPORT, (state, news) => ({
		...state,
		loading: false,
	}))
	.case(MatchActions.GET_TOURNAMENT_MATCHES, (state, news) => ({
		...state,
		loading: false,
	}))
	// SUCCESS CASES
	.case(MatchActions.GET_MATCH_SUCCESS, (state, currentMatch) => ({
		...state,
		currentMatch,
		loading: false,
	}))
	.case(MatchActions.GET_TOURNAMENT_MATCHES_SUCCESS, (state, matches) => ({
		...state,
		matches,
		loading: false,
	}))
	.case(MatchActions.GET_TEAM_MATCHES_SUCCESS, (state, matches) => ({
		...state,
		matches,
		loading: false,
	}))
	.case(MatchActions.GET_COMPETITION_MATCHES_SUCCESS, (state, competitionMatches) => ({
		...state,
		competitionMatches,
		loading: false,
	}))
	.case(MatchActions.UPDATE_MATCH_SUCCESS, (state, currentMatch) => ({
		...state,
		currentMatch,
		loading: false,
	}))
	.case(MatchActions.ADD_MATCH_REPORT_SUCCESS, (state, news) => ({
		...state,
		loading: false,
	}))

	// FAILED CASES
	.case(MatchActions.GET_TOURNAMENT_MATCHES_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(MatchActions.GET_MATCH_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(MatchActions.UPDATE_MATCH_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(MatchActions.GET_TEAM_MATCHES_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(MatchActions.ADD_MATCH_REPORT_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(MatchActions.GET_COMPETITION_MATCHES_FAILED, (state, error) => ({ ...state, error, loading: false }))

	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default MatchesReducer
