import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import {
	getLadder,
	getLadderLatestMatches,
	getLadderUpcomingMatches,
	joinLadder,
	leaveLadder,
	updateLadder,
} from 'services/ladders'
import { MatchModel } from 'models/match'
import { RefIdPayload, JoinLadderPayload, LaddersActions, LaddersActionsTypes, UpdateLadderRulesPayload } from './types'
import { LadderModel } from '../../models'
import { RawDraftContentState } from 'draft-js'
import ladders from './reducers'
import { history } from '../index'

function* GET_LADDER(action: LaddersActionsTypes) {
	const { refId } = action.payload as RefIdPayload
	try {
		// @ts-ignore
		const ladderData = yield call(getLadder, refId)
		yield put({
			type: LaddersActions.GET_LADDER_SUCCESS.toString(),
			payload: ladderData,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: LaddersActions.GET_LADDER_FAILED.toString(),
			payload: error,
		})
	}
}

function* JOIN_LADDER(action: LaddersActionsTypes) {
	const ladderInfo = action.payload as JoinLadderPayload
	try {
		yield call(joinLadder, ladderInfo)
		yield put({
			type: LaddersActions.GET_LADDER.toString(),
			payload: { refId: ladderInfo.ladderId },
		})
		yield put({
			type: LaddersActions.JOIN_LADDER_SUCCESS.toString(),
		})
		history.go(0)
	} catch (error) {
		notification.warning({
			message: error.message,
		})

		yield put({
			type: LaddersActions.JOIN_LADDER_FAILED.toString(),
			payload: error,
		})
	}
}

function* LEAVE_LADDER(action: LaddersActionsTypes) {
	console.log('LEAVE_LADDER')
	const teamLadderRefId = action.payload as string
	try {
		yield call(leaveLadder, teamLadderRefId)
		yield put({
			type: LaddersActions.LEAVE_LADDER_SUCCESS.toString(),
		})
		history.go(0)
	} catch (error) {
		notification.warning({
			message: error.message,
		})

		yield put({
			type: LaddersActions.LEAVE_LADDER_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_UPCOMING_LATEST_LADDER_MATCHES(action: LaddersActionsTypes) {
	const { refId } = action.payload as RefIdPayload
	try {
		const [upcoming, latest]: MatchModel[][] = yield all([
			call(getLadderUpcomingMatches, refId),
			call(getLadderLatestMatches, refId),
		])
		yield put({
			type: LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS.toString(),
			payload: { upcoming, latest },
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED.toString(),
			payload: error,
		})
	}
}

function* UPDATE_LADDER(action: LaddersActionsTypes) {
	const data = action.payload as UpdateLadderRulesPayload
	const { currentLadder } = yield select(state => state.ladders)
	try {
		const updatedLadder: LadderModel = yield call(updateLadder, data)
		yield put({
			type: LaddersActions.UPDATE_LADDER_SUCCESS.toString(),
			payload: {
				...currentLadder,
				rules: updatedLadder.rules,
			},
		})
		notification.success({
			message: 'Ladder rules updated successfully',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: LaddersActions.UPDATE_LADDER_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(LaddersActions.GET_LADDER, GET_LADDER),
		takeLatest(LaddersActions.LEAVE_LADDER, LEAVE_LADDER),
		takeEvery(LaddersActions.JOIN_LADDER, JOIN_LADDER),
		takeEvery(LaddersActions.UPDATE_LADDER, UPDATE_LADDER),
		takeLatest(LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES, GET_UPCOMING_LATEST_LADDER_MATCHES),
	])
}
