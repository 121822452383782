import apiClient from 'services/api'
import { LadderModel } from 'models/ladder'
import { JoinLadderPayload, UpdateLadderRulesPayload } from 'store/ladders/types'

export async function getLadder(refId: string) {
	return apiClient
		.get(`/ladder/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No ladder response data')
		})
		.catch(err => {
			throw err
		})
}

export async function addLadder(ladder: LadderModel) {
	return apiClient
		.post(`/ladder`, ladder)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function joinLadder(ladder: JoinLadderPayload) {
	return apiClient
		.post(`/teamLadder`, ladder)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function getLadderUpcomingMatches(refId: string) {
	return apiClient
		.get(`/ladder/${refId}/upcoming`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No ladder upcoming response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getLadderLatestMatches(refId: string) {
	return apiClient
		.get(`/ladder/${refId}/latest`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No ladder latest response data')
		})
		.catch(err => {
			throw err
		})
}

export async function updateLadder(data: UpdateLadderRulesPayload) {
	return apiClient
		.put(`/ladder/${data.ladder.refId}`, { rules: data.rules })
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function leaveLadder(teamLadderRefId: string) {
	return apiClient
		.delete(`/teamLadder/${teamLadderRefId}`, {})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No deleted response data')
		})
		.catch(err => {
			throw err
		})
}
