import actionCreatorFactory from 'typescript-fsa'
import { CommentModel, CommentType } from 'models/comments'

const actionCreator = actionCreatorFactory()

export interface CommentPayload {
	user: string
	profile: string
	parent: string
	relatedTo: string
	content: string
	type: CommentType
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}
export interface GetCommentsPayload {
	entityId: string
	type: CommentType
}

export enum ActionsTypes {
	ADD_COMMENT = 'ADD_COMMENT',
	ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS',
	ADD_COMMENT_FAILED = 'ADD_COMMENT_FAILED',
	EDIT_COMMENT = 'EDIT_COMMENT',
	EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS',
	EDIT_COMMENT_FAILED = 'EDIT_COMMENT_FAILED',
	DELETE_COMMENT = 'DELETE_COMMENT',
	DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS',
	DELETE_COMMENT_FAILED = 'DELETE_COMMENT_FAILED',
	GET_COMMENTS = 'GET_COMMENTS',
	GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS',
	GET_COMMENTS_FAILED = 'GET_COMMENTS_FAILED',
}

export const CommentActions = {
	ADD_COMMENT: actionCreator<CommentPayload>(ActionsTypes.ADD_COMMENT),
	ADD_COMMENT_SUCCESS: actionCreator<CommentModel[]>(ActionsTypes.ADD_COMMENT_SUCCESS),
	ADD_COMMENT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.ADD_COMMENT_FAILED),
	EDIT_COMMENT: actionCreator<CommentModel>(ActionsTypes.EDIT_COMMENT),
	EDIT_COMMENT_SUCCESS: actionCreator<CommentModel[]>(ActionsTypes.EDIT_COMMENT_SUCCESS),
	EDIT_COMMENT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.EDIT_COMMENT_FAILED),
	DELETE_COMMENT: actionCreator<CommentModel>(ActionsTypes.DELETE_COMMENT),
	DELETE_COMMENT_SUCCESS: actionCreator<CommentModel[]>(ActionsTypes.DELETE_COMMENT_SUCCESS),
	DELETE_COMMENT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.DELETE_COMMENT_FAILED),
	GET_COMMENTS: actionCreator<GetCommentsPayload>(ActionsTypes.GET_COMMENTS),
	GET_COMMENTS_SUCCESS: actionCreator<CommentModel[]>(ActionsTypes.GET_COMMENTS_SUCCESS),
	GET_COMMENTS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_COMMENTS_FAILED),
}

export type CommentsActionsTypes =
	| ReturnType<typeof CommentActions.ADD_COMMENT>
	| ReturnType<typeof CommentActions.ADD_COMMENT_SUCCESS>
	| ReturnType<typeof CommentActions.ADD_COMMENT_FAILED>
	| ReturnType<typeof CommentActions.EDIT_COMMENT>
	| ReturnType<typeof CommentActions.EDIT_COMMENT_SUCCESS>
	| ReturnType<typeof CommentActions.EDIT_COMMENT_FAILED>
	| ReturnType<typeof CommentActions.DELETE_COMMENT>
	| ReturnType<typeof CommentActions.DELETE_COMMENT_SUCCESS>
	| ReturnType<typeof CommentActions.DELETE_COMMENT_FAILED>
	| ReturnType<typeof CommentActions.GET_COMMENTS>
	| ReturnType<typeof CommentActions.GET_COMMENTS_SUCCESS>
	| ReturnType<typeof CommentActions.GET_COMMENTS_FAILED>
