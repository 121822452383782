import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { NotificationsActions } from './types'
import { NotificationModel } from '../../models/notification'

export interface NotificationState {
	notifications: NotificationModel[]
	loading: boolean
	error: unknown | null
}

const NotificationsInitialState: NotificationState = {
	notifications: [],
	loading: false,
	error: null,
}

const notificationsReducer = reducerWithInitialState(NotificationsInitialState)
	// LOADING CASES
	.case(NotificationsActions.GET_NOTIFICATIONS, state => ({ ...state, loading: true }))
	.case(NotificationsActions.DELETE_NOTIFICATION, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(NotificationsActions.GET_NOTIFICATIONS_SUCCESS, (state, notifications) => ({
		...state,
		notifications,
		loading: false,
	}))
	// SUCCESS CASES
	.case(NotificationsActions.UPDATE_NOTIFICATIONS_SUCCESS, (state, notifications) => ({
		...state,
		notifications,
		loading: false,
	}))
	.case(NotificationsActions.UPDATE_NOTIFICATION_SUCCESS, (state, notifications) => ({
		...state,
		notifications,
		loading: false,
	}))
	.case(NotificationsActions.DELETE_ALL_NOTIFICATIONS_SUCCESS, (state, notifications) => ({
		...state,
		notifications,
		loading: false,
	}))
	.case(NotificationsActions.READ_ALL_NOTIFICATIONS_SUCCESS, (state, notifications) => ({
		...state,
		notifications,
		loading: false,
	}))
	.case(NotificationsActions.DELETE_NOTIFICATION_SUCCESS, (state, notifications) => ({
		...state,
		notifications,
		loading: false,
	}))
	.case(NotificationsActions.NEW_SUBSCRIBED_NOTIFICATION, (state, newNotification) => ({
		...state,
		notifications: [...state.notifications, newNotification],
		loading: false,
	}))
	// FAILED CASES
	.case(NotificationsActions.GET_NOTIFICATIONS_FAILED, state => ({ ...state, loading: false }))
	.case(NotificationsActions.DELETE_NOTIFICATION_FAILED, state => ({ ...state, loading: false }))
	.case(NotificationsActions.READ_ALL_NOTIFICATIONS_FAILED, state => ({ ...state, loading: false }))

	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default notificationsReducer
