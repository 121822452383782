import apiClient from 'services/api'
import { CommentModel } from '../../models/comments'
import { GetCommentsPayload } from '../../store/comments/types'

export async function getComments(data: GetCommentsPayload) {
	return apiClient
		.get(`/comments/`, {
			params: {
				entityId: data.entityId,
				type: data.type,
			},
		})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No get challenges response data')
		})
		.catch(err => {
			throw err
		})
}

export async function addComment(comment: any) {
	return apiClient
		.post(`/comment`, comment)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function addReplyToComment(comment: any) {
	return apiClient
		.post(`/comment/reply/${comment.parent}`, comment)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function editComment(comment: CommentModel) {
	return apiClient
		.put(`/comment/${comment._id}`, comment)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function deleteComment(commentId: string) {
	return apiClient
		.delete(`/comment/${commentId}`, {})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No change role response data')
		})
		.catch(err => {
			throw err
		})
}
