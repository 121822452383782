import actionCreatorFactory from 'typescript-fsa'
import { UserModel } from '../../models/user'

const actionCreator = actionCreatorFactory()

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export enum ActionsTypes {
	GET_PLAYERS = 'GET_PLAYERS',
	GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS',
	GET_PLAYERS_FAILED = 'GET_PLAYERS_FAILED',
}
export const PlayersActions = {
	GET_PLAYERS: actionCreator(ActionsTypes.GET_PLAYERS),
	GET_PLAYERS_SUCCESS: actionCreator<UserModel[]>(ActionsTypes.GET_PLAYERS_SUCCESS),
	GET_PLAYERS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_PLAYERS_FAILED),
}

export type PlayersActionsTypes =
	| ReturnType<typeof PlayersActions.GET_PLAYERS>
	| ReturnType<typeof PlayersActions.GET_PLAYERS_SUCCESS>
	| ReturnType<typeof PlayersActions.GET_PLAYERS_FAILED>
