import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/analytics'
import * as AmplitudeJS from 'amplitude-js'
import socket from '../sockets'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()
export const firebaseDatabase = firebase.database()

// FIREBASE ANALYTICS
export const analytics = firebase.analytics()

// AMPLITUDE ANALYTICS
AmplitudeJS.getInstance().init(process.env.REACT_APP_AMPLITUDE!)
export const amplitude = AmplitudeJS.getInstance()

export async function login(email: string, password: string) {
	return firebaseAuth
		.signInWithEmailAndPassword(email, password)
		.then(() => true)
		.catch(error => {
			throw error
		})
}

export async function register(email: string, password: string, name: any) {
	return firebaseAuth
		.createUserWithEmailAndPassword(email, password)
		.then(async response => {
			if (response.user) {
				return response.user
			}
			throw new Error('Response but no user')
		})
		.catch(error => {
			throw error
		})
}

export async function discordLogin(token: string) {
	return firebaseAuth
		.signInWithCustomToken(token)
		.then(async response => {
			if (response.user) {
				return response.user
			}
			throw new Error('Response but no user')
		})
		.catch(error => {
			throw error
		})
}

export async function updateUserPassword(newPassword: string) {
	return new Promise((resolve, reject) => {
		const user = firebaseAuth.currentUser
		if (!user) {
			reject(new Error('No user token'))
		} else {
			user
				.updatePassword(newPassword)
				.then(() => {
					resolve(true)
				})
				.catch(error => {
					reject(new Error(error))
				})
		}
	})
}

export async function currentAccount() {
	return new Promise((resolve, reject) => {
		firebaseAuth.onAuthStateChanged(
			user => {
				if (!user) {
					socket.io.opts.query = { token: '' }
					reject(new Error('No user token'))
				} else {
					user.getIdToken().then(token => {
						console.log('::::::::::::::::::::: FIREBASE GOT TOKEN :::::::::::::::: ')
						socket.io.opts.query = { token: token ?? '' }
						socket.connect()
					})
				}
				resolve(true)
			},
			err => {
				reject(err)
			},
		)
	})
}

export async function forgotPassword(email: string) {
	return firebaseAuth
		.sendPasswordResetEmail(email)
		.then(() => {
			// Password reset email sent!
			// ..
		})
		.catch(error => {
			throw error
		})
}

export async function logout() {
	return firebaseAuth.signOut().then(() => socket.disconnect())
}
