import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { getPlayer } from 'services/player'
import { playerTeams } from 'services/teams'
import { PlayerActions, PlayerActionsTypes, GetPlayerPayload, PlayerPayload, GetPlayerTeams } from './types'

function* GET_PLAYER(action: PlayerActionsTypes) {
	const { refId } = action.payload as GetPlayerPayload
	try {
		// @ts-ignore
		const userData = yield call(getPlayer, refId)
		yield put({
			type: PlayerActions.GET_PLAYER_SUCCESS.toString(),
			payload: userData,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
	}
}

function* GET_PLAYER_TEAMS(action: PlayerActionsTypes) {
	const { refId } = action.payload as GetPlayerTeams
	try {
		const teams: PlayerPayload = yield call(playerTeams, refId)
		yield put({
			type: PlayerActions.GET_PLAYER_TEAMS_SUCCESS.toString(),
			payload: teams,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: PlayerActions.GET_PLAYER_TEAMS_FAILED.toString(),
			payload: error.message,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(PlayerActions.GET_PLAYER, GET_PLAYER),
		takeEvery(PlayerActions.GET_PLAYER_TEAMS, GET_PLAYER_TEAMS),
	])
}
