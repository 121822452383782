// middlewared
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import loggerMiddleware from './middlewares'
import logger from 'redux-logger'

import { AppState, Reducers } from './reducers'
import sagas from './sagas'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
	}
}

let rehydrationComplete: any
// let rehydrationFailed

const rehydrationPromise = new Promise(resolve => {
	rehydrationComplete = resolve
	// rehydrationFailed = reject
})

export function rehydration() {
	return rehydrationPromise
}

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const loggers = process.env.NODE_ENV !== 'production' ? [loggerMiddleware, logger] : []

const middlewares = [sagaMiddleware, routeMiddleware, ...loggers]

const persistConfig = {
	key: 'root',
	whitelist: ['user', 'chats'],
	storage,
	stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
}

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }

// @ts-ignore

const resetEnhancer = rootReducer => (state, action) => {
	if (action.type !== 'LOGOUT_SUCCESS') return rootReducer(state, action)

	const newState = rootReducer(undefined, {})
	newState.router = state.router
	newState.menu = state.menu
	newState.settings = state.settings
	// newState.teams = state.teams
	// newState.player = state.player
	return newState
}

export type AppDispatch = typeof store.dispatch

const persistedReducer = persistReducer(persistConfig, resetEnhancer(Reducers(history)))

const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: middlewares,
})

sagaMiddleware.run(sagas)
const persistor = persistStore(store, null, () => {
	// this will be invoked after rehydration is complete
	rehydrationComplete()
})
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector
export { store, history, persistor }
