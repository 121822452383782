import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import {
	cancelChallenge,
	challenge,
	getChallenge,
	getChallenges,
	getCompetitionChallenges,
	handleChallenge,
	forceChallenge,
} from 'services/challenges'
import { ChallengeModel } from 'models/challenge'
import { v4 as uuidv4 } from 'uuid'
import { ChallengePayload, ChallengesActions, ChallengesActionsTypes } from './types'
import { history } from '../index'
import { NotificationModel, NotificationType } from '../../models/notification'
import { NotificationsActions } from '../notifications/types'

function* CHALLENGE(action: ChallengesActionsTypes) {
	const challengeInfo = action.payload as ChallengePayload
	const { user } = yield select(state => state)
	challengeInfo.refId = uuidv4()
	try {
		yield call(challenge, challengeInfo)
		yield put({
			type: ChallengesActions.CHALLENGE_SUCCESS.toString(),
		})
		notification.success({
			message: 'Challenge Submitted',
		})
		history.push(`/team/${user.activeProfile.team.refId}/challenges`)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChallengesActions.CHALLENGE_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_CHALLENGES(action: ChallengesActionsTypes) {
	const teamId = action.payload as string
	try {
		const challenges: ChallengeModel[] = yield call(getChallenges, teamId)
		yield put({
			type: ChallengesActions.GET_CHALLENGES_SUCCESS.toString(),
			payload: challenges,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChallengesActions.GET_CHALLENGES_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_COMPETITION_CHALLENGES(action: ChallengesActionsTypes) {
	const competitionId = action.payload as string
	try {
		const challenges: ChallengeModel[] = yield call(getCompetitionChallenges, competitionId)
		yield put({
			type: ChallengesActions.GET_COMPETITION_CHALLENGES_SUCCESS.toString(),
			payload: challenges,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChallengesActions.GET_COMPETITION_CHALLENGES_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_CHALLENGE(action: ChallengesActionsTypes) {
	const challengeId = action.payload as string
	try {
		const singleChallenge: ChallengeModel = yield call(getChallenge, challengeId)
		yield put({
			type: ChallengesActions.GET_CHALLENGE_SUCCESS.toString(),
			payload: singleChallenge,
		})
	} catch (error) {
		notification.warning({
			message: 'Challenge not Found',
		})
		yield put({
			type: ChallengesActions.GET_CHALLENGE_FAILED.toString(),
			payload: error,
		})
		const { notifications } = yield select(state => state.notifications)
		const notificationFound = notifications.find((n: NotificationModel) => n.relatedId === challengeId)
		if (notificationFound) {
			yield put({
				type: NotificationsActions.DELETE_NOTIFICATION.toString(),
				payload: notificationFound.refId,
			})
		}
		history.goBack()
	}
}

function* HANDLE_CHALLENGE(action: ChallengesActionsTypes) {
	const challengeInfo = action.payload as ChallengeModel
	const { user } = yield select(state => state)

	try {
		const challengeResponse: ChallengeModel = yield call(handleChallenge, challengeInfo)

		yield put({
			type: ChallengesActions.GET_CHALLENGES.toString(),
			payload: user.activeProfile.team.refId,
		})
		yield put({
			type: ChallengesActions.HANDLE_CHALLENGE_SUCCESS.toString(),
		})
		notification.success({
			message: 'Challenge Submitted',
		})
		if (challengeInfo.status === 'COMPLETED') {
			yield history.push(`/match/${challengeResponse.refId}`)
		} else {
			yield history.push(`/team/${user.activeProfile.team.refId}/challenges`)
		}
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChallengesActions.HANDLE_CHALLENGE_FAILED.toString(),
			payload: error,
		})
	}
}

function* FORCE_CHALLENGE(action: ChallengesActionsTypes) {
	const challengeInfo = action.payload as ChallengeModel

	try {
		const challengeResponse: ChallengeModel = yield call(forceChallenge, challengeInfo)
		yield put({
			type: ChallengesActions.FORCE_CHALLENGE_SUCCESS.toString(),
		})
		notification.success({
			message: 'Challenge Forced',
		})
		history.push(`/match/${challengeResponse.refId}`)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChallengesActions.FORCE_CHALLENGE_FAILED.toString(),
			payload: error,
		})
	}
}

function* CANCEL_CHALLENGE(action: ChallengesActionsTypes) {
	const challengeInfo = action.payload as ChallengeModel
	const { user } = yield select(state => state)

	try {
		yield call(cancelChallenge, challengeInfo)
		yield put({
			type: ChallengesActions.GET_CHALLENGES.toString(),
			payload: user.activeProfile.team.refId,
		})
		yield put({
			type: ChallengesActions.CANCEL_CHALLENGE_SUCCESS.toString(),
		})
		notification.success({
			message: 'Challenge Canceled',
		})
		yield history.push(`/team/${user.activeProfile.team.refId}/challenges`)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChallengesActions.CANCEL_CHALLENGE_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(ChallengesActions.CHALLENGE, CHALLENGE),
		takeLatest(ChallengesActions.GET_CHALLENGES, GET_CHALLENGES),
		takeLatest(ChallengesActions.GET_CHALLENGE, GET_CHALLENGE),
		takeLatest(ChallengesActions.HANDLE_CHALLENGE, HANDLE_CHALLENGE),
		takeLatest(ChallengesActions.CANCEL_CHALLENGE, CANCEL_CHALLENGE),
		takeLatest(ChallengesActions.GET_COMPETITION_CHALLENGES, GET_COMPETITION_CHALLENGES),
		takeLatest(ChallengesActions.FORCE_CHALLENGE, FORCE_CHALLENGE),
	])
}
