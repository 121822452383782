import actionCreatorFactory from 'typescript-fsa'
import { ChallengeModel } from 'models/challenge'
import { CompetitionType } from '../../models/match'

const actionCreator = actionCreatorFactory()

export interface ChallengePayload {
	refId?: string
	competition: string
	opponent: string
	date: string
	size: string
	length: string
	room: string
	password: string
	competitionType: CompetitionType
	challenger: string
	status: string
	count: number
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export enum ActionsTypes {
	CHALLENGE = 'CHALLENGE',
	CHALLENGE_SUCCESS = 'CHALLENGE_SUCCESS',
	CHALLENGE_FAILED = 'CHALLENGE_FAILED',
	GET_CHALLENGES = 'GET_CHALLENGES',
	GET_CHALLENGES_SUCCESS = 'GET_CHALLENGES_SUCCESS',
	GET_CHALLENGES_FAILED = 'GET_CHALLENGES_FAILED',
	GET_COMPETITION_CHALLENGES = 'GET_COMPETITION_CHALLENGES',
	GET_COMPETITION_CHALLENGES_SUCCESS = 'GET_COMPETITION_CHALLENGES_SUCCESS',
	GET_COMPETITION_CHALLENGES_FAILED = 'GET_COMPETITION_CHALLENGES_FAILED',
	GET_CHALLENGE = 'GET_CHALLENGE',
	GET_CHALLENGE_SUCCESS = 'GET_CHALLENGE_SUCCESS',
	GET_CHALLENGE_FAILED = 'GET_CHALLENGE_FAILED',
	HANDLE_CHALLENGE = 'HANDLE_CHALLENGE',
	HANDLE_CHALLENGE_SUCCESS = 'HANDLE_CHALLENGE_SUCCESS',
	HANDLE_CHALLENGE_FAILED = 'HANDLE_CHALLENGE_FAILED',
	FORCE_CHALLENGE = 'FORCE_CHALLENGE',
	FORCE_CHALLENGE_SUCCESS = 'FORCE_CHALLENGE_SUCCESS',
	FORCE_CHALLENGE_FAILED = 'FORCE_CHALLENGE_FAILED',
	CANCEL_CHALLENGE = 'CANCEL_CHALLENGE',
	CANCEL_CHALLENGE_SUCCESS = 'CANCEL_CHALLENGE_SUCCESS',
	CANCEL_CHALLENGE_FAILED = 'CANCEL_CHALLENGE_FAILED',
	REMOVE_CHALLENGE_NOTIFICATION = 'REMOVE_CHALLENGE_NOTIFICATION',
}
export const ChallengesActions = {
	CHALLENGE: actionCreator<ChallengePayload>(ActionsTypes.CHALLENGE),
	CHALLENGE_SUCCESS: actionCreator(ActionsTypes.CHALLENGE_SUCCESS),
	CHALLENGE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.CHALLENGE_FAILED),
	GET_CHALLENGES: actionCreator<string>(ActionsTypes.GET_CHALLENGES),
	GET_CHALLENGES_SUCCESS: actionCreator<ChallengeModel[]>(ActionsTypes.GET_CHALLENGES_SUCCESS),
	GET_CHALLENGES_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_CHALLENGES_FAILED),
	GET_COMPETITION_CHALLENGES: actionCreator<string>(ActionsTypes.GET_COMPETITION_CHALLENGES),
	GET_COMPETITION_CHALLENGES_SUCCESS: actionCreator<ChallengeModel[]>(ActionsTypes.GET_COMPETITION_CHALLENGES_SUCCESS),
	GET_COMPETITION_CHALLENGES_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_COMPETITION_CHALLENGES_FAILED),
	GET_CHALLENGE: actionCreator<string>(ActionsTypes.GET_CHALLENGE),
	GET_CHALLENGE_SUCCESS: actionCreator<ChallengeModel>(ActionsTypes.GET_CHALLENGE_SUCCESS),
	GET_CHALLENGE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_CHALLENGE_FAILED),
	HANDLE_CHALLENGE: actionCreator<ChallengeModel>(ActionsTypes.HANDLE_CHALLENGE),
	HANDLE_CHALLENGE_SUCCESS: actionCreator<ErrorPayload>(ActionsTypes.HANDLE_CHALLENGE_SUCCESS),
	HANDLE_CHALLENGE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.HANDLE_CHALLENGE_FAILED),
	FORCE_CHALLENGE: actionCreator<ChallengeModel>(ActionsTypes.FORCE_CHALLENGE),
	FORCE_CHALLENGE_SUCCESS: actionCreator<ErrorPayload>(ActionsTypes.FORCE_CHALLENGE_SUCCESS),
	FORCE_CHALLENGE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.FORCE_CHALLENGE_FAILED),
	CANCEL_CHALLENGE: actionCreator<ChallengeModel>(ActionsTypes.CANCEL_CHALLENGE),
	CANCEL_CHALLENGE_SUCCESS: actionCreator(ActionsTypes.CANCEL_CHALLENGE_SUCCESS),
	CANCEL_CHALLENGE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.CANCEL_CHALLENGE_FAILED),
}

export type ChallengesActionsTypes =
	| ReturnType<typeof ChallengesActions.CHALLENGE>
	| ReturnType<typeof ChallengesActions.CHALLENGE_SUCCESS>
	| ReturnType<typeof ChallengesActions.CHALLENGE_FAILED>
	| ReturnType<typeof ChallengesActions.GET_CHALLENGES>
	| ReturnType<typeof ChallengesActions.GET_CHALLENGES_SUCCESS>
	| ReturnType<typeof ChallengesActions.GET_CHALLENGES_FAILED>
	| ReturnType<typeof ChallengesActions.GET_COMPETITION_CHALLENGES>
	| ReturnType<typeof ChallengesActions.GET_COMPETITION_CHALLENGES_SUCCESS>
	| ReturnType<typeof ChallengesActions.GET_COMPETITION_CHALLENGES_FAILED>
	| ReturnType<typeof ChallengesActions.GET_CHALLENGE>
	| ReturnType<typeof ChallengesActions.GET_CHALLENGE_SUCCESS>
	| ReturnType<typeof ChallengesActions.GET_CHALLENGE_FAILED>
	| ReturnType<typeof ChallengesActions.HANDLE_CHALLENGE>
	| ReturnType<typeof ChallengesActions.HANDLE_CHALLENGE_SUCCESS>
	| ReturnType<typeof ChallengesActions.HANDLE_CHALLENGE_FAILED>
	| ReturnType<typeof ChallengesActions.CANCEL_CHALLENGE>
	| ReturnType<typeof ChallengesActions.CANCEL_CHALLENGE_SUCCESS>
	| ReturnType<typeof ChallengesActions.CANCEL_CHALLENGE_FAILED>
	| ReturnType<typeof ChallengesActions.FORCE_CHALLENGE>
	| ReturnType<typeof ChallengesActions.FORCE_CHALLENGE_SUCCESS>
	| ReturnType<typeof ChallengesActions.FORCE_CHALLENGE_FAILED>
