import actionCreatorFactory from 'typescript-fsa'
import { PostModel } from '../../models/posts'
import { CommentModel } from '../../models/comments'
import { RcFile } from 'antd/es/upload'

const actionCreator = actionCreatorFactory()

export interface InitialStateType {
	currentPost: PostModel | null
	newPost: PostModel | null
	posts: PostModel[]
	currentPostComments: CommentModel[]
	loading: boolean
	error: unknown | null
}

export enum PostType {
	DEFAULT = 'default',
	LADDER = 'ladder',
	TOURNAMENT = 'tournament',
	GAME = 'game',
	MATCH = 'match',
}

export interface GetSinglePostsPayload {
	refId: string
}

export interface GetAllPostsPayload {
	type: PostType
	relatedId?: string
	skip?: number
	limit?: number
}

export interface AddPostsPayload {
	posts: PostModel
	cover?: RcFile
}

export enum PostsTags {
	GAME = 'game',
	LADDER = 'ladder',
	ET = 'et',
	ET_LEGACY = 'etlegacy',
}

export enum PostsType {
	DEFAULT = 'default',
	LADDER = 'ladder',
	CUP = 'cup',
	GAME = 'game',
	MATCH = 'match',
}

export interface PostsPayload {
	refId: string
	user: string
	title: string
	coverImage: string
	content: string
	date: string
	tags: string[]
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export enum ActionsTypes {
	SAVE_NEW_POST_STATE = 'SAVE_NEW_POST_STATE',
	GET_SINGLE_POSTS = 'GET_SINGLE_POSTS',
	GET_SINGLE_POSTS_SUCCESS = 'GET_SINGLE_POSTS_SUCCESS',
	GET_SINGLE_POSTS_FAILED = 'GET_SINGLE_POSTS_FAILED',
	GET_ALL_POSTS = 'GET_ALL_POSTS',
	GET_ALL_POSTS_SUCCESS = 'GET_ALL_POSTS_SUCCESS',
	GET_ALL_POSTS_FAILED = 'GET_ALL_POSTS_FAILED',
	ADD_POSTS = 'ADD_POSTS',
	ADD_POSTS_SUCCESS = 'ADD_POSTS_SUCCESS',
	ADD_POSTS_FAILED = 'ADD_POSTS_FAILED',
	DELETE_POST = 'DELETE_POST',
	DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS',
	DELETE_POST_FAILED = 'DELETE_POST_FAILED',
}

export const PostsActions = {
	SAVE_NEW_POST_STATE: actionCreator<PostModel>(ActionsTypes.SAVE_NEW_POST_STATE),
	GET_SINGLE_POSTS: actionCreator<GetSinglePostsPayload>(ActionsTypes.GET_SINGLE_POSTS),
	GET_SINGLE_POSTS_SUCCESS: actionCreator<PostModel>(ActionsTypes.GET_SINGLE_POSTS_SUCCESS),
	GET_SINGLE_POSTS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_SINGLE_POSTS_FAILED),
	GET_ALL_POSTS: actionCreator<GetAllPostsPayload>(ActionsTypes.GET_ALL_POSTS),
	GET_ALL_POSTS_SUCCESS: actionCreator<PostModel[]>(ActionsTypes.GET_ALL_POSTS_SUCCESS),
	GET_ALL_POSTS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_ALL_POSTS_FAILED),
	ADD_POSTS: actionCreator<AddPostsPayload>(ActionsTypes.ADD_POSTS),
	ADD_POSTS_SUCCESS: actionCreator<PostModel>(ActionsTypes.ADD_POSTS_SUCCESS),
	ADD_POSTS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.ADD_POSTS_FAILED),
	DELETE_POST: actionCreator<string>(ActionsTypes.DELETE_POST),
	DELETE_POST_SUCCESS: actionCreator<PostModel[]>(ActionsTypes.DELETE_POST_SUCCESS),
	DELETE_POST_FAILED: actionCreator<ErrorPayload>(ActionsTypes.DELETE_POST_FAILED),
}

export type PostsActionsTypes =
	| ReturnType<typeof PostsActions.SAVE_NEW_POST_STATE>
	| ReturnType<typeof PostsActions.GET_SINGLE_POSTS>
	| ReturnType<typeof PostsActions.GET_SINGLE_POSTS_SUCCESS>
	| ReturnType<typeof PostsActions.GET_SINGLE_POSTS_FAILED>
	| ReturnType<typeof PostsActions.GET_ALL_POSTS>
	| ReturnType<typeof PostsActions.GET_ALL_POSTS_SUCCESS>
	| ReturnType<typeof PostsActions.GET_ALL_POSTS_FAILED>
	| ReturnType<typeof PostsActions.ADD_POSTS>
	| ReturnType<typeof PostsActions.ADD_POSTS_SUCCESS>
	| ReturnType<typeof PostsActions.ADD_POSTS_FAILED>
	| ReturnType<typeof PostsActions.DELETE_POST>
	| ReturnType<typeof PostsActions.DELETE_POST_SUCCESS>
	| ReturnType<typeof PostsActions.DELETE_POST_FAILED>
