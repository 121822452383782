import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Layout } from 'antd'
import classNames from 'classnames'
import store from 'store'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { filter } from 'lodash'
import defaultLogo from 'assets/images/logo.png'

import style from './style.module.scss'
import Icon from '../../../../uikit/icon/icon'

const { SubMenu } = Menu

const mapStateToProps = ({ menu, settings, user }) => ({
	menuData: menu.menuData,
	isMenuCollapsed: settings.isMenuCollapsed,
	isMobileView: settings.isMobileView,
	isMenuUnfixed: settings.isMenuUnfixed,
	isMenuShadow: settings.isMenuShadow,
	leftMenuWidth: settings.leftMenuWidth,
	menuColor: settings.menuColor,
	logo: settings.logo,
	role: user?.role,
})

const MenuLeft = ({
	dispatch,
	menuData = [],
	location: { pathname, key, search, hash },

	isMenuCollapsed,
	isMobileView,
	isMenuUnfixed,
	isMenuShadow,
	leftMenuWidth,
	menuColor,
	logo,
	role,
}) => {
	const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])
	const [openedKeys, setOpenedKeys] = useState(store.get('app.menu.openedKeys') || [])

	useEffect(() => {
		applySelectedKeys()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, menuData])

	const applySelectedKeys = () => {
		const flattenItems = (items, key) =>
			items.reduce((flattenedItems, item) => {
				flattenedItems.push(item)
				if (Array.isArray(item[key])) {
					return flattenedItems.concat(flattenItems(item[key], key))
				}
				return flattenedItems
			}, [])
		const selectedParents = filter(menuData, ['url', pathname])
		const childs = menuData.filter(item => item.children)
		const selectedChildrens = filter(childs, route => pathname.includes(`${route.url}`))

		selectedParents.map(item => {
			return setSelectedKeys(item ? [item.key] : [])
		})
		selectedChildrens.map(item => {
			return setSelectedKeys(item ? [item.key] : [])
		})
	}

	const onCollapse = (value, type) => {
		if (type === 'responsive' && isMenuCollapsed) {
			return
		}
		dispatch({
			type: 'settings/CHANGE_SETTING',
			payload: {
				setting: 'isMenuCollapsed',
				value: !isMenuCollapsed,
			},
		})
		setOpenedKeys([])
	}

	const onOpenChange = keys => {
		store.set('app.menu.openedKeys', keys)
		setOpenedKeys(keys)
	}

	const handleClick = e => {
		store.set('app.menu.selectedKeys', [e.key])
		setSelectedKeys([e.key])
	}

	const generateMenuItems = () => {
		const generateItem = item => {
			const { key, title, url, icon, customIcon, disabled, count } = item
			if (item.category) {
				return <Menu.ItemGroup key={Math.random()} title={item.title} />
			}
			if (item.url) {
				return (
					<Menu.Item key={key} disabled={disabled}>
						{item.target && (
							<a href={url} target={item.target} rel="noopener noreferrer" className={'justify-content-between'}>
								<span className={style.title}>{title}</span>
								{count && <span className="badge badge-success ml-2">{count}</span>}
								{icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
								{customIcon && (
									<span className={`icon-collapsed-hidden`}>
										<Icon icon={customIcon} size={16} />
									</span>
								)}
							</a>
						)}
						{!item.target && (
							<Link to={url} className="d-flex justify-content-between">
								<span className={`${style.title}`}>{title} </span>
								{count && <span className="badge badge-success ml-2">{count}</span>}
								{icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
								{customIcon && (
									<span className={`icon-collapsed-hidden d-flex align-self-center ml-2`}>
										<Icon icon={customIcon} size={16} />
									</span>
								)}
							</Link>
						)}
					</Menu.Item>
				)
			}
			return (
				<Menu.Item key={key} disabled={disabled}>
					<span className={style.title}>{title}</span>
					{count && <span className="badge badge-success ml-2">{count}</span>}
					{icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
					{customIcon && (
						<span className={`icon-collapsed-hidden`}>
							<Icon icon={customIcon} size={16} />
						</span>
					)}
				</Menu.Item>
			)
		}

		const generateSubmenu = items =>
			items.map(menuItem => {
				if (menuItem.children) {
					const subMenuTitle = (
						<span key={menuItem.key}>
							<span className={style.title}>{menuItem.title}</span>
							{menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
							{menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
							{menuItem.customIcon && <Icon icon={menuItem.icon} size={16} />}
						</span>
					)
					return (
						<Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
							{generateSubmenu(menuItem.children)}
						</Menu.SubMenu>
					)
				}
				return generateItem(menuItem)
			})

		return menuData.map(menuItem => {
			if (menuItem.roles && !menuItem.roles.includes(role)) {
				return null
			}
			if (menuItem.children) {
				const subMenuTitle = (
					<span key={menuItem.key}>
						<span className={style.title}>{menuItem.title}</span>
						{menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
						{menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
						{menuItem.customIcon && (
							<span className={`${style.icon}`}>
								<Icon icon={menuItem.customIcon} size={22} />
							</span>
						)}
					</span>
				)
				return (
					<Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
						{generateSubmenu(menuItem.children)}
					</Menu.SubMenu>
				)
			}
			return generateItem(menuItem)
		})
	}

	const menuSettings = isMobileView
		? {
				width: 256,
				collapsible: false,
				collapsed: false,
				onCollapse,
		  }
		: {
				width: 256,
				collapsible: false,
				collapsed: false,
				onCollapse,
				breakpoint: 'lg',
		  }

	return (
		<Layout.Sider
			collapsedWidth={256}
			{...menuSettings}
			className={classNames(`${style.menu}`, {
				[style.white]: menuColor === 'white',
				[style.gray]: menuColor === 'gray',
				[style.dark]: menuColor === 'dark',
				[style.unfixed]: isMenuUnfixed,
				[style.shadow]: isMenuShadow,
			})}>
			<div
				className={style.menuOuter}
				style={{
					width: isMenuCollapsed && !isMobileView ? 256 : leftMenuWidth,
					height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)',
				}}>
				<Link className={style.logoContainer} to={'/'}>
					<div className={style.logo} style={{ justifyContent: 'center' }}>
						<img src={defaultLogo} style={{ width: 120 }} className="mr-2" alt="REDDAL" />
					</div>
				</Link>
				<PerfectScrollbar>
					<Menu
						style={{
							width: isMenuCollapsed && !isMobileView ? 256 : leftMenuWidth,
						}}
						onClick={handleClick}
						selectedKeys={selectedKeys}
						openKeys={openedKeys}
						onOpenChange={onOpenChange}
						mode="inline"
						className={`${style.navigation} ${isMenuCollapsed ? 'text-center' : ''}`}
						inlineIndent="15">
						{/*<Menu.Item key="1" icon={<PieChartOutlined />}>*/}
						{/*  Option 1*/}
						{/*</Menu.Item>*/}
						{/*<Menu.Item key="2" icon={<DesktopOutlined />}>*/}
						{/*  Option 2*/}
						{/*</Menu.Item>*/}
						{/*<Menu.Item key="3" icon={<ContainerOutlined />}>*/}
						{/*  Option 3*/}
						{/*</Menu.Item>*/}
						{/*<SubMenu key="sub1" icon={<MailOutlined />} title="Navigation One">*/}
						{/*  <Menu.Item key="5">Option 5</Menu.Item>*/}
						{/*  <Menu.Item key="6">Option 6</Menu.Item>*/}
						{/*  <Menu.Item key="7">Option 7</Menu.Item>*/}
						{/*  <Menu.Item key="8">Option 8</Menu.Item>*/}
						{/*</SubMenu>*/}
						{/*<SubMenu key="sub2" icon={<AppstoreOutlined />} title="Navigation Two">*/}
						{/*  <Menu.Item key="9">Option 9</Menu.Item>*/}
						{/*  <Menu.Item key="10">Option 10</Menu.Item>*/}
						{/*  <SubMenu key="sub3" title="Submenu">*/}
						{/*    <Menu.Item key="11">Option 11</Menu.Item>*/}
						{/*    <Menu.Item key="12">Option 12</Menu.Item>*/}
						{/*  </SubMenu>*/}
						{/*</SubMenu>*/}
						{generateMenuItems()}
					</Menu>
				</PerfectScrollbar>
			</div>
		</Layout.Sider>
	)
}

export default withRouter(connect(mapStateToProps)(MenuLeft))
