import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { TeamsActions } from './types'
import { TeamModel } from '../../models/team'

export interface TeamsState {
	currentTeam: TeamModel | null
	teams: TeamModel[]
	loading: boolean
	error: unknown | null
}

const teamsInitialState: TeamsState = {
	currentTeam: null,
	teams: [],
	loading: false,
	error: null,
}

const teamsReducer = reducerWithInitialState(teamsInitialState)
	// LOADING CASES
	.case(TeamsActions.GET_TEAM, state => ({ ...state, currentTeam: null, loading: true }))
	.case(TeamsActions.CREATE_TEAM, state => ({ ...state, loading: true }))
	.case(TeamsActions.EDIT_TEAM, state => ({ ...state, loading: true }))
	.case(TeamsActions.KICK_PLAYER, state => ({ ...state, loading: true }))
	.case(TeamsActions.CHANGE_ROLE, state => ({ ...state, loading: true }))
	// SUCCESS CASES
	.case(TeamsActions.GET_TEAM_SUCCESS, (state, currentTeam) => ({
		...state,
		currentTeam,
		loading: false,
	}))
	.case(TeamsActions.CREATE_TEAM_SUCCESS, (state, currentTeam) => ({
		...state,
		currentTeam,
		loading: false,
	}))
	.case(TeamsActions.EDIT_TEAM_SUCCESS, (state, currentTeam) => ({
		...state,
		currentTeam,
		loading: false,
	}))
	.case(TeamsActions.CHANGE_ROLE_SUCCESS, state => ({ ...state, loading: false }))
	// FAILED CASES
	.case(TeamsActions.GET_TEAM_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(TeamsActions.CREATE_TEAM_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(TeamsActions.EDIT_TEAM_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(TeamsActions.CHANGE_ROLE_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(TeamsActions.KICK_PLAYER_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default teamsReducer
