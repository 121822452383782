import { NotificationsActions } from './types'
import { NotificationStatus } from '../../models/notification'

export const getLatestNotifications = () => NotificationsActions.GET_NOTIFICATIONS()

export const deleteNotification = (notificationId: string) => NotificationsActions.DELETE_NOTIFICATION(notificationId)

export const updateNotification = (refId: string, status: NotificationStatus) => {
	return NotificationsActions.UPDATE_NOTIFICATION({ refId, status })
}

export const deleteAllNotifications = () => NotificationsActions.DELETE_ALL_NOTIFICATIONS()

export const readAllNotifications = () => NotificationsActions.READ_ALL_NOTIFICATIONS()
