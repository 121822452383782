import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { SearchActions } from './types'
import { UserPayload } from '../user/types'
import { TeamPayload } from '../teams/types'

export interface SearchState {
	userSearchResults: UserPayload[]
	previousUserSearchResults: UserPayload[]
	teamSearchResults: TeamPayload[]
	previousTeamSearchResults: TeamPayload[]
	loading: boolean
	error: unknown | null
}

const searchInitialState: SearchState = {
	userSearchResults: [],
	teamSearchResults: [],
	previousUserSearchResults: [],
	previousTeamSearchResults: [],
	loading: false,
	error: null,
}

const searchReducer = reducerWithInitialState(searchInitialState)
	// LOADING CASES
	.case(SearchActions.SEARCH_TEAM, state => ({ ...state, loading: true }))
	.case(SearchActions.SEARCH_USER, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(SearchActions.SEARCH_TEAM_SUCCESS, (state, teamSearchResults) => ({
		...state,
		previousTeamSearchResults: [...teamSearchResults, ...state.teamSearchResults],
		teamSearchResults,
		loading: false,
	}))
	.case(SearchActions.SEARCH_USER_SUCCESS, (state, userSearchResults) => ({
		...state,
		previousUserSearchResults: [...userSearchResults, ...state.userSearchResults],
		userSearchResults,
		loading: false,
	}))
	// FAILED CASES
	.case(SearchActions.SEARCH_TEAM_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(SearchActions.SEARCH_USER_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default searchReducer
