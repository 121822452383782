import React from 'react'
// @ts-ignore
import IcoMoon from 'react-icomoon'
import iconSet from 'assets/fonts/selection.json'
import { IconProps } from './icon.props'

const Icon = ({ icon, ...rest }: IconProps) => {
	return <IcoMoon iconSet={iconSet} icon={icon} {...rest} />
}

export default Icon
