import React, { useEffect, useState } from 'react'
import { Dropdown, Badge } from 'antd'
import NotificationsPanel from 'components/uikit/notifications-panel'
import { connect, ConnectedProps } from 'react-redux'
import styles from './style.module.scss'
import { NotificationModel, NotificationStatus, NotificationType } from '../../../../../models/notification'

interface RootState {
	notifications: {
		notifications: NotificationModel[]
	}
}

const mapStateToProps = ({ notifications }: RootState) => ({
	notifications: notifications.notifications,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const Actions = ({ dispatch, notifications }: PropsFromRedux) => {
	const [count, setCount] = useState(0)
	useEffect(() => {
		const reducer = (accumulator: any, item: any) => {
			if (item.status === NotificationStatus.NEW && item.type !== NotificationType.NEW_MESSAGE) {
				return accumulator + 1
			}
			return accumulator
		}
		const total = notifications.reduce(reducer, 0)
		setCount(total)
	}, [notifications])

	const menu = (
		<React.Fragment>
			<div className="card cui__utils__shadow width-500 border-0">
				<div className="card-body p-0">
					<NotificationsPanel />
				</div>
			</div>
		</React.Fragment>
	)
	return (
		<Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
			<div className={styles.dropdown}>
				<Badge count={count} className={`${styles.badge}`}>
					<i className={`${styles.icon} fe fe-bell ${count > 0 ? 'tada' : ''}`} />
				</Badge>
			</div>
		</Dropdown>
	)
}

export default connect(mapStateToProps)(Actions)
