import { UserModel } from './user'
import { ProfileModel } from './profile'

export enum CommentType {
	MATCH = 'MATCH',
	POST = 'POST',
}

export interface CommentModel {
	_id: string
	refId: string
	comments: CommentModel[]
	content: string
	deleted?: string
	createdAt: string
	parent: string
	profile: ProfileModel
	relatedTo: string
	type: CommentType
	updatedAt: string
	user: UserModel
}
