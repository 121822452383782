import actionCreatorFactory from 'typescript-fsa'
import { RcFile } from 'antd/lib/upload/interface'
import { ProfileModel } from '../../models/profile'
import { UserGuidModel } from '../../models/guid'
import { JwtPayload } from 'jsonwebtoken'
import { CountryType } from '../../components/uikit/flag/flags'

const actionCreator = actionCreatorFactory()

export interface validJWT extends JwtPayload {
	firebaseId: string
	firebaseToken: string
	id: string
	name: string
	email: string
	avatar: string
	accessToken: string
	refreshToken: string
	provider: string
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface UserLoginPayload {
	email: string
	password: string
}

export interface HandleInvitePayload {
	refId: string
	status: string
}

export interface UserUpdateData {
	firstName?: string
	lastName?: string
	avatar?: string
	country?: CountryType
	banner?: string
	password?: string
	activeProfile?: string
	email?: string
	username?: string
}

export interface UpdateUserPayload {
	userData: UserUpdateData
	avatar?: RcFile
	banner?: RcFile
}

export interface UserPayload {
	_id: string | null
	refId: string | null
	email: string | null
	username: string | null
	firstName?: string | null
	lastName?: string | null
	avatar?: string | null
	banner?: string | null
	profiles?: ProfileModel[] | null
	guids?: UserGuidModel[] | null
	activeProfile?: Record<string, any> | null
	country?: CountryType | null
	roles?: Array<string> | null
	authorized?: boolean // false is default value
	status?: string | null // false is default value
}

export interface UserRegisterPayload {
	email: string
	username: string
	password: string
}
export enum ActionsTypes {
	SET_STATE = 'user/SET_STATE',
	DISCORD_LOGIN = 'DISCORD_LOGIN',
	LOGIN = 'LOGIN',
	REGISTER = 'user/REGISTER',
	GET_USER = 'GET_USER',
	GET_USER_SUCCESS = 'GET_USER_SUCCESS',
	GET_USER_FAILED = 'GET_USER_FAILED',
	LOAD_CURRENT_ACCOUNT = 'LOAD_CURRENT_ACCOUNT',
	LOAD_CURRENT_ACCOUNT_FAILED = 'LOAD_CURRENT_ACCOUNT_FAILED',
	LOAD_CURRENT_ACCOUNT_SUCCESS = 'LOAD_CURRENT_ACCOUNT_SUCCESS',
	LOGOUT = 'LOGOUT',
	LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
	REGISTER_SUCCESS = 'REGISTER_SUCCESS',
	REGISTER_FAILED = 'REGISTER_FAILED',
	CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
	CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS',
	CHANGE_USER_PASSWORD_FAILED = 'CHANGE_USER_PASSWORD_FAILED',
	UPDATE_USER = 'UPDATE_USER',
	UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
	UPDATE_USER_FAILED = 'UPDATE_USER_FAILED',
	HANDLE_INVITE = 'HANDLE_INVITE',
	HANDLE_INVITE_SUCCESS = 'HANDLE_INVITE_SUCCESS',
	HANDLE_INVITE_FAILED = 'HANDLE_INVITE_FAILED',
	SET_ACTIVE_PROFILE = 'SET_ACTIVE_PROFILE',
	SET_ACTIVE_PROFILE_SUCCESS = 'SET_ACTIVE_PROFILE_SUCCESS',
	SET_ACTIVE_PROFILE_FAILED = 'SET_ACTIVE_PROFILE_FAILED',
	SET_NEW_PROFILE_FROM_CREATED_TEAM = 'SET_NEW_PROFILE_FROM_CREATED_TEAM',
	ADD_USER_GUID = 'ADD_USER_GUID',
	ADD_USER_GUID_SUCCESS = 'ADD_USER_GUID_SUCCESS',
	ADD_USER_GUID_FAILED = 'ADD_USER_GUID_FAILED',
	GET_USER_GUIDS = 'GET_USER_GUIDS',
	GET_USER_GUIDS_SUCCESS = 'GET_USER_GUIDS_SUCCESS',
	GET_USER_GUIDS_FAILED = 'GET_USER_GUIDS_FAILED',
	FORGOT_PASSWORD = 'FORGOT_PASSWORD',
	FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
	FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
}
export const UserActions = {
	SET_STATE: actionCreator<ErrorPayload>(ActionsTypes.SET_STATE),
	DISCORD_LOGIN: actionCreator<string>(ActionsTypes.DISCORD_LOGIN),
	LOGIN: actionCreator<UserLoginPayload>(ActionsTypes.LOGIN),
	REGISTER: actionCreator<UserRegisterPayload>(ActionsTypes.REGISTER),
	REGISTER_SUCCESS: actionCreator<UserPayload>(ActionsTypes.REGISTER_SUCCESS),
	REGISTER_FAILED: actionCreator<UserPayload>(ActionsTypes.REGISTER_FAILED),
	FORGOT_PASSWORD: actionCreator<string>(ActionsTypes.FORGOT_PASSWORD),
	FORGOT_PASSWORD_SUCCESS: actionCreator(ActionsTypes.FORGOT_PASSWORD_SUCCESS),
	FORGOT_PASSWORD_FAILED: actionCreator<ErrorPayload>(ActionsTypes.FORGOT_PASSWORD_FAILED),
	CHANGE_USER_PASSWORD: actionCreator<string>(ActionsTypes.CHANGE_USER_PASSWORD),
	CHANGE_USER_PASSWORD_SUCCESS: actionCreator(ActionsTypes.CHANGE_USER_PASSWORD_SUCCESS),
	CHANGE_USER_PASSWORD_FAILED: actionCreator<ErrorPayload>(ActionsTypes.CHANGE_USER_PASSWORD_FAILED),
	LOAD_CURRENT_ACCOUNT: actionCreator(ActionsTypes.LOAD_CURRENT_ACCOUNT),
	LOAD_CURRENT_ACCOUNT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.LOAD_CURRENT_ACCOUNT_FAILED),
	LOAD_CURRENT_ACCOUNT_SUCCESS: actionCreator<UserPayload>(ActionsTypes.LOAD_CURRENT_ACCOUNT_SUCCESS),
	LOGOUT: actionCreator<UserLoginPayload>(ActionsTypes.LOGOUT),
	LOGOUT_SUCCESS: actionCreator(ActionsTypes.LOGOUT_SUCCESS),
	UPDATE_USER: actionCreator<UpdateUserPayload>(ActionsTypes.UPDATE_USER),
	UPDATE_USER_SUCCESS: actionCreator<UserPayload>(ActionsTypes.UPDATE_USER_SUCCESS),
	UPDATE_USER_FAILED: actionCreator<ErrorPayload>(ActionsTypes.UPDATE_USER_FAILED),
	HANDLE_INVITE: actionCreator<HandleInvitePayload>(ActionsTypes.HANDLE_INVITE),
	HANDLE_INVITE_SUCCESS: actionCreator(ActionsTypes.HANDLE_INVITE_SUCCESS),
	HANDLE_INVITE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.HANDLE_INVITE_FAILED),
	SET_ACTIVE_PROFILE: actionCreator<ProfileModel>(ActionsTypes.SET_ACTIVE_PROFILE),
	SET_ACTIVE_PROFILE_SUCCESS: actionCreator<ProfileModel>(ActionsTypes.SET_ACTIVE_PROFILE_SUCCESS),
	SET_ACTIVE_PROFILE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.SET_ACTIVE_PROFILE_FAILED),
	SET_NEW_PROFILE_FROM_CREATED_TEAM: actionCreator<ProfileModel[]>(ActionsTypes.SET_NEW_PROFILE_FROM_CREATED_TEAM),
	GET_USER: actionCreator(ActionsTypes.GET_USER),
	GET_USER_SUCCESS: actionCreator<UserPayload>(ActionsTypes.GET_USER_SUCCESS),
	GET_USER_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_USER_FAILED),
	ADD_USER_GUID: actionCreator<UserGuidModel[]>(ActionsTypes.ADD_USER_GUID),
	ADD_USER_GUID_SUCCESS: actionCreator(ActionsTypes.ADD_USER_GUID_SUCCESS),
	ADD_USER_GUID_FAILED: actionCreator(ActionsTypes.ADD_USER_GUID_FAILED),
	GET_USER_GUIDS: actionCreator(ActionsTypes.GET_USER_GUIDS),
	GET_USER_GUIDS_SUCCESS: actionCreator(ActionsTypes.GET_USER_GUIDS_SUCCESS),
	GET_USER_GUIDS_FAILED: actionCreator(ActionsTypes.GET_USER_GUIDS_FAILED),
}

export type UserActionsTypes =
	| ReturnType<typeof UserActions.SET_STATE>
	| ReturnType<typeof UserActions.DISCORD_LOGIN>
	| ReturnType<typeof UserActions.LOGIN>
	| ReturnType<typeof UserActions.REGISTER>
	| ReturnType<typeof UserActions.REGISTER_SUCCESS>
	| ReturnType<typeof UserActions.REGISTER_FAILED>
	| ReturnType<typeof UserActions.LOAD_CURRENT_ACCOUNT>
	| ReturnType<typeof UserActions.LOAD_CURRENT_ACCOUNT_SUCCESS>
	| ReturnType<typeof UserActions.LOAD_CURRENT_ACCOUNT_FAILED>
	| ReturnType<typeof UserActions.LOGOUT>
	| ReturnType<typeof UserActions.UPDATE_USER>
	| ReturnType<typeof UserActions.UPDATE_USER_SUCCESS>
	| ReturnType<typeof UserActions.UPDATE_USER_FAILED>
	| ReturnType<typeof UserActions.HANDLE_INVITE>
	| ReturnType<typeof UserActions.HANDLE_INVITE_SUCCESS>
	| ReturnType<typeof UserActions.HANDLE_INVITE_FAILED>
	| ReturnType<typeof UserActions.SET_ACTIVE_PROFILE>
	| ReturnType<typeof UserActions.SET_ACTIVE_PROFILE_SUCCESS>
	| ReturnType<typeof UserActions.SET_ACTIVE_PROFILE_FAILED>
	| ReturnType<typeof UserActions.SET_NEW_PROFILE_FROM_CREATED_TEAM>
	| ReturnType<typeof UserActions.GET_USER>
	| ReturnType<typeof UserActions.GET_USER_SUCCESS>
	| ReturnType<typeof UserActions.GET_USER_FAILED>
	| ReturnType<typeof UserActions.ADD_USER_GUID>
	| ReturnType<typeof UserActions.ADD_USER_GUID_SUCCESS>
	| ReturnType<typeof UserActions.ADD_USER_GUID_FAILED>
	| ReturnType<typeof UserActions.GET_USER_GUIDS>
	| ReturnType<typeof UserActions.GET_USER_GUIDS_SUCCESS>
	| ReturnType<typeof UserActions.GET_USER_GUIDS_FAILED>
	| ReturnType<typeof UserActions.CHANGE_USER_PASSWORD>
	| ReturnType<typeof UserActions.CHANGE_USER_PASSWORD_SUCCESS>
	| ReturnType<typeof UserActions.CHANGE_USER_PASSWORD_FAILED>
