import React, { useEffect, useState } from 'react'
import { Dropdown, Menu } from 'antd'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { UserModel } from 'models/user'
import { ProfileModel } from 'models/profile'
import { setActiveProfile } from 'store/user/actions'
import { MenuInfo } from 'rc-menu/lib/interface'
import Button from 'antd/es/button'

interface RootState {
	user: UserModel
}
const mapStateToProps = ({ user }: RootState) => ({ user })

const ProfilePicker = ({ user }: RootState) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [selectableProfiles, setProfiles] = useState<ProfileModel[]>()

	useEffect(() => {
		const profiles = user.profiles?.filter(p => p.refId !== user.activeProfile?.refId)
		setProfiles(profiles)
	}, [user.profiles, user.activeProfile])
	const changeProfile = (e: MenuInfo) => {
		const newActiveProfile = user.profiles?.find(p => p.refId === e.key)
		dispatch(setActiveProfile(newActiveProfile!))
	}

	const pickerMenu = () => {
		return (
			// TODO: check e typescript
			<Menu onClick={(e: any) => changeProfile(e)}>
				{selectableProfiles?.map((p: ProfileModel) => {
					return (
						<Menu.Item key={p.refId} icon={<UserOutlined />}>
							{p.nickname} / {p.team.name}
						</Menu.Item>
					)
				})}
			</Menu>
		)
	}

	return (
		<div className="mb-0 d-xl-block justify-content-end">
			{/*<span className="mr-2">Active Profile: </span>*/}
			{user.profiles!.length > 1 ? (
				<Dropdown.Button
					className="mr-2"
					overlay={pickerMenu}
					placement="bottomCenter"
					type="ghost"
					icon={<MenuOutlined />}
					onClick={() => history.push(`/user/${user.refId}`)}>
					{/*<button*/}
					{/*  type="button"*/}
					{/*  className="ant-btn ant-btn-link"*/}
					{/*  onClick={() => history.push(`/user/${user.refId}`)}*/}
					{/*>*/}
					{/* */}
					{/*</button>*/}
					<span className="text-primary">{user.activeProfile?.nickname}</span>
				</Dropdown.Button>
			) : (
				<Button className="mr-2" type="ghost" onClick={() => history.push(`/user/${user.refId}`)}>
					<span className="text-primary">{user.activeProfile?.nickname}</span>
				</Button>
			)}

			<span>/</span>
			<button
				type="button"
				className="ant-btn ant-btn-link"
				onClick={() => history.push(`/team/${user.activeProfile?.team.refId}`)}>
				<span>{user.activeProfile?.team?.name}</span>
				{/*<span className="ml-2 fa fa-trophy" />*/}
			</button>
		</div>
	)
}

export default connect(mapStateToProps)(ProfilePicker)
