import apiClient from 'services/api'
import { AcceptTeamsPayload, JoinTournamentPayload } from '../../store/tournaments/types'
import { TournamentModel } from '../../models'

export async function getTournament(refId: string) {
	return apiClient
		.get(`/tournament/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No ladder response data')
		})
		.catch(err => {
			throw err
		})
}

export async function joinTournament(tournament: JoinTournamentPayload) {
	return apiClient
		.post(`/tournament/signup`, tournament)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function acceptTeams(data: AcceptTeamsPayload) {
	return apiClient
		.put(`/tournament/${data.tournamentId}/accept`, { teamIds: data.teamIds })
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function updateTournament(tournament: TournamentModel) {
	return apiClient
		.put(`/tournament/${tournament.refId}`, tournament)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function startBracket(tournamentId: string) {
	return apiClient
		.put(`/tournament/updateBracket/${tournamentId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

//
// export async function addLadder(ladder: LadderModel) {
// 	return apiClient
// 		.post(`/ladder`, ladder)
// 		.then(response => {
// 			if (response) {
// 				return response.data
// 			}
// 			throw new Error('No response data')
// 		})
// 		.catch(err => {
// 			throw err
// 		})
// }
//
// export async function joinLadder(ladder: JoinLadderPayload) {
// 	return apiClient
// 		.post(`/teamLadder`, ladder)
// 		.then(response => {
// 			if (response) {
// 				return response.data
// 			}
// 			throw new Error(response)
// 		})
// 		.catch(err => {
// 			throw err
// 		})
// }
//
// export async function getLadderUpcomingMatches(refId: string) {
// 	return apiClient
// 		.get(`/ladder/${refId}/upcoming`)
// 		.then(response => {
// 			if (response) {
// 				return response.data
// 			}
// 			throw new Error('No ladder upcoming response data')
// 		})
// 		.catch(err => {
// 			throw err
// 		})
// }
//
// export async function getLadderLatestMatches(refId: string) {
// 	return apiClient
// 		.get(`/ladder/${refId}/latest`)
// 		.then(response => {
// 			if (response) {
// 				return response.data
// 			}
// 			throw new Error('No ladder latest response data')
// 		})
// 		.catch(err => {
// 			throw err
// 		})
// }
//
// export async function updateLadder(data: UpdateLadderRulesPayload) {
// 	return apiClient
// 		.put(`/ladder/${data.ladder.refId}`, { rules: data.rules })
// 		.then(response => {
// 			if (response) {
// 				return response.data
// 			}
// 			throw new Error('No response data')
// 		})
// 		.catch(err => {
// 			throw err
// 		})
// }
