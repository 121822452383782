import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { getGuids } from 'services/user'
import { HelpersActions } from './types'

function* GET_GUIDS() {
	try {
		// @ts-ignore
		const guids = yield call(getGuids)
		yield put({
			type: HelpersActions.GET_GUIDS_SUCCESS.toString(),
			payload: guids,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
	}
}

export default function* rootSaga() {
	yield all([takeEvery(HelpersActions.GET_GUIDS, GET_GUIDS)])
}
