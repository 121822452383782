import { ProfileModel } from 'models/profile'
import apiClient from 'services/api'
import { HandleInvitePayload, UserUpdateData } from '../../store/user/types'

export async function login() {
	return apiClient
		.post('/login', {})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response from Login')
		})
		.catch(err => {
			throw err
		})
}

export async function register(email: string, username: string) {
	return apiClient
		.post('/createUser', {
			email,
			username,
		})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response from Registration')
		})
		.catch(err => {
			throw err
		})
}

export async function logout() {
	return apiClient
		.get('/auth/logout')
		.then(() => {
			// store.remove('accessToken')
			return true
		})
		.catch(err => {
			throw err
		})
}

export async function updateUser(user: UserUpdateData) {
	return apiClient
		.put('/user', user)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response from Update User')
		})
		.catch(err => {
			throw err
		})
}

export async function setActiveProfile(activeProfile: ProfileModel) {
	return apiClient
		.put('/activeProfile', activeProfile)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response from Active Profile')
		})
		.catch(err => {
			throw err
		})
}

export async function handleInvite(invite: HandleInvitePayload) {
	return apiClient
		.put('/invite', invite)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response from accept invite')
		})
		.catch(err => {
			throw err
		})
}

export async function getUser() {
	return apiClient
		.get('/user')
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response from get user')
		})
		.catch(err => {
			throw err
		})
}
