import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { LadderModel } from 'models/ladder'
import { MatchModel } from 'models/match'
import { LaddersActions } from './types'

export interface LaddersState {
	currentLadder: LadderModel | null
	currentLadderUpcomingMatches: MatchModel[]
	currentLadderLatestMatches: MatchModel[]
	loading: boolean
	error: unknown | null
}
const laddersInitialState: LaddersState = {
	currentLadder: null,
	currentLadderUpcomingMatches: [],
	currentLadderLatestMatches: [],
	loading: false,
	error: null,
}

const laddersReducer = reducerWithInitialState(laddersInitialState)
	// LOADING CASES
	.case(LaddersActions.GET_LADDER, state => ({ ...laddersInitialState, loading: true }))
	.case(LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES, state => ({ ...laddersInitialState, loading: true }))

	// SUCCESS CASES
	.case(LaddersActions.GET_LADDER_SUCCESS, (state, ladder) => ({
		...state,
		currentLadder: ladder,
		loading: false,
	}))
	.case(LaddersActions.UPDATE_LADDER_SUCCESS, (state, ladder) => ({
		...state,
		currentLadder: ladder,
		loading: false,
	}))
	.case(LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS, (state, { upcoming, latest }) => ({
		...state,
		currentLadderUpcomingMatches: upcoming,
		currentLadderLatestMatches: latest,
		loading: false,
	}))

	// FAILED CASES
	.case(LaddersActions.GET_LADDER_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED, (state, error) => ({
		...state,
		error,
		loading: false,
	}))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default laddersReducer
