import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import challenges from './challenges/reducers'
import comments from './comments/reducers'
import user from './user/reducers'
import player from './player/reducers'
import helpers from './helpers/reducers'
import ladders from './ladders/reducers'
import teams from './teams/reducers'
import matches from './matches/reducers'
import notifications from './notifications/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import chats from './chats/reducers'
import search from './search/reducers'
import posts from './posts/reducers'
import polls from './polls/reducers'
import players from './players/reducers'
import tournaments from './tournaments/reducers'
import { MatchState } from './matches/types'

export interface AppState {
	router: any
	challenges: any
	players: any
	chats: any
	comments: any
	tournaments: any
	helpers: any
	ladders: any
	matches: MatchState
	menu: any
	notifications: any
	player: any
	polls: any
	posts: any
	search: any
	settings: any
	teams: any
	user: any
}

const Reducers = (history: History<unknown>) =>
	combineReducers<AppState>({
		router: connectRouter(history),
		challenges,
		chats,
		players,
		tournaments,
		comments,
		helpers,
		ladders,
		matches,
		menu,
		notifications,
		player,
		polls,
		posts,
		search,
		settings,
		teams,
		user,
	})

export { Reducers }
