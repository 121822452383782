import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { InitialStateType, PostsActions } from './types'

const initialState: InitialStateType = {
	currentPostComments: [],
	currentPost: null,
	newPost: null,
	posts: [],
	loading: false,
	error: null,
}

const postsReducer = reducerWithInitialState(initialState)
	// SUCCESS CASES
	.case(PostsActions.SAVE_NEW_POST_STATE, (state, post) => ({
		...state,
		newPost: post,
		loading: false,
	}))
	.case(PostsActions.GET_ALL_POSTS, state => ({ ...state, loading: true }))
	.case(PostsActions.GET_SINGLE_POSTS, state => ({ ...state, loading: true }))
	.case(PostsActions.DELETE_POST, state => ({ ...state }))
	// SUCCESS CASES
	.case(PostsActions.GET_ALL_POSTS_SUCCESS, (state, posts) => ({
		...state,
		posts,
		loading: false,
	}))
	.case(PostsActions.DELETE_POST_SUCCESS, (state, posts) => ({
		...state,
		posts,
		loading: false,
	}))
	.case(PostsActions.GET_SINGLE_POSTS_SUCCESS, (state, currentPost) => ({
		...state,
		currentPost,
		loading: false,
	}))
	.case(PostsActions.ADD_POSTS_SUCCESS, (state, post) => ({
		...state,
		newPost: null,
		posts: [...state.posts, post],
		loading: false,
	}))

	// FAILED CASES
	.case(PostsActions.ADD_POSTS_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(PostsActions.DELETE_POST_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(PostsActions.GET_ALL_POSTS_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(PostsActions.GET_SINGLE_POSTS_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default postsReducer
