import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { TournamentsActions } from './types'
import { TournamentModel } from '../../models'

export interface TournamentsState {
	currentTournament: TournamentModel | null
	loading: boolean
	error: unknown | null
}
const initialState: TournamentsState = {
	currentTournament: null,
	loading: false,
	error: null,
}

const tournamentsReducer = reducerWithInitialState(initialState)
	// LOADING CASES
	.case(TournamentsActions.GET_TOURNAMENT, state => ({ ...state, loading: true }))
	.case(TournamentsActions.JOIN_TOURNAMENT, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(TournamentsActions.GET_TOURNAMENT_SUCCESS, (state, tournament) => ({
		...state,
		currentTournament: tournament,
		loading: false,
	}))
	.case(TournamentsActions.JOIN_TOURNAMENT_SUCCESS, (state, tournament) => ({
		...state,
		loading: false,
	}))
	.case(TournamentsActions.UPDATE_TOURNAMENT_SUCCESS, (state, currentTournament) => ({
		...state,
		currentTournament,
		loading: false,
	}))
	.case(TournamentsActions.ACCEPT_TEAMS_SUCCESS, (state, teams) => ({
		...state,
		currentTournament: {
			...state.currentTournament!,
			teams: teams,
		},
	}))
	// FAILED CASES
	.case(TournamentsActions.GET_TOURNAMENT_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(TournamentsActions.JOIN_TOURNAMENT_FAILED, (state, error) => ({
		...state,
		error,
		loading: false,
	}))
	.case(TournamentsActions.UPDATE_TOURNAMENT_FAILED, (state, error) => ({
		...state,
		error,
		loading: false,
	}))
	.case(TournamentsActions.ACCEPT_TEAMS_FAILED, (state, error) => ({ ...state, error }))

	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default tournamentsReducer
