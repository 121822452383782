import actionCreatorFactory from 'typescript-fsa'
import { CountryType } from '../../components/uikit/flag/flags'
import { GuidModel, UserGuidModel } from '../../models/guid'

const actionCreator = actionCreatorFactory()

export interface GetPlayerPayload {
	refId: string
}

export interface GetPlayerTeams {
	refId: string
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface PlayerTeam {
	refId: string
}

export interface PlayerPayload {
	refId: string
	username: string
	nickname: string
	firstName?: string
	lastName?: string
	avatar?: string
	guids?: UserGuidModel[]
	banner?: string
	profiles?: Array<Record<string, any>> | null
	country?: CountryType
	status?: string // false is default value
	loading: boolean
}

export enum ActionsTypes {
	GET_PLAYER = 'GET_PLAYER',
	GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS',
	GET_PLAYER_FAILED = 'GET_PLAYER_FAILED',
	GET_PLAYERS = 'GET_PLAYERS',
	GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS',
	GET_PLAYERS_FAILED = 'GET_PLAYERS_FAILED',
	GET_PLAYER_TEAMS = 'GET_PLAYER_TEAMS',
	GET_PLAYER_TEAMS_SUCCESS = 'GET_PLAYER_TEAMS_SUCCESS',
	GET_PLAYER_TEAMS_FAILED = 'GET_PLAYER_TEAMS_FAILED',
}
export const PlayerActions = {
	GET_PLAYER: actionCreator<GetPlayerPayload>(ActionsTypes.GET_PLAYER),
	GET_PLAYER_SUCCESS: actionCreator<PlayerPayload>(ActionsTypes.GET_PLAYER_SUCCESS),
	GET_PLAYER_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_PLAYER_FAILED),
	GET_PLAYERS: actionCreator<GetPlayerPayload[]>(ActionsTypes.GET_PLAYERS),
	GET_PLAYERS_SUCCESS: actionCreator<PlayerPayload[]>(ActionsTypes.GET_PLAYERS_SUCCESS),
	GET_PLAYERS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_PLAYERS_FAILED),
	GET_PLAYER_TEAMS: actionCreator(ActionsTypes.GET_PLAYER_TEAMS),
	GET_PLAYER_TEAMS_SUCCESS: actionCreator<PlayerTeam[]>(ActionsTypes.GET_PLAYER_TEAMS_SUCCESS),
	GET_PLAYER_TEAMS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_PLAYER_TEAMS_FAILED),
}

export type PlayerActionsTypes =
	| ReturnType<typeof PlayerActions.GET_PLAYER>
	| ReturnType<typeof PlayerActions.GET_PLAYER_SUCCESS>
	| ReturnType<typeof PlayerActions.GET_PLAYER_FAILED>
	| ReturnType<typeof PlayerActions.GET_PLAYERS>
	| ReturnType<typeof PlayerActions.GET_PLAYERS_SUCCESS>
	| ReturnType<typeof PlayerActions.GET_PLAYERS_FAILED>
	| ReturnType<typeof PlayerActions.GET_PLAYER_TEAMS>
	| ReturnType<typeof PlayerActions.GET_PLAYER_TEAMS_SUCCESS>
	| ReturnType<typeof PlayerActions.GET_PLAYER_TEAMS_FAILED>
