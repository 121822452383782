import { all, call } from 'redux-saga/effects'
import auth from './user/sagas'
import challenges from './challenges/sagas'
import chats from './chats/sagas'
import comments from './comments/sagas'
import helpers from './helpers/sagas'
import ladders from './ladders/sagas'
import tournaments from './tournaments/sagas'
import matches from './matches/sagas'
import menu from './menu/sagas'
import notifications from './notifications/sagas'
import player from './player/sagas'
import settings from './settings/sagas'
import search from './search/sagas'
import players from './players/sagas'
import teams from './teams/sagas'
import polls from './polls/sagas'
import posts from './posts/sagas'
import { rehydration } from './index'

export default function* rootSaga() {
	yield all([
		// @ts-ignore
		yield call(rehydration),
		auth(),
		challenges(),
		comments(),
		menu(),
		matches(),
		polls(),
		posts(),
		players(),
		settings(),
		player(),
		helpers(),
		ladders(),
		tournaments(),
		chats(),
		teams(),
		notifications(),
		search(),
	])
}
