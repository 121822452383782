import React from 'react'
import { FlagProps } from './flag.props'
import { isoCountries } from './flags'

const Flag = ({ code, size, country, className, ...rest }: FlagProps) => {
	const flag = code || Object.keys(isoCountries).find(k => isoCountries[k] === country)
	return (
		<img src={`${process.env.PUBLIC_URL}/resources/flags/${size || 24}/${flag}.png`} alt={flag} className={className} />
	)
}

export default Flag
