import apiClient from 'services/api'

export async function searchUser(term: string) {
	return apiClient
		.get(`/search/user?query=${term}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No users found')
		})
		.catch(err => {
			throw err
		})
}

export async function searchTeam(term: string) {
	return apiClient
		.get(`/search/team?query=${term}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No teams found')
		})
		.catch(err => {
			throw err
		})
}
