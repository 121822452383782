import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './components/kit/vendors/antd/themes/esm-dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/browser'
import Localization from './localization'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import { store, history, persistor } from './store'

const packageJSON = require('../package.json')

Sentry.init({
	dsn: 'https://26b74cf734b7479f82e852e2150e9706@o536334.ingest.sentry.io/5654828',
	environment: `${process.env.NODE_ENV}`,
	release: `${packageJSON.version}`,
	integrations: [new Integrations.BrowserTracing()],

	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
})

const app = (
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Localization>
				<Router history={history} />
			</Localization>
		</PersistGate>
	</Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
