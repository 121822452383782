export default async function getMenuData() {
	return [
		{
			category: true,
			title: 'Info',
		},
		{
			title: 'News',
			key: 'news',
			icon: 'fa fa-calendar',
			url: '/news',
		},
		// {
		//   title: 'News',
		//   key: 'news',
		//   icon: 'fe fe-bookmark',
		//   url: '/ui-kits/antd',
		// },
		// {
		//   title: 'Links',
		//   key: 'links',
		//   icon: 'fa fa-calendar',
		//   url: '/ui-kits/antd',
		// },
		//   {
		//     title: 'Files',
		//     key: 'files',
		//     icon: 'fe fe-bookmark',
		//     url: '/ui-kits/antd',
		//   },
		//   {
		//     title: 'Events',
		//     key: 'events',
		//     icon: 'fe fe-bookmark',
		//     url: '/ui-kits/antd',
		//   },
		//   {
		//     category: true,
		//     title: 'Find',
		//   },
		//   {
		//     title: 'Find Team',
		//     key: 'findteam',
		//     icon: 'fe fe-bookmark',
		//     url: '/ui-kits/antd',
		//   },
		//   {
		//     title: 'Find Players',
		//     key: 'findplayers',
		//     icon: 'fe fe-bookmark',
		//     url: '/ui-kits/antd',
		//   },
		//   {
		//     title: 'Find Demos',
		//     key: 'finddemos',
		//     icon: 'fe fe-bookmark',
		//     url: '/ui-kits/antd',
		//   },
		//   {
		//     title: 'Find Wanted',
		//     key: 'findwanted',
		//     icon: 'fe fe-bookmark',
		//     url: '/ui-kits/antd',
		//   },
		//   {
		//     category: true,
		//     title: 'Support',
		//   },
		//   {
		//     category: true,
		//     title: 'Competitions',
		//   },
		//   {
		//     title: 'Cups',
		//     key: 'cups',
		//     icon: 'fa fa-trophy',
		//     disabled: true,
		//     children: [
		//       {
		//         title: 'MastersCup',
		//         key: 'masterscup',
		//         children: [
		//           {
		//             title: 'Game 1',
		//             customIcon: 'rocketleague',
		//             key: 'ec1',
		//           },
		//           {
		//             title: 'Game 2',
		//             key: 'ec2',
		//             disabled: false,
		//           },
		//         ],
		//       },
		//       {
		//         title: 'OpenCup',
		//         key: 'opencup',
		//         children: [
		//           {
		//             title: 'Game 1',
		//             icon: 'fa fa-trophy',
		//             key: 'o1',
		//           },
		//           {
		//             title: 'Game 2',
		//             key: 'o2',
		//             disabled: false,
		//           },
		//         ],
		//       },
		//     ],
		//   },
		{
			category: true, // render category
			title: 'Ladders', // category title
		},
		// {
		// 	title: 'Enemy Territory 3on3',
		// 	key: 'et_3on3',
		// 	url: '/ladders/medal',
		// 	customIcon: 'ladder',
		// },
		// {
		// 	title: 'Enemy Territory 6on6',
		// 	key: 'et_6on6',
		// 	url: '/ladder/enemy-territory-6-on-6',
		// 	customIcon: 'ladder',
		// },
		//enemy-territory-1-on-1
		{
			title: 'Enemy Territory 1on1',
			key: 'et_1on1',
			url: '/ladder/enemy-territory-1-on-1',
			customIcon: 'ladder',
		},
		{
			title: 'Enemy Territory 3on3',
			key: 'et_3on3',
			url: '/ladder/enemy-territory-3-on-3',
			customIcon: 'ladder',
		},
		{
			title: 'RTCW 3on3',
			key: 'rtcw_3on3',
			url: '/ladder/rtcw-3-on-3',
			customIcon: 'ladder',
		},
		{
			category: true, // render category
			title: 'Tournaments', // category title
		},
		{
			title: 'ET Anniversary Fall Cup 3v3',
			key: 'et_fall_cup_3on3',
			url: '/tournament/hflpV3sZU4',
			icon: 'fa fa-trophy',
		},
		{
			title: 'ET Anniversary Fall Cup 6v6',
			key: 'et_fall_cup_6on6',
			url: '/tournament/upN70zBtIw',
			icon: 'fa fa-trophy',
		},
		// {
		// 	title: 'Tournaments',
		// 	key: 'et_tournament',
		// 	url: '/tournaments/enemy-territory-6-on-6',
		// 	icon: 'fa fa-trophy',
		// 	children: [
		// 		{
		// 			title: 'Enemy Territory',
		// 			icon: 'fa fa-gamepad',
		// 			key: 'et_tournament',
		// 			url: '/tournaments/enemy-territory-6-on-6',
		// 		},
		// 	],
		// },
		// {
		// 	category: true, // render category
		// 	title: 'Tournaments', // category title
		// },
		//   {
		//     title: 'Admin Panel', // item title
		//     key: 'adminpanel', // key (required by antd menu)
		//     icon: 'fe fe-home', // icon class
		//     roles: ['admin'], // set user roles with access to this route
		//     // count: 4, // item badge
		//     children: [
		//       // render submenu
		//       {
		//         title: 'Administration Beta',
		//         key: 'dashboard',
		//         url: '/administration',
		//       },
		//     ],
		//   },
	]
}
