import actionCreatorFactory from 'typescript-fsa'
import { UserPayload } from '../user/types'
import { TeamPayload } from '../teams/types'

const actionCreator = actionCreatorFactory()

export interface SearchTerm {
	term: string
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export enum ActionsTypes {
	SEARCH_USER = 'SEARCH_USER',
	SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS',
	SEARCH_USER_FAILED = 'SEARCH_USER_FAILED',
	SEARCH_TEAM = 'SEARCH_TEAM',
	SEARCH_TEAM_SUCCESS = 'SEARCH_TEAM_SUCCESS',
	SEARCH_TEAM_FAILED = 'SEARCH_TEAM_FAILED',
}
export const SearchActions = {
	SEARCH_USER: actionCreator<SearchTerm>(ActionsTypes.SEARCH_USER),
	SEARCH_USER_SUCCESS: actionCreator<UserPayload[]>(ActionsTypes.SEARCH_USER_SUCCESS),
	SEARCH_USER_FAILED: actionCreator<ErrorPayload>(ActionsTypes.SEARCH_USER_FAILED),
	SEARCH_TEAM: actionCreator<SearchTerm>(ActionsTypes.SEARCH_TEAM),
	SEARCH_TEAM_SUCCESS: actionCreator<TeamPayload[]>(ActionsTypes.SEARCH_TEAM_SUCCESS),
	SEARCH_TEAM_FAILED: actionCreator<ErrorPayload>(ActionsTypes.SEARCH_TEAM_FAILED),
}

export type SearchActionsTypes =
	| ReturnType<typeof SearchActions.SEARCH_USER>
	| ReturnType<typeof SearchActions.SEARCH_USER_SUCCESS>
	| ReturnType<typeof SearchActions.SEARCH_USER_FAILED>
	| ReturnType<typeof SearchActions.SEARCH_TEAM>
	| ReturnType<typeof SearchActions.SEARCH_TEAM_SUCCESS>
	| ReturnType<typeof SearchActions.SEARCH_TEAM_FAILED>
