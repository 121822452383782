import React from 'react'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button, Tooltip } from 'antd'
import style from './style.module.scss'
import {
	NotificationAction,
	NotificationModel,
	NotificationStatus,
	NotificationType,
} from '../../../../models/notification'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../../../store'
import { updateNotification } from '../../../../store/notifications/actions'

dayjs.extend(calendar)
dayjs.extend(relativeTime)

interface NotificationListProps {
	notifications: NotificationModel[]
	handleNotification: (notification: NotificationModel, action: NotificationAction) => void
}

// @ts-ignore
const NotificationList = ({ notifications, handleNotification }: NotificationListProps) => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const handleDelete = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, notification: NotificationModel) => {
		e.preventDefault()
		e.stopPropagation()
		console.log('handle')
		handleNotification(notification, NotificationAction.DELETE)
	}

	const onNotificationClick = (notification: NotificationModel) => {
		if (notification.status === NotificationStatus.NEW) {
			dispatch(updateNotification(notification.refId, NotificationStatus.READ))
		}
		if (
			notification.type === NotificationType.TEAM_INVITE ||
			notification.type === NotificationType.TEAM_INVITE_ACCEPT ||
			notification.type === NotificationType.TEAM_INVITE_DECLINE
		) {
			history.push(`/team/${notification.parentId}`)
		}
		if (
			notification.type === NotificationType.MATCH_CHALLENGE ||
			notification.type === NotificationType.CHALLENGE_UPDATED
		) {
			history.push(`/challenge/${notification.relatedId}`)
		}
		if (
			notification.type === NotificationType.CHALLENGE_COMPLETED ||
			notification.type === NotificationType.MATCH_DONE ||
			notification.type === NotificationType.MATCH_UPDATED ||
			notification.type === NotificationType.MATCH_RESET
		) {
			history.push(`/match/${notification.relatedId}`)
		}
		return
	}

	const renderNotifications = () => {
		return notifications.reverse().map((notification: NotificationModel) => {
			return (
				// <li key={notification.refId} className={style.item}>
				// 	<Link className={`${style.itemHead} mb-3 justify-content-between`} to={'#'}>
				// 		<div className="col-auto align-content-center">
				// 			<p className={`${notification.status === NotificationStatus.READ ? 'text-muted' : ''}`}>
				// 				{notification.message}
				// 			</p>
				// 			<div className="text-muted">{dayjs().calendar(dayjs(notification.created).fromNow(true))}</div>
				// 		</div>
				// 		<div className="col-4 pr-0 align-content-center">
				// 			<div className="row justify-content-end">
				// 				<Button
				// 					className={`${
				// 						notification.status === NotificationStatus.READ ? 'btn  ml-2' : 'btn btn-success ml-2'
				// 					}`}
				// 					onClick={() => handleNotification(notification, '')}>
				// 					<span>View Match</span>
				// 				</Button>
				// 			</div>
				// 		</div>
				// 	</Link>
				// </li>
				<li key={notification.refId} className={style.item}>
					<a
						className={`${style.itemLink} ${notification.status === NotificationStatus.READ ? 'text-muted' : ''}`}
						onClick={() => onNotificationClick(notification)}>
						<div className={style.itemPic}>
							{notification.type === NotificationType.TEAM_INVITE && <i className="fe fe-user-plus" />}
							{notification.type === NotificationType.TEAM_INVITE_DECLINE && <i className="fe fe-user-x" />}
							{notification.type === NotificationType.TEAM_INVITE_ACCEPT && <i className="fe fe-user-check" />}
							{notification.type === NotificationType.NEW_MESSAGE && <i className="fe fe-mail" />}
							{notification.type === NotificationType.MATCH_CHALLENGE && <i className="fe fe-crosshair" />}
							{notification.type === NotificationType.CHALLENGE_UPDATED && <i className="fe fe-refresh-cw" />}
							{notification.type === NotificationType.CHALLENGE_COMPLETED && <i className="fe fe-check" />}
							{notification.type === NotificationType.MATCH_DONE && <i className="fe fe-check" />}
							{notification.type === NotificationType.MATCH_UPDATED && <i className="fe fe-refresh-cw" />}
							{notification.type === NotificationType.MATCH_RESET && <i className="fe fe-refresh-ccw" />}
						</div>
						<div className="mr-auto">
							<div>{notification.message}</div>
							<div className="text-muted">{dayjs().calendar(dayjs(notification.created).fromNow(true))}</div>
						</div>
						<div className={style.itemAction}>
							{notification.type === NotificationType.TEAM_INVITE ? (
								<>
									<Button
										className="btn btn-outline-danger mr-2"
										onClick={() => handleNotification(notification, NotificationAction.DECLINE)}>
										Decline
									</Button>
									<Button
										className="btn btn-success"
										onClick={() => handleNotification(notification, NotificationAction.ACCEPT)}>
										Accept
									</Button>
								</>
							) : (
								<Tooltip placement="top" title="Delete notification">
									<a className="btn btn-sm btn-light" onClick={e => handleDelete(e, notification)}>
										<i className="fe fe-trash-2" />
									</a>
								</Tooltip>
							)}
						</div>
					</a>
				</li>
			)
		})
	}

	return <ul className="list-unstyled">{renderNotifications()}</ul>
}

export default NotificationList
