import apiClient from 'services/api'
import { UserGuidModel } from 'models/guid'

export async function addUserGuid(guid: UserGuidModel) {
	return apiClient
		.post(`/userGuid`, guid)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No userGuid response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getGuids() {
	return apiClient
		.get(`/guids`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No guids response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getUserGuids() {
	return apiClient
		.get(`/userGuid`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No userGuids response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getAllUsers() {
	return apiClient
		.get(`/users`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No userGuid response data')
		})
		.catch(err => {
			throw err
		})
}
