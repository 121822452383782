import apiClient from 'services/api'
import socket from '../sockets'
import { SendMessagePayload } from '../../store/chats/types'

export async function getAllChats() {
	return apiClient
		.get(`/chats`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No getAllUserChats data')
		})
		.catch(err => {
			throw err
		})
}

export async function getChat(chatId: string) {
	return apiClient
		.get(`/chat/${chatId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No getUserChats data')
		})
		.catch(err => {
			throw err
		})
}

export async function markChatAsRead(chatId: string) {
	return apiClient
		.put(`/chat/${chatId}/read`, {})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getChatMessages(chatId: string, skip: number = 0, limit: number = 10) {
	return apiClient
		.get(`/messages/${chatId}?skip=${skip}&limit=${limit}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No getAllUserChats data')
		})
		.catch(err => {
			throw err
		})
}

export function sendChatMessage(message: SendMessagePayload) {
	socket.emit('messageSend', message)
}
