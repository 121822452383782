import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { notification } from 'antd'
import { getAllChats, getChat, getChatMessages, markChatAsRead, sendChatMessage } from 'services/chats'
import dayjs from 'dayjs'
import _ from 'lodash'
import socket from 'services/sockets'
import { ChatMessageModel } from 'models/chat-message'
import { ChatModel } from 'models/chat'
import { TypingModel } from 'models/typing'
import { ChatsActions, ChatsActionsTypes, GetChatMessagesPayload, SocketEvents } from './types'
import { NotificationsActions } from '../notifications/types'
import { NotificationModel } from '../../models/notification'

function createSocketChannel() {
	return eventChannel(emit => {
		const socketEventHandler = (event: any) => {
			emit(event)
		}
		const connectToRooms = () => {
			emit('connect')
		}
		const errorHandler = (errorEvent: any) => {
			emit(new Error(errorEvent.reason))
		}

		socket.on('connect', connectToRooms)
		socket.on(SocketEvents.MESSAGE_GET, socketEventHandler)
		socket.on(SocketEvents.IS_TYPING, socketEventHandler)
		socket.on(SocketEvents.ERROR, errorHandler)

		return () => {
			socket.off(SocketEvents.MESSAGE_GET, socketEventHandler)
		}
	})
}

//
//
// useEffect(() => {
//   console.log('USE EFFECT')
//   //region ####### SOCKET ##########
//   socket.on('connect', function connection(message: string) {
//     console.log('connected ', message)
//     socket.emit('join', [roomName])
//   })
//
//   socket.on('disconnect', function disconnection(message: string) {
//     console.log('disconnect ', message)
//   })
//
//   socket.on('RoomEnter', function roomEntered(room: string) {
//     console.log('Room joined', room)
//   })
//
//   socket.on('messageGet', function getMessage(message: string) {
//     console.log('message get: ', message)
//     dispatch(getChatMessage(message))
//   })
//
//   socket.on('isTyping', function playerIsTyping(isTyping: boolean) {
//     console.log('Other is typing: ', isTyping)
//     setOtherMemberIsTyping(isTyping)
//   })
// }, [roomName])

export function* SUBSCRIBE_TO_ALL_CHATS() {
	// @ts-ignore
	const socketChannel = yield call(createSocketChannel)
	while (true) {
		try {
			// An error from socketChannel will cause the saga jump to the catch block
			// @ts-ignore
			const socketEvent = yield take(socketChannel)
			switch (socketEvent.type) {
				case SocketEvents.MESSAGE_GET:
					yield put({
						type: ChatsActions.NEW_SUBSCRIBED_CHAT_MESSAGE.toString(),
						payload: socketEvent,
					})
					break
				case SocketEvents.IS_TYPING:
					yield put({
						type: ChatsActions.USER_IS_TYPING.toString(),
						payload: socketEvent,
					})
					break
				default:
					break
			}
		} catch (err) {
			console.error('socket error:', err)
			// socketChannel is still open in catch block
			// if we want end the socketChannel, we need close it explicitly
			// socketChannel.close()
		}
	}
}

function* NEW_SUBSCRIBED_CHAT_MESSAGE(action: ChatsActionsTypes) {
	const { chats } = yield select(state => state.chats)
	const chatMessage = action.payload
	try {
		const { messageWithDate } = chatMessage
		const chatsWIthNewMessage = chats.map((chat: ChatModel) => {
			if (chat.refId === messageWithDate.chatId) {
				if (chat.messages) {
					chat.messages = _.uniqBy([...chat.messages, messageWithDate], 'refId')
				} else {
					chat.messages = [messageWithDate]
				}
			}
			return chat
		})

		yield put({
			type: ChatsActions.NEW_SUBSCRIBED_CHAT_MESSAGE_SUCCESS.toString(),
			payload: chatsWIthNewMessage,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChatsActions.NEW_SUBSCRIBED_CHAT_MESSAGE_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_USER_CHATS() {
	try {
		// @ts-ignore
		const userChats = yield call(getAllChats)
		yield put({
			type: ChatsActions.GET_USER_CHATS_SUCCESS.toString(),
			payload: userChats,
		})
		const { activeChat } = yield select(state => state.chats)
		if (!activeChat && userChats.length > 0) {
			yield put({
				type: ChatsActions.SET_ACTIVE_CHAT.toString(),
				payload: userChats[0].refId,
			})
		} else if (activeChat && userChats.length > 0) {
			yield put({
				type: ChatsActions.GET_CHAT_MESSAGES.toString(),
				payload: { chatId: activeChat },
			})
		}
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChatsActions.GET_USER_CHATS_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_CHAT(action: ChatsActionsTypes) {
	const chatId = action.payload as string
	const { chats } = yield select(state => state.chats)

	try {
		const singleChat: ChatModel = yield call(getChat, chatId)
		const updatedChats = Array.from(new Set([singleChat, ...chats]))

		socket.emit('join', [chatId])
		yield put({
			type: ChatsActions.GET_CHAT_SUCCESS.toString(),
			payload: updatedChats,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChatsActions.GET_CHAT_FAILED.toString(),
			payload: error,
		})
	}
}

function* SEND_CHAT_MESSAGE(action: ChatsActionsTypes) {
	const { chats } = yield select(state => state.chats)
	const message = action.payload as ChatMessageModel
	try {
		const chatsWIthNewMessage = chats.map((chat: ChatModel) => {
			if (chat.refId === message.chatId) {
				message.date = dayjs().toISOString()
				if (chat.messages) {
					chat.messages = [...chat.messages, message]
				} else {
					chat.messages = [message]
				}
			}
			return chat
		})

		yield put({
			type: ChatsActions.SEND_CHAT_MESSAGE_SUCCESS.toString(),
			payload: chatsWIthNewMessage,
		})
		yield call(sendChatMessage, message)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
	}
}

function* GET_CHAT_MESSAGES(action: ChatsActionsTypes) {
	const { chatId } = action.payload as GetChatMessagesPayload
	const { chats } = yield select(state => state.chats)
	try {
		// @ts-ignore
		const getChatMessagesSuccess = yield call(getChatMessages, chatId)
		const chatsWIthMessages = chats.map((chat: ChatModel) => {
			if (chat.refId === chatId) {
				if (chat.messages) {
					chat.messages = _.uniqBy([...chat.messages, ...getChatMessagesSuccess], 'refId')
				} else {
					chat.messages = getChatMessagesSuccess
				}
			}
			return chat
		})
		yield put({
			type: ChatsActions.GET_CHAT_MESSAGES_SUCCESS.toString(),
			payload: chatsWIthMessages,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: ChatsActions.GET_CHAT_MESSAGES_FAILED.toString(),
			payload: error,
		})
	}
}

function* SET_CHAT_AS_READ(action: ChatsActionsTypes) {
	const chatId = action.payload as string
	const { chats } = yield select(state => state.chats)
	const { notifications } = yield select(state => state.notifications)

	try {
		// @ts-ignore
		const updatedChatSuccess = yield call(markChatAsRead, chatId)
		const filterChats = chats.filter((chat: ChatModel) => chat.refId !== chatId)
		const updatedChats = [...filterChats, updatedChatSuccess]
		const updatedNotifications = notifications.filter((notif: NotificationModel) => notif.relatedId !== chatId)

		yield put({
			type: NotificationsActions.UPDATE_NOTIFICATIONS_SUCCESS.toString(),
			payload: updatedNotifications,
		})
		yield put({
			type: ChatsActions.SET_CHAT_AS_READ_SUCCESS.toString(),
			payload: updatedChats,
		})
	} catch (error) {
		yield put({
			type: ChatsActions.SET_CHAT_AS_READ_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* USER_IS_TYPING(action: ChatsActionsTypes) {
	console.log('::::::::::::::::::::: SOCKET IS TYPING :::::::::::::::: ')

	const isTyping = action.payload as TypingModel
	const { chats } = yield select(state => state.chats)
	const updatedChats = chats.map((c: ChatModel) => {
		if (isTyping.isTyping.chatId === c.refId) {
			c.typing = isTyping
		}
		return c
	})
	console.log('typing info: ', isTyping)
	try {
		yield put({
			type: ChatsActions.USER_IS_TYPING_SUCCESS.toString(),
			payload: updatedChats,
		})
	} catch (error) {
		yield put({
			type: ChatsActions.USER_IS_TYPING_FAILED.toString(),
			payload: error.message,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(ChatsActions.USER_IS_TYPING, USER_IS_TYPING),
		takeLatest(ChatsActions.GET_CHAT, GET_CHAT),
		takeEvery(ChatsActions.SET_CHAT_AS_READ, SET_CHAT_AS_READ),
		takeLatest(ChatsActions.GET_USER_CHATS, GET_USER_CHATS),
		takeLatest(ChatsActions.GET_CHAT_MESSAGES, GET_CHAT_MESSAGES),
		takeEvery(ChatsActions.SEND_CHAT_MESSAGE, SEND_CHAT_MESSAGE),
		takeLatest(ChatsActions.NEW_SUBSCRIBED_CHAT_MESSAGE, NEW_SUBSCRIBED_CHAT_MESSAGE),
		takeLatest(ChatsActions.SUBSCRIBE_TO_ALL_CHATS, SUBSCRIBE_TO_ALL_CHATS),
	])
}
