import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { CommentActions } from './types'
import { CommentModel } from '../../models/comments'

export interface CommentsState {
	currentComments: CommentModel[]
	loading: boolean
	error: unknown | null
}

const commentsInitialState: CommentsState = {
	currentComments: [],
	loading: false,
	error: null,
}

const CommentsReducer = reducerWithInitialState(commentsInitialState)
	// LOADING CASES
	.case(CommentActions.ADD_COMMENT, state => ({ ...state, loading: true }))
	.case(CommentActions.GET_COMMENTS, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(CommentActions.GET_COMMENTS_SUCCESS, (state, currentComments) => ({
		...state,
		currentComments,
	}))
	.case(CommentActions.EDIT_COMMENT_SUCCESS, (state, currentComments) => ({
		...state,
		currentComments,
	}))
	.case(CommentActions.ADD_COMMENT_SUCCESS, (state, currentComments) => ({
		...state,
		currentComments,
	}))
	.case(CommentActions.DELETE_COMMENT_SUCCESS, (state, currentComments) => ({
		...state,
		currentComments,
	}))
	// FAILED CASES
	.case(CommentActions.ADD_COMMENT_FAILED, (state, error) => ({ ...state, error, loading: false }))

	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default CommentsReducer
