import apiClient from 'services/api'
import { RcFile } from 'antd/lib/upload/interface'
import { UploadEntity, UploadType } from '../../pages/types'
// CDN dvfjiyqu46c2g.cloudfront.net

export async function uploadImage(entity: UploadEntity, type: UploadType, refId: string, file: RcFile) {
	const formData = new FormData()
	formData.append('entity', entity)
	formData.append('type', type)
	formData.append('refId', refId)
	formData.append('file', file)
	return apiClient
		.post('/upload', formData)
		.then(response => {
			if (response) {
				return response.data
			}
			return false
		})
		.catch(err => {
			throw err
		})
}

export function removeEmpty(obj: any) {
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}
