import { TeamLadderModel } from './team-ladder'
import { LadderModel } from './ladder'
import { CommentModel } from './comments'
import { MatchReportModel } from './matchReport'
import { TournamentModel } from './tournament'
import { TeamModel } from './team'

export enum CompetitionType {
	'Ladder' = 'Ladder',
	'Tournament' = 'Tournament',
}

export enum MatchStatus {
	UPCOMING = 'UPCOMING',
	PENDING = 'PENDING',
	CONFLICT = 'CONFLICT',
	DONE = 'DONE',
}
export interface MatchModel {
	_id: string
	refId: string
	date: Date
	size: string
	length: string
	room: string
	password: string
	challenger: TeamLadderModel
	challengerTeam: string
	opponent: TeamLadderModel
	opponentTeam: string
	waitingTeam: string | null
	winNumberOne: string | null
	lostNumberOne: string | null
	forfeit: string | null
	noshow: string | null
	isCanceled: boolean | null
	maps: any[]
	comments: CommentModel[]
	competition: LadderModel & TournamentModel & string
	competitionType: CompetitionType
	challengerScore: number | null
	challengerPoints: number
	challengerMatchReport: MatchReportModel
	opponentScore: number | null
	opponentPoints: number
	opponentMatchReport: MatchReportModel
	status: MatchStatus
	count: number
	drawDisabled: boolean
	teams: TeamModel[]
}
