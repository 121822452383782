import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	deleteAllNotifications,
	getNotifications,
	readAllNotifications,
	updateNotification,
	deleteNotification,
} from 'services/notifications'
import { notification } from 'antd'
import { eventChannel } from 'redux-saga'
import { NotificationsActions, NotificationsActionsTypes, UpdateNotificationPayload } from './types'
import socket from '../../services/sockets'
import { ChatsActions, SocketEvents } from '../chats/types'
import { NotificationModel, NotificationType } from '../../models/notification'
import { ChallengesActions } from '../challenges/types'
import { MatchActions } from '../matches/types'

function* GET_NOTIFICATIONS() {
	try {
		// @ts-ignore
		const notificationsSuccess = yield call(getNotifications)
		yield put({
			type: NotificationsActions.GET_NOTIFICATIONS_SUCCESS.toString(),
			payload: notificationsSuccess,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: NotificationsActions.GET_NOTIFICATIONS_FAILED.toString(),
			payload: {
				loading: false,
				error: error.message,
			},
		})
	}
}

// this function creates an event channel from a given socket
// Setup subscription to incoming `ping` events
function createSocketChannel() {
	// `eventChannel` takes a subscriber function
	// the subscriber function takes an `emit` argument to put messages onto the channel
	return eventChannel(emit => {
		const notificationHandler = (event: any) => {
			// puts event payload into the channel
			// this allows a Saga to take this payload from the returned channel
			emit(event)
		}

		const errorHandler = (errorEvent: any) => {
			// create an Error object and put it into the channel
			emit(new Error(errorEvent.reason))
		}

		// socket.on('notification', function connection(notificationMessage: string) {
		//   console.log('notification ', notificationMessage)
		// })
		// setup the subscription
		socket.on('notification', notificationHandler)
		socket.on('error', errorHandler)

		// the subscriber must return an unsubscribe function
		// this will be invoked when the saga calls `channel.close` method
		return () => {
			socket.off('ping', notificationHandler)
		}
	})
}

function* NEW_CHAT_MESSAGE_NOTIFICATION(action: NotificationsActionsTypes) {
	const newChatNotification = action.payload as NotificationModel
	try {
		yield put({
			type: ChatsActions.GET_CHAT.toString(),
			payload: newChatNotification.relatedId,
		})
		yield put({
			type: ChatsActions.GET_CHAT_MESSAGES.toString(),
			payload: {
				chatId: newChatNotification.relatedId,
			},
		})
	} catch (error) {
		console.log('ERROR NEW CHAT MESSAGE NOTIFICATION:: ', error)
	}
}

// // reply with a `pong` message by invoking `socket.emit('pong')`
// function* pong() {
//   yield delay(5000)
//   yield apply(socket, socket.emit, ['pong']) // call `emit` as a method with `socket` as context
// }

export function* SUBSCRIBE_TO_ALL_NOTIFICATIONS() {
	// @ts-ignore
	const socketChannel = yield call(createSocketChannel)
	while (true) {
		try {
			// "{"type":"NEW-MESSAGE","status":"NEW","_id":"6072183717d58d00298f5b98","user":"605633f907c0f3001ee346d5","from":"YqaMrMF2ezbZyPxFTPOUvI1ComE2","message":"dfgdfgdfg","relatedId":"YqaMrMF2ezbZyPxFTPOUvI1ComE2PKRUlJcqePYKV3nRnI1VmKH01Vd2","created":"2021-04-10T21:27:19.014Z","refId":"d41bb93f-66e0-47fb-aee2-8facd5439103","__v":0}"
			// An error from socketChannel will cause the saga jump to the catch block
			// @ts-ignore
			const socketNotification = yield take(socketChannel)
			if (socketNotification.socketType === SocketEvents.NOTIFICATION) {
				console.log('socketNotification ', socketNotification)
				yield put({
					type: NotificationsActions.NEW_SUBSCRIBED_NOTIFICATION.toString(),
					payload: socketNotification,
				})
			}
		} catch (err) {
			console.error('socket error:', err)
			// socketChannel is still open in catch block
			// if we want end the socketChannel, we need close it explicitly
			// socketChannel.close()
		}
	}
}

function* NEW_SUBSCRIBED_NOTIFICATION(action: NotificationsActionsTypes) {
	const notification = action.payload as NotificationModel
	try {
		if (notification.type === NotificationType.CHALLENGE_UPDATED) {
			const { currentChallenge } = yield select(state => state.challenges)
			if (currentChallenge && currentChallenge.refId === notification.relatedId) {
				yield put({
					type: ChallengesActions.GET_CHALLENGE.toString(),
					payload: notification.relatedId,
				})
			}
		}
		if (
			notification.type === NotificationType.MATCH_RESET ||
			notification.type === NotificationType.MATCH_DONE ||
			NotificationType.MATCH_UPDATED
		) {
			const { currentMatch } = yield select(state => state.matches)
			if (currentMatch && currentMatch.refId === notification.relatedId) {
				yield put({
					type: MatchActions.GET_MATCH.toString(),
					payload: notification.relatedId,
				})
			}
		}
	} catch (error) {
		console.log(error)
	}
}

// function* SUBSCRIBE_TO_ALL_NOTIFICATIONS() {
//   const { notifications } = yield select(state => state)
//   return eventChannel(emit => {
//     //region ####### SOCKET ##########
// socket.on('notification', function connection(notificationMessage: string) {
//   console.log('notification ', notificationMessage)
// })
//
//     return () => {}
//   })
// }

function* UPDATE_NOTIFICATION(action: NotificationsActionsTypes) {
	const notificationData = action.payload as UpdateNotificationPayload
	try {
		const updatedNotification: NotificationModel = yield call(updateNotification, notificationData)
		const { notifications } = yield select(state => state.notifications)
		const updatedNotifications = notifications.map((notification: NotificationModel) => {
			if (updatedNotification.refId === notification.refId) {
				return updatedNotification
			}
			return notification
		})
		yield put({
			type: NotificationsActions.UPDATE_NOTIFICATION_SUCCESS.toString(),
			payload: updatedNotifications,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: NotificationsActions.UPDATE_NOTIFICATION_FAILED.toString(),
			payload: {
				loading: false,
				error: error.message,
			},
		})
	}
}

function* DELETE_ALL_NOTIFICATIONS() {
	try {
		yield call(deleteAllNotifications)
		const { notifications } = yield select(state => state.notifications)

		yield put({
			type: NotificationsActions.DELETE_ALL_NOTIFICATIONS_SUCCESS.toString(),
			payload: [],
		})
	} catch (error) {
		console.log(error)
		yield put({
			type: NotificationsActions.DELETE_ALL_NOTIFICATIONS_FAILED.toString(),
			payload: error,
		})
	}
}

function* DELETE_NOTIFICATION(action: NotificationsActionsTypes) {
	const notificationId = action.payload as string
	try {
		yield call(deleteNotification, notificationId)
		const { notifications } = yield select(state => state.notifications)
		const updatedNotifications = notifications.filter((notif: NotificationModel) => notif.refId !== notificationId)
		yield put({
			type: NotificationsActions.DELETE_NOTIFICATION_SUCCESS.toString(),
			payload: updatedNotifications,
		})
	} catch (error) {
		console.log(error)
		yield put({
			type: NotificationsActions.DELETE_NOTIFICATION_FAILED.toString(),
			payload: error,
		})
	}
}

function* READ_ALL_NOTIFICATIONS() {
	try {
		const updatedNotifications: NotificationModel[] = yield call(readAllNotifications)
		yield put({
			type: NotificationsActions.READ_ALL_NOTIFICATIONS_SUCCESS.toString(),
			payload: updatedNotifications,
		})
	} catch (error) {
		console.log(error)
		yield put({
			type: NotificationsActions.READ_ALL_NOTIFICATIONS_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(NotificationsActions.GET_NOTIFICATIONS, GET_NOTIFICATIONS),
		takeEvery(NotificationsActions.NEW_CHAT_MESSAGE_NOTIFICATION, NEW_CHAT_MESSAGE_NOTIFICATION),
		takeLatest(NotificationsActions.SUBSCRIBE_TO_ALL_NOTIFICATIONS, SUBSCRIBE_TO_ALL_NOTIFICATIONS),
		takeLatest(NotificationsActions.UPDATE_NOTIFICATION, UPDATE_NOTIFICATION),
		takeLatest(NotificationsActions.DELETE_ALL_NOTIFICATIONS, DELETE_ALL_NOTIFICATIONS),
		takeLatest(NotificationsActions.READ_ALL_NOTIFICATIONS, READ_ALL_NOTIFICATIONS),
		takeLatest(NotificationsActions.DELETE_NOTIFICATION, DELETE_NOTIFICATION),
		takeLatest(NotificationsActions.NEW_SUBSCRIBED_NOTIFICATION, NEW_SUBSCRIBED_NOTIFICATION),
	])
}
