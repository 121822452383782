import apiClient from 'services/api'

export async function getPlayer(refId: string) {
	return apiClient
		.get(`/player/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getPlayers(refId: string[]) {
	return apiClient
		.get(`/user/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}
