import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import {
	addMatchReport,
	getCompetitionMatches,
	getMatch,
	getTeamMatches,
	getTournamentMatches,
	updateMatch,
	updateMatchReport,
} from 'services/matches'
import { CompetitionType, MatchModel } from 'models/match'
import { MatchActions, MatchActionsTypes, MatchUpdatePayload } from './types'
import { MatchReportModel } from '../../models/matchReport'
import { CommentActions } from '../comments/types'
import { history } from '../index'
import { CommentType } from '../../models/comments'
import { TournamentsActions } from '../tournaments/types'
import { NotificationModel } from '../../models/notification'
import { NotificationsActions } from '../notifications/types'

function* GET_MATCH(action: MatchActionsTypes) {
	const matchId = action.payload as string
	try {
		const match: MatchModel = yield call(getMatch, matchId)
		yield put({
			type: MatchActions.GET_MATCH_SUCCESS.toString(),
			payload: match,
		})
		yield put({
			type: CommentActions.GET_COMMENTS.toString(),
			payload: {
				entityId: match._id,
				type: CommentType.MATCH,
			},
		})
	} catch (error) {
		notification.warning({
			message: 'Match not found',
		})
		const { notifications } = yield select(state => state.notifications)
		const notificationFound = notifications.find((n: NotificationModel) => n.relatedId === matchId)
		if (notificationFound) {
			yield put({
				type: NotificationsActions.DELETE_NOTIFICATION.toString(),
				payload: notificationFound.refId,
			})
		}
		history.goBack()
		yield put({
			type: MatchActions.GET_MATCH_FAILED.toString(),
			payload: error,
		})
		yield put({
			type: MatchActions.GET_TEAM_MATCHES_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_TOURNAMENT_MATCHES(action: MatchActionsTypes) {
	const id = action.payload as string
	try {
		const matches: MatchModel[] = yield call(getTournamentMatches, id)
		yield put({
			type: MatchActions.GET_TOURNAMENT_MATCHES_SUCCESS.toString(),
			payload: matches,
		})
	} catch (error) {
		debugger
		notification.warning({
			message: error.message,
		})
		yield put({
			type: MatchActions.GET_TOURNAMENT_MATCHES_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_TEAM_MATCHES(action: MatchActionsTypes) {
	const teamId = action.payload as string
	try {
		const matches: MatchModel[] = yield call(getTeamMatches, teamId)
		yield put({
			type: MatchActions.GET_TEAM_MATCHES_SUCCESS.toString(),
			payload: matches,
		})
	} catch (error) {
		// notification.warning({
		// 	message: error.message,
		// })
		// debugger
		// yield put({
		// 	type: MatchActions.GET_TEAM_MATCHES_FAILED.toString(),
		// 	payload: error,
		// })
	}
}

function* UPDATE_MATCH(action: MatchActionsTypes) {
	const match = action.payload as MatchUpdatePayload
	try {
		const updatedMatch: MatchModel = yield call(updateMatch, match)
		if (match.isCanceled && match.status === 'DONE') {
			history.goBack()
			notification.success({
				message: 'Match canceled successfully',
			})
			yield put({
				type: MatchActions.UPDATE_MATCH_SUCCESS.toString(),
				payload: null,
			})
		} else {
			yield put({
				type: MatchActions.UPDATE_MATCH_SUCCESS.toString(),
				payload: updatedMatch,
			})
			notification.success({
				message: 'Match updated successfully',
			})
			if (updatedMatch.competitionType === CompetitionType.Tournament) {
				const { currentTournament } = yield select(state => state.tournaments)
				yield put({
					type: TournamentsActions.GET_TOURNAMENT.toString(),
					payload: currentTournament.refId,
				})
			}
		}
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: MatchActions.UPDATE_MATCH_FAILED.toString(),
			payload: error,
		})
	}
}

function* ADD_MATCH_REPORT(action: MatchActionsTypes) {
	const matchReport = action.payload as MatchReportModel
	try {
		yield call(addMatchReport, matchReport)

		yield put({
			type: MatchActions.ADD_MATCH_REPORT_SUCCESS.toString(),
		})
		notification.success({
			message: 'Match report added',
		})

		history.go(0)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: MatchActions.ADD_MATCH_REPORT_FAILED.toString(),
			payload: error,
		})
	}
}

function* UPDATE_MATCH_REPORT(action: MatchActionsTypes) {
	const matchReport = action.payload as MatchReportModel
	try {
		yield call(updateMatchReport, matchReport)
		yield put({
			type: MatchActions.UPDATE_MATCH_REPORT_SUCCESS.toString(),
		})
		notification.success({
			message: 'Match report updated',
		})
		yield put({
			type: MatchActions.GET_MATCH.toString(),
			payload: matchReport.relatedId,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: MatchActions.UPDATE_MATCH_REPORT_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_COMPETITION_MATCHES(action: MatchActionsTypes) {
	const competitionId = action.payload as string
	try {
		const matches: MatchModel[] = yield call(getCompetitionMatches, competitionId)
		yield put({
			type: MatchActions.GET_COMPETITION_MATCHES_SUCCESS.toString(),
			payload: matches,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: MatchActions.GET_COMPETITION_MATCHES_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(MatchActions.GET_TEAM_MATCHES, GET_TEAM_MATCHES),
		takeLatest(MatchActions.GET_MATCH, GET_MATCH),
		takeLatest(MatchActions.UPDATE_MATCH, UPDATE_MATCH),
		takeLatest(MatchActions.GET_TOURNAMENT_MATCHES, GET_TOURNAMENT_MATCHES),
		takeLatest(MatchActions.ADD_MATCH_REPORT, ADD_MATCH_REPORT),
		takeLatest(MatchActions.UPDATE_MATCH_REPORT, UPDATE_MATCH_REPORT),
		takeLatest(MatchActions.GET_COMPETITION_MATCHES, GET_COMPETITION_MATCHES),
	])
}
