import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import _ from 'lodash'
import { addComment, addReplyToComment, deleteComment, editComment, getComments } from 'services/comments'
import { CommentModel, CommentType } from 'models/comments'
import { CommentActions, CommentPayload, CommentsActionsTypes, GetCommentsPayload } from './types'

function* ADD_COMMENT(action: CommentsActionsTypes) {
	const comment = action.payload as CommentPayload
	const { comments, user } = yield select(state => state)
	const { currentComments } = comments
	try {
		let updatedComments: CommentModel[]
		let newComment: CommentModel
		let newReply: CommentModel

		if (comment.parent === comment.relatedTo) {
			newComment = yield call(addComment, comment)
			const populatedComment = {
				...newComment,
				user,
				profile: user.activeProfile,
			}
			updatedComments = _.uniqBy([populatedComment, ...currentComments], 'refId')
		} else {
			newReply = yield call(addReplyToComment, comment)
			const populatedReply = {
				...newReply,
				user,
				profile: user.activeProfile,
			}
			updatedComments = _.map(currentComments, (currentComment: CommentModel) => {
				if (newReply.parent === currentComment._id) {
					currentComment.comments = _.uniqBy([populatedReply, ...currentComment.comments], 'refId')
				}
				return currentComment
			})
		}
		yield put({
			type: CommentActions.ADD_COMMENT_SUCCESS.toString(),
			payload: updatedComments,
		})
		notification.success({
			message: 'Comment added successfully',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: CommentActions.ADD_COMMENT_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_COMMENTS(action: CommentsActionsTypes) {
	const { entityId, type } = action.payload as GetCommentsPayload
	try {
		const comments: CommentModel[] = yield call(getComments, { entityId, type })
		const filteredComments = comments.filter(comment => {
			return (comment.deleted && Array.isArray(comment.comments) && comment.comments.length > 0) || !comment.deleted
		})
		yield put({
			type: CommentActions.GET_COMMENTS_SUCCESS.toString(),
			payload: filteredComments,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: CommentActions.GET_COMMENTS_FAILED.toString(),
			payload: error,
		})
	}
}

function* EDIT_COMMENT(action: CommentsActionsTypes) {
	const comment = action.payload as CommentModel
	const { currentComments }: { currentComments: CommentModel[] } = yield select(state => state.comments)

	try {
		const editedComment: CommentModel = yield call(editComment, comment)

		currentComments.filter((comment: CommentModel, parentIndex: string | number) => {
			if (comment.refId === editedComment.refId) {
				currentComments[parentIndex].content = editedComment.content
				currentComments[parentIndex].updatedAt = editedComment.updatedAt
				currentComments[parentIndex].deleted = editedComment.deleted
				return true
			}
			if (comment.comments.length > 0) {
				comment.comments.filter((c: CommentModel, index: string | number) => {
					if (c.refId === editedComment.refId) {
						currentComments[parentIndex].comments[index].content = editedComment.content
						currentComments[parentIndex].comments[index].updatedAt = editedComment.updatedAt
						currentComments[parentIndex].comments[index].deleted = editedComment.deleted
						return true
					}
					return false
				})
			}
			return false
		})
		const filteredComments = currentComments.filter(
			comment => (comment.deleted && Array.isArray(comment.comments) && comment.comments.length) || !comment.deleted,
		)
		yield put({
			type: CommentActions.EDIT_COMMENT_SUCCESS.toString(),
			payload: filteredComments,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: CommentActions.EDIT_COMMENT_FAILED.toString(),
			payload: error,
		})
	}
}

function* DELETE_COMMENT(action: CommentsActionsTypes) {
	const comment = action.payload as CommentModel
	const { currentComments }: { currentComments: CommentModel[] } = yield select(state => state.comments)

	try {
		const deletedComment: CommentModel = yield call(deleteComment, comment._id)

		currentComments.filter((comment: CommentModel, parentIndex: string | number) => {
			if (comment.refId === deletedComment.refId) {
				currentComments[parentIndex].deleted = deletedComment.deleted
				return true
			}
			if (comment.comments.length > 0) {
				comment.comments.filter((c: CommentModel, index: string | number) => {
					if (c.refId === deletedComment.refId) {
						currentComments[parentIndex].comments[index].deleted = deletedComment.deleted
						return true
					}
					return false
				})
			}
			return false
		})
		const filteredComments = currentComments.filter(
			comment =>
				(comment.deleted && Array.isArray(comment.comments) && comment.comments.length > 0) || !comment.deleted,
		)
		yield put({
			type: CommentActions.DELETE_COMMENT_SUCCESS.toString(),
			payload: filteredComments,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: CommentActions.DELETE_COMMENT_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(CommentActions.ADD_COMMENT, ADD_COMMENT),
		takeLatest(CommentActions.GET_COMMENTS, GET_COMMENTS),
		takeLatest(CommentActions.EDIT_COMMENT, EDIT_COMMENT),
		takeLatest(CommentActions.DELETE_COMMENT, DELETE_COMMENT),
	])
}
