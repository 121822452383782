import { all, call, put, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { AcceptTeamsPayload, JoinTournamentPayload, TournamentsActions, TournamentsActionsTypes } from './types'
import { getTournament, joinTournament, acceptTeams, updateTournament, startBracket } from 'services/tournaments'
import { TournamentModel } from '../../models'
import { PostsActions, PostType } from '../posts/types'
import { MatchActions } from '../matches/types'

function* GET_TOURNAMENT(action: TournamentsActionsTypes) {
	const refId = action.payload as string
	try {
		const tournament: TournamentModel = yield call(getTournament, refId)
		yield put({
			type: PostsActions.GET_ALL_POSTS.toString(),
			payload: {
				relatedId: tournament._id,
				type: PostType.TOURNAMENT,
			},
		})
		yield put({
			type: MatchActions.GET_TOURNAMENT_MATCHES.toString(),
			payload: tournament._id,
		})
		yield put({
			type: TournamentsActions.GET_TOURNAMENT_SUCCESS.toString(),
			payload: tournament,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TournamentsActions.GET_TOURNAMENT_FAILED.toString(),
			payload: error,
		})
	}
}

function* JOIN_TOURNAMENT(action: TournamentsActionsTypes) {
	const tournamentInfo = action.payload as JoinTournamentPayload
	try {
		const tournament: TournamentModel = yield call(joinTournament, tournamentInfo)
		yield put({
			type: TournamentsActions.JOIN_TOURNAMENT_SUCCESS.toString(),
			payload: tournament,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})

		yield put({
			type: TournamentsActions.JOIN_TOURNAMENT_FAILED.toString(),
			payload: error,
		})
	}
}

function* ACCEPT_TEAMS(action: TournamentsActionsTypes) {
	const teamsInfo = action.payload as AcceptTeamsPayload
	try {
		const tournament: TournamentModel = yield call(acceptTeams, teamsInfo)
		yield put({
			type: TournamentsActions.ACCEPT_TEAMS_SUCCESS.toString(),
			payload: [...tournament.teams!],
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})

		yield put({
			type: TournamentsActions.ACCEPT_TEAMS_FAILED.toString(),
			payload: error,
		})
	}
}

function* UPDATE_TOURNAMENT(action: TournamentsActionsTypes) {
	const tournament = action.payload as TournamentModel
	try {
		yield call(updateTournament, tournament)
		yield put({
			type: TournamentsActions.GET_TOURNAMENT.toString(),
			payload: tournament.refId,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})

		yield put({
			type: TournamentsActions.UPDATE_TOURNAMENT_FAILED.toString(),
			payload: error,
		})
	}
}

function* START_BRACKET(action: TournamentsActionsTypes) {
	const tournamentId = action.payload as string
	try {
		const updatedTournament: TournamentModel = yield call(startBracket, tournamentId)
		yield put({
			type: TournamentsActions.GET_TOURNAMENT.toString(),
			payload: updatedTournament.refId,
		})
		yield put({
			type: TournamentsActions.START_BRACKET_SUCCESS.toString(),
			payload: updatedTournament,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})

		yield put({
			type: TournamentsActions.START_BRACKET_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(TournamentsActions.GET_TOURNAMENT, GET_TOURNAMENT),
		takeLatest(TournamentsActions.JOIN_TOURNAMENT, JOIN_TOURNAMENT),
		takeLatest(TournamentsActions.ACCEPT_TEAMS, ACCEPT_TEAMS),
		takeLatest(TournamentsActions.UPDATE_TOURNAMENT, UPDATE_TOURNAMENT),
		takeLatest(TournamentsActions.START_BRACKET, START_BRACKET),
	])
}
