import React, { Fragment } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
// import Loader from 'components/cleanui/layout/Loader'
import PublicLayout from './Public'
import AuthLayout from './Auth'
import MainLayout from './Main'

const Layouts = {
	public: PublicLayout,
	auth: AuthLayout,
	main: MainLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
	// NProgress & ScrollTop Management
	const currentPath = pathname + search
	if (currentPath !== previousPath) {
		window.scrollTo(0, 0)
		NProgress.start()
	}
	setTimeout(() => {
		NProgress.done()
		previousPath = currentPath
	}, 300)

	// Layout Rendering
	const getLayout = () => {
		switch (pathname) {
			case '/':
				return 'public'
			case '/terms':
				return 'public'
			case '/privacy':
				return 'public'
			default:
				if (/^\/ladder(?=\/|$)/i.test(pathname)) {
					return 'main'
				}
				if (/^\/auth(?=\/|$)/i.test(pathname)) {
					return 'auth'
				}
				return 'main'
		}
	}

	const Container = Layouts[getLayout()]
	const isUserAuthorized = user && user.authorized
	const isUserLoading = user.loading
	const isAuthLayout = getLayout() === 'auth'

	const BootstrappedLayout = () => {
		// show loader when user in check authorization process, not authorized yet and not on login pages
		if (isUserLoading && !isAuthLayout) {
			return null
		}

		// IF f user is not authenticated

		// redirect to login page if current is not login page and user not authorized
		// if (
		//   !isUserAuthorized &&
		//   (/^\/create-team(?=\/|$)/i.test(pathname) || /^\/edit-user(?=\/|$)/i.test(pathname))
		// ) {
		//   return <Redirect to="/news" />
		// }

		// in other case render previously set layout
		return <Container isUserAuthorized={isUserAuthorized}>{children}</Container>
	}

	return (
		<Fragment>
			<Helmet titleTemplate="REDDAL | %s" title="Reddal" />
			{BootstrappedLayout()}
		</Fragment>
	)
}

export default withRouter(connect(mapStateToProps)(Layout))
