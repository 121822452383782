import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { searchTeam, searchUser } from 'services/search'
import { PlayerModel } from 'models/player'
import { SearchActions, SearchActionsTypes, SearchTerm } from './types'
import { TeamPayload } from '../teams/types'

function* SEARCH_USER(action: SearchActionsTypes) {
	const { term } = action.payload as SearchTerm
	try {
		const searchResults: PlayerModel[] = yield call(searchUser, term)
		console.log('1 ', searchResults)
		yield put({
			type: SearchActions.SEARCH_USER_SUCCESS.toString(),
			payload: searchResults,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: SearchActions.SEARCH_USER_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* SEARCH_TEAM(action: SearchActionsTypes) {
	const { term } = action.payload as SearchTerm
	try {
		const searchResults: TeamPayload[] = yield call(searchTeam, term)
		console.log('2 ', searchResults)
		yield put({
			type: SearchActions.SEARCH_TEAM_SUCCESS.toString(),
			payload: searchResults,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: SearchActions.SEARCH_TEAM_FAILED.toString(),
			payload: error.message,
		})
	}
}

export default function* rootSaga() {
	yield all([takeEvery(SearchActions.SEARCH_USER, SEARCH_USER), takeEvery(SearchActions.SEARCH_TEAM, SEARCH_TEAM)])
}
