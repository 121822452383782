import { ChatMessageModel } from 'models/chat-message'
import actionCreatorFactory from 'typescript-fsa'
import { ChatModel } from 'models/chat'
import { TypingModel } from 'models/typing'

const actionCreator = actionCreatorFactory()

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface GetChatMessagesPayload {
	chatId: string
	skip?: number
	limit?: number
}

export interface SendMessagePayload {
	refId?: string
	chatId: string
	from: string
	to: string
	data: {
		body: string
	}
	status: 'NEW' | 'SENT' | 'READ' | 'DELETED'
}

export interface GetMessagePayload {
	message: string
}

export enum SocketEvents {
	JOIN = 'join',
	CONNECT = 'connect',
	CONNECTION = 'connection',
	ROOM_ENTER = 'RoomEnter',
	MESSAGE_GET = 'messageGet',
	MESSAGE_SEND = 'messageSend',
	TYPING = 'typing',
	IS_TYPING = 'isTyping',
	DISCONNECT = 'disconnect',
	NOTIFICATION = 'notification',
	ERROR = 'error',
}

export enum ActionsTypes {
	SUBSCRIBE_TO_ALL_CHATS = 'SUBSCRIBE_TO_ALL_CHATS',
	USER_IS_TYPING = 'USER_IS_TYPING',
	USER_IS_TYPING_SUCCESS = 'USER_IS_TYPING_SUCCESS',
	USER_IS_TYPING_FAILED = 'USER_IS_TYPING_FAILED',
	GET_USER_CHATS = 'GET_USER_CHATS',
	GET_USER_CHATS_SUCCESS = 'GET_USER_CHATS_SUCCESS',
	GET_USER_CHATS_FAILED = 'GET_USER_CHATS_FAILED',
	SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT',
	GET_CHAT = 'GET_CHAT',
	GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS',
	GET_CHAT_FAILED = 'GET_CHAT_FAILED',
	GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES',
	GET_CHAT_MESSAGES_SUCCESS = 'GET_CHAT_MESSAGES_SUCCESS',
	GET_CHAT_MESSAGES_FAILED = 'GET_CHAT_MESSAGES_FAILED',
	SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE',
	SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS',
	NEW_SUBSCRIBED_CHAT_MESSAGE = 'NEW_SUBSCRIBED_CHAT_MESSAGE',
	NEW_SUBSCRIBED_CHAT_MESSAGE_SUCCESS = 'NEW_SUBSCRIBED_CHAT_MESSAGE_SUCCESS',
	NEW_SUBSCRIBED_CHAT_MESSAGE_FAILED = 'NEW_SUBSCRIBED_CHAT_MESSAGE_FAILED',
	SET_CHAT_AS_READ = 'SET_CHAT_AS_READ',
	SET_CHAT_AS_READ_SUCCESS = 'SET_CHAT_AS_READ_SUCCESS',
	SET_CHAT_AS_READ_FAILED = 'SET_CHAT_AS_READ_FAILED',
}
export const ChatsActions = {
	SUBSCRIBE_TO_ALL_CHATS: actionCreator(ActionsTypes.SUBSCRIBE_TO_ALL_CHATS),
	USER_IS_TYPING: actionCreator<TypingModel>(ActionsTypes.USER_IS_TYPING),
	USER_IS_TYPING_SUCCESS: actionCreator<ChatModel[]>(ActionsTypes.USER_IS_TYPING_SUCCESS),
	USER_IS_TYPING_FAILED: actionCreator<ErrorPayload>(ActionsTypes.USER_IS_TYPING_FAILED),
	GET_USER_CHATS: actionCreator(ActionsTypes.GET_USER_CHATS),
	GET_USER_CHATS_SUCCESS: actionCreator<ChatModel[]>(ActionsTypes.GET_USER_CHATS_SUCCESS),
	GET_USER_CHATS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_USER_CHATS_FAILED),
	SET_ACTIVE_CHAT: actionCreator<string>(ActionsTypes.SET_ACTIVE_CHAT),
	GET_CHAT: actionCreator<string>(ActionsTypes.GET_CHAT),
	GET_CHAT_SUCCESS: actionCreator<ChatModel[]>(ActionsTypes.GET_CHAT_SUCCESS),
	GET_CHAT_FAILED: actionCreator(ActionsTypes.GET_CHAT_FAILED),
	GET_CHAT_MESSAGES: actionCreator<GetChatMessagesPayload>(ActionsTypes.GET_CHAT_MESSAGES),
	GET_CHAT_MESSAGES_SUCCESS: actionCreator<ChatModel[]>(ActionsTypes.GET_CHAT_MESSAGES_SUCCESS),
	GET_CHAT_MESSAGES_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_CHAT_MESSAGES_FAILED),
	SEND_CHAT_MESSAGE: actionCreator<SendMessagePayload>(ActionsTypes.SEND_CHAT_MESSAGE),
	SEND_CHAT_MESSAGE_SUCCESS: actionCreator<any>(ActionsTypes.SEND_CHAT_MESSAGE_SUCCESS),
	NEW_SUBSCRIBED_CHAT_MESSAGE: actionCreator<ChatMessageModel>(ActionsTypes.NEW_SUBSCRIBED_CHAT_MESSAGE),
	NEW_SUBSCRIBED_CHAT_MESSAGE_SUCCESS: actionCreator<ChatModel[]>(ActionsTypes.NEW_SUBSCRIBED_CHAT_MESSAGE_SUCCESS),
	NEW_SUBSCRIBED_CHAT_MESSAGE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.NEW_SUBSCRIBED_CHAT_MESSAGE_FAILED),
	SET_CHAT_AS_READ: actionCreator<string>(ActionsTypes.SET_CHAT_AS_READ),
	SET_CHAT_AS_READ_SUCCESS: actionCreator<ChatModel[]>(ActionsTypes.SET_CHAT_AS_READ_SUCCESS),
	SET_CHAT_AS_READ_FAILED: actionCreator<ErrorPayload>(ActionsTypes.SET_CHAT_AS_READ_FAILED),
}

export type ChatsActionsTypes =
	| ReturnType<typeof ChatsActions.USER_IS_TYPING>
	| ReturnType<typeof ChatsActions.USER_IS_TYPING_SUCCESS>
	| ReturnType<typeof ChatsActions.USER_IS_TYPING_FAILED>
	| ReturnType<typeof ChatsActions.SUBSCRIBE_TO_ALL_CHATS>
	| ReturnType<typeof ChatsActions.GET_USER_CHATS>
	| ReturnType<typeof ChatsActions.GET_USER_CHATS_SUCCESS>
	| ReturnType<typeof ChatsActions.GET_USER_CHATS_FAILED>
	| ReturnType<typeof ChatsActions.GET_CHAT>
	| ReturnType<typeof ChatsActions.GET_CHAT_SUCCESS>
	| ReturnType<typeof ChatsActions.GET_CHAT_FAILED>
	| ReturnType<typeof ChatsActions.GET_CHAT_MESSAGES>
	| ReturnType<typeof ChatsActions.GET_CHAT_MESSAGES_SUCCESS>
	| ReturnType<typeof ChatsActions.GET_CHAT_MESSAGES_FAILED>
	| ReturnType<typeof ChatsActions.SEND_CHAT_MESSAGE>
	| ReturnType<typeof ChatsActions.SEND_CHAT_MESSAGE_SUCCESS>
	| ReturnType<typeof ChatsActions.NEW_SUBSCRIBED_CHAT_MESSAGE>
	| ReturnType<typeof ChatsActions.SET_ACTIVE_CHAT>
	| ReturnType<typeof ChatsActions.SET_CHAT_AS_READ>
	| ReturnType<typeof ChatsActions.SET_CHAT_AS_READ_SUCCESS>
	| ReturnType<typeof ChatsActions.SET_CHAT_AS_READ_FAILED>
