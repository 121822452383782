import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import store from 'store'
import { find } from 'lodash'
import defaultLogo from 'assets/images/logo.png'
import style from './style.module.scss'

const mapStateToProps = ({ menu, settings, user }) => ({
	menuData: menu.menuData,
	logo: settings.logo,
	menuColor: settings.menuColor,
	role: user.role,
})

const MenuTop = ({
	menuData = [],
	location: { pathname },

	menuColor,
	logo,
	role,
}) => {
	const [selectedKeys, setSelectedKeys] = useState(store.get('app.menu.selectedKeys') || [])

	useEffect(() => {
		applySelectedKeys()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, menuData])

	const applySelectedKeys = () => {
		const flattenItems = (items, key) =>
			items.reduce((flattenedItems, item) => {
				flattenedItems.push(item)
				if (Array.isArray(item[key])) {
					return flattenedItems.concat(flattenItems(item[key], key))
				}
				return flattenedItems
			}, [])
		const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
		setSelectedKeys(selectedItem ? [selectedItem.key] : [])
	}

	const handleClick = e => {
		store.set('app.menu.selectedKeys', [e.key])
		setSelectedKeys([e.key])
	}

	const generateMenuItems = () => {
		const generateItem = item => {
			const { key, title, url, icon, disabled, count } = item
			if (item.category) {
				return null
			}
			if (item.url) {
				return (
					<Menu.Item key={key} disabled={disabled}>
						{item.target && (
							<a href={url} target={item.target} rel="noopener noreferrer">
								{icon && <span className={`${icon} ${style.icon}`} />}
								<span className={style.title}>{title}</span>
								{count && <span className="badge badge-success ml-2">{count}</span>}
							</a>
						)}
						{!item.target && (
							<Link to={url}>
								{icon && <span className={`${icon} ${style.icon}`} />}
								<span className={style.title}>{title}</span>
								{count && <span className="badge badge-success ml-2">{count}</span>}
							</Link>
						)}
					</Menu.Item>
				)
			}
			return (
				<Menu.Item key={key} disabled={disabled}>
					{icon && <span className={`${icon} ${style.icon}`} />}
					<span className={style.title}>{title}</span>
					{count && <span className="badge badge-success ml-2">{count}</span>}
				</Menu.Item>
			)
		}
		const generateSubmenu = items =>
			items.map(menuItem => {
				if (menuItem.children) {
					const subMenuTitle = (
						<span key={menuItem.key}>
							{menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
							<span className={style.title}>{menuItem.title}</span>
							{menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
						</span>
					)
					return (
						<Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
							{generateSubmenu(menuItem.children)}
						</Menu.SubMenu>
					)
				}
				return generateItem(menuItem)
			})
		return menuData.map(menuItem => {
			if (menuItem.roles && !menuItem.roles.includes(role)) {
				return null
			}
			if (menuItem.children) {
				const subMenuTitle = (
					<span key={menuItem.key}>
						{menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
						<span className={style.title}>{menuItem.title}</span>
						{menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
					</span>
				)
				return (
					<Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
						{generateSubmenu(menuItem.children)}
					</Menu.SubMenu>
				)
			}
			return generateItem(menuItem)
		})
	}

	return (
		<div
			className={classNames(`${style.menu}`, {
				[style.white]: menuColor === 'white',
				[style.gray]: menuColor === 'gray',
				[style.dark]: menuColor === 'dark',
			})}>
			<div className={style.logoContainer}>
				<div className={style.logo}>
					<img src={defaultLogo} className="mr-2" alt="REDDAL" />
					<div className={style.name}>{logo}</div>
					{logo === 'REDDAL' && <div className={style.descr}>Reddal</div>}
				</div>
			</div>
			<div className={style.navigation}>
				<Menu onClick={handleClick} selectedKeys={selectedKeys} mode="horizontal">
					{generateMenuItems()}
				</Menu>
			</div>
		</div>
	)
}

export default withRouter(connect(mapStateToProps)(MenuTop))
