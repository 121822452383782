import actionCreatorFactory from 'typescript-fsa'
import { TournamentModel } from '../../models'
import { TeamModel } from '../../models/team'

const actionCreator = actionCreatorFactory()

export interface JoinTournamentPayload {
	tournamentId: string
	teamId: string
}

export interface AcceptTeamsPayload {
	tournamentId: string
	teamIds: string[]
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export enum ActionsTypes {
	GET_TOURNAMENT = 'GET_TOURNAMENT',
	GET_TOURNAMENT_SUCCESS = 'GET_TOURNAMENT_SUCCESS',
	GET_TOURNAMENT_FAILED = 'GET_TOURNAMENT_FAILED',
	JOIN_TOURNAMENT = 'JOIN_TOURNAMENT',
	JOIN_TOURNAMENT_SUCCESS = 'JOIN_TOURNAMENT_SUCCESS',
	JOIN_TOURNAMENT_FAILED = 'JOIN_TOURNAMENT_FAILED',
	ACCEPT_TEAMS = 'ACCEPT_TEAMS',
	ACCEPT_TEAMS_SUCCESS = 'ACCEPT_TEAMS_SUCCESS',
	ACCEPT_TEAMS_FAILED = 'ACCEPT_TEAMS_FAILED',
	UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT',
	UPDATE_TOURNAMENT_SUCCESS = 'UPDATE_TOURNAMENT_SUCCESS',
	UPDATE_TOURNAMENT_FAILED = 'UPDATE_TOURNAMENT_FAILED',
	START_BRACKET = 'START_BRACKET',
	START_BRACKET_SUCCESS = 'START_BRACKET_SUCCESS',
	START_BRACKET_FAILED = 'START_BRACKET_FAILED',
}

export const TournamentsActions = {
	GET_TOURNAMENT: actionCreator<string>(ActionsTypes.GET_TOURNAMENT),
	GET_TOURNAMENT_SUCCESS: actionCreator<TournamentModel>(ActionsTypes.GET_TOURNAMENT_SUCCESS),
	GET_TOURNAMENT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_TOURNAMENT_FAILED),
	JOIN_TOURNAMENT: actionCreator<JoinTournamentPayload>(ActionsTypes.JOIN_TOURNAMENT),
	JOIN_TOURNAMENT_SUCCESS: actionCreator<TournamentModel>(ActionsTypes.JOIN_TOURNAMENT_SUCCESS),
	JOIN_TOURNAMENT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.JOIN_TOURNAMENT_FAILED),
	ACCEPT_TEAMS: actionCreator<AcceptTeamsPayload>(ActionsTypes.ACCEPT_TEAMS),
	ACCEPT_TEAMS_SUCCESS: actionCreator<TeamModel[]>(ActionsTypes.ACCEPT_TEAMS_SUCCESS),
	ACCEPT_TEAMS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.ACCEPT_TEAMS_FAILED),
	UPDATE_TOURNAMENT: actionCreator<TournamentModel>(ActionsTypes.UPDATE_TOURNAMENT),
	UPDATE_TOURNAMENT_SUCCESS: actionCreator<TournamentModel>(ActionsTypes.UPDATE_TOURNAMENT_SUCCESS),
	UPDATE_TOURNAMENT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.UPDATE_TOURNAMENT_FAILED),
	START_BRACKET: actionCreator<string>(ActionsTypes.START_BRACKET),
	START_BRACKET_SUCCESS: actionCreator<TournamentModel>(ActionsTypes.START_BRACKET_SUCCESS),
	START_BRACKET_FAILED: actionCreator<ErrorPayload>(ActionsTypes.START_BRACKET_FAILED),
}

export type TournamentsActionsTypes =
	| ReturnType<typeof TournamentsActions.GET_TOURNAMENT>
	| ReturnType<typeof TournamentsActions.GET_TOURNAMENT_SUCCESS>
	| ReturnType<typeof TournamentsActions.GET_TOURNAMENT_FAILED>
	| ReturnType<typeof TournamentsActions.JOIN_TOURNAMENT>
	| ReturnType<typeof TournamentsActions.JOIN_TOURNAMENT_SUCCESS>
	| ReturnType<typeof TournamentsActions.JOIN_TOURNAMENT_FAILED>
	| ReturnType<typeof TournamentsActions.ACCEPT_TEAMS>
	| ReturnType<typeof TournamentsActions.ACCEPT_TEAMS_SUCCESS>
	| ReturnType<typeof TournamentsActions.ACCEPT_TEAMS_FAILED>
	| ReturnType<typeof TournamentsActions.UPDATE_TOURNAMENT>
	| ReturnType<typeof TournamentsActions.UPDATE_TOURNAMENT_SUCCESS>
	| ReturnType<typeof TournamentsActions.UPDATE_TOURNAMENT_FAILED>
	| ReturnType<typeof TournamentsActions.START_BRACKET>
	| ReturnType<typeof TournamentsActions.START_BRACKET_SUCCESS>
	| ReturnType<typeof TournamentsActions.START_BRACKET_FAILED>
