import apiClient from '../api'
import { VotePayload } from '../../store/polls/types'

export async function getPoll(pollId: string) {
	return apiClient
		.get(`/poll/${pollId}/`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No posts response data')
		})
		.catch(err => {
			throw err
		})
}

export async function votePoll({ pollId, optionId }: VotePayload) {
	return apiClient
		.put(`/poll/${pollId}/vote`, { vote: optionId })
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}
