import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
	const settings = {}
	Object.keys(storedSettings).forEach(key => {
		const item = store.get(`app.settings.${key}`)
		settings[key] = typeof item !== 'undefined' ? item : storedSettings[key]
	})
	return settings
}

const initialState = {
	...STORED_SETTINGS({
		authProvider: 'firebase', // firebase, jwt
		logo: 'REDDAL',
		locale: 'en-US',
		isSidebarOpen: false,
		isSupportChatOpen: false,
		isMobileView: false,
		isMobileMenuOpen: false,
		isMenuCollapsed: false,
		menuLayoutType: 'left', // left, top, nomenu
		routerAnimation: 'slide-fadein-right', // none, slide-fadein-up, slide-fadein-right, fadein, zoom-fadein
		menuColor: 'dark', // white, dark, gray
		theme: 'dark', // default, dark
		authPagesColor: 'white', // white, gray, image
		primaryColor: '#eeb10b',
		leftMenuWidth: 256,
		isMenuUnfixed: false,
		isMenuShadow: false,
		isTopbarFixed: true,
		isGrayTopbar: false,
		isContentMaxWidth: true,
		isAppMaxWidth: false,
		isGrayBackground: false,
		isCardShadow: true,
		isSquaredBorders: false,
		isBorderless: false,
	}),
}

export default function settingsReducer(state = initialState, action) {
	switch (action.type) {
		case actions.SET_STATE:
			return { ...state, ...action.payload }
		default:
			return state
	}
}
