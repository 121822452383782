import React from 'react'
import style from './style.module.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
	return (
		<div className={style.footer}>
			<div className={style.footerInner}>
				<a href="https://reddal.gg" target="_self" rel="noopener noreferrer" className={style.logo}>
					REDDAL <span className="mr-2" /> Ride to the top
				</a>
				<br />
				<p className="mb-0">
					Copyright © 2021 Codnyx | <Link to={'/privacy'}>Privacy Policy</Link>
				</p>
			</div>
		</div>
	)
}

export default Footer
