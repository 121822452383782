import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ChatModel } from 'models/chat'
import { ChatsActions } from './types'

export interface ChatsState {
	chats: ChatModel[] // false is default value
	activeChat: string
	loading: boolean
	error: unknown | null
}

const chatsInitialState: ChatsState = {
	chats: [],
	activeChat: '',
	loading: false,
	error: null,
}

const chatsReducer = reducerWithInitialState(chatsInitialState)
	// LOADING CASES
	.case(ChatsActions.GET_USER_CHATS, state => ({ ...state, loading: true }))
	.case(ChatsActions.GET_CHAT, state => ({ ...state, loading: true }))
	.case(ChatsActions.SEND_CHAT_MESSAGE, state => ({ ...state, loading: true }))
	.case(ChatsActions.SET_CHAT_AS_READ, state => ({ ...state, loading: true }))
	.case(ChatsActions.GET_CHAT_MESSAGES, state => ({
		...state,
		loading: true,
	}))
	// SUCCESS CASES
	.case(ChatsActions.GET_USER_CHATS_SUCCESS, (state, chats) => ({
		...state,
		chats,
		loading: false,
	}))
	.case(ChatsActions.GET_CHAT_SUCCESS, (state, updatedChats) => ({
		...state,
		chats: updatedChats,
		loading: false,
	}))
	.case(ChatsActions.SET_CHAT_AS_READ_SUCCESS, (state, updatedChats) => ({
		...state,
		chats: updatedChats,
		loading: false,
	}))
	.case(ChatsActions.USER_IS_TYPING_SUCCESS, (state, updatedChats) => ({
		...state,
		chats: updatedChats,
		loading: false,
	}))
	.case(ChatsActions.SET_ACTIVE_CHAT, (state, activeChat) => ({
		...state,
		activeChat,
	}))
	.case(ChatsActions.SEND_CHAT_MESSAGE_SUCCESS, (state, chats) => ({
		...state,
		chats,
		loading: false,
	}))
	.case(ChatsActions.NEW_SUBSCRIBED_CHAT_MESSAGE_SUCCESS, (state, chats) => ({
		...state,
		chats,
		loading: false,
	}))
	.case(ChatsActions.SET_CHAT_AS_READ_FAILED, state => ({
		...state,
		loading: false,
	}))
	.case(ChatsActions.GET_CHAT_MESSAGES_SUCCESS, (state, chats) => ({
		...state,
		chats,
		loading: false,
	}))

	// FAILED CASES
	.case(ChatsActions.GET_USER_CHATS_FAILED, (state, error) => ({ ...state, error, loading: false }))

	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default chatsReducer
