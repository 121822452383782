import actionCreatorFactory from 'typescript-fsa'
import { MatchModel } from 'models/match'
import { MatchReportModel } from '../../models/matchReport'
import { ChallengeModel } from '../../models/challenge'

const actionCreator = actionCreatorFactory()

export interface MatchState {
	currentMatch: MatchModel | null
	matches: MatchModel[]
	competitionMatches: MatchModel[]
	loading: boolean
	error: unknown | null
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface MatchUpdatePayload {
	_id: string
	refId: string
	challengerScore?: number
	opponentScore?: number
	room?: string
	date?: string
	password?: string
	status?: string
	isCanceled?: boolean | null
	waitingTeam?: string
	noshow?: string // refId of the team that did noshow
	forfeit?: string // refId of the team that forfeited
	count?: number
}

export enum ActionsTypes {
	GET_MATCH = 'GET_MATCH',
	GET_MATCH_SUCCESS = 'GET_MATCH_SUCCESS',
	GET_MATCH_FAILED = 'GET_MATCH_FAILED',
	GET_TEAM_MATCHES = 'GET_TEAM_MATCHES',
	GET_TEAM_MATCHES_SUCCESS = 'GET_TEAM_MATCHES_SUCCESS',
	GET_TEAM_MATCHES_FAILED = 'GET_TEAM_MATCHES_FAILED',
	UPDATE_MATCH = 'UPDATE_MATCH',
	UPDATE_MATCH_SUCCESS = 'UPDATE_MATCH_SUCCESS',
	UPDATE_MATCH_FAILED = 'UPDATE_MATCH_FAILED',
	ADD_MATCH_REPORT = 'ADD_MATCH_REPORT',
	ADD_MATCH_REPORT_SUCCESS = 'ADD_MATCH_REPORT_SUCCESS',
	ADD_MATCH_REPORT_FAILED = 'ADD_MATCH_REPORT_FAILED',
	UPDATE_MATCH_REPORT = 'UPDATE_MATCH_REPORT',
	UPDATE_MATCH_REPORT_SUCCESS = 'UPDATE_MATCH_REPORT_SUCCESS',
	UPDATE_MATCH_REPORT_FAILED = 'UPDATE_MATCH_REPORT_FAILED',
	GET_TOURNAMENT_MATCHES = 'GET_TOURNAMENT_MATCHES',
	GET_TOURNAMENT_MATCHES_SUCCESS = 'GET_TOURNAMENT_MATCHES_SUCCESS',
	GET_TOURNAMENT_MATCHES_FAILED = 'GET_TOURNAMENT_MATCHES_FAILED',
	GET_COMPETITION_MATCHES = 'GET_COMPETITION_MATCHES',
	GET_COMPETITION_MATCHES_SUCCESS = 'GET_COMPETITION_MATCHES_SUCCESS',
	GET_COMPETITION_MATCHES_FAILED = 'GET_COMPETITION_MATCHES_FAILED',
}
export const MatchActions = {
	GET_MATCH: actionCreator<string>(ActionsTypes.GET_MATCH),
	GET_MATCH_SUCCESS: actionCreator<MatchModel>(ActionsTypes.GET_MATCH_SUCCESS),
	GET_MATCH_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_MATCH_FAILED),
	UPDATE_MATCH: actionCreator<MatchUpdatePayload>(ActionsTypes.UPDATE_MATCH),
	UPDATE_MATCH_SUCCESS: actionCreator<MatchModel>(ActionsTypes.UPDATE_MATCH_SUCCESS),
	UPDATE_MATCH_FAILED: actionCreator<ErrorPayload>(ActionsTypes.UPDATE_MATCH_FAILED),
	GET_TEAM_MATCHES: actionCreator<string>(ActionsTypes.GET_TEAM_MATCHES),
	GET_TEAM_MATCHES_SUCCESS: actionCreator<MatchModel[]>(ActionsTypes.GET_TEAM_MATCHES_SUCCESS),
	GET_TEAM_MATCHES_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_TEAM_MATCHES_FAILED),
	GET_TOURNAMENT_MATCHES: actionCreator<string>(ActionsTypes.GET_TOURNAMENT_MATCHES),
	GET_TOURNAMENT_MATCHES_SUCCESS: actionCreator<MatchModel[]>(ActionsTypes.GET_TOURNAMENT_MATCHES_SUCCESS),
	GET_TOURNAMENT_MATCHES_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_TOURNAMENT_MATCHES_FAILED),
	ADD_MATCH_REPORT: actionCreator<MatchReportModel>(ActionsTypes.ADD_MATCH_REPORT),
	ADD_MATCH_REPORT_SUCCESS: actionCreator(ActionsTypes.ADD_MATCH_REPORT_SUCCESS),
	ADD_MATCH_REPORT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.ADD_MATCH_REPORT_FAILED),
	UPDATE_MATCH_REPORT: actionCreator<MatchReportModel>(ActionsTypes.UPDATE_MATCH_REPORT),
	UPDATE_MATCH_REPORT_SUCCESS: actionCreator(ActionsTypes.UPDATE_MATCH_REPORT_SUCCESS),
	UPDATE_MATCH_REPORT_FAILED: actionCreator<ErrorPayload>(ActionsTypes.UPDATE_MATCH_REPORT_FAILED),
	GET_COMPETITION_MATCHES: actionCreator<string>(ActionsTypes.GET_COMPETITION_MATCHES),
	GET_COMPETITION_MATCHES_SUCCESS: actionCreator<MatchModel[]>(ActionsTypes.GET_COMPETITION_MATCHES_SUCCESS),
	GET_COMPETITION_MATCHES_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_COMPETITION_MATCHES_FAILED),
}

export type MatchActionsTypes =
	| ReturnType<typeof MatchActions.GET_MATCH>
	| ReturnType<typeof MatchActions.GET_MATCH_SUCCESS>
	| ReturnType<typeof MatchActions.GET_MATCH_FAILED>
	| ReturnType<typeof MatchActions.GET_TEAM_MATCHES>
	| ReturnType<typeof MatchActions.GET_TEAM_MATCHES_SUCCESS>
	| ReturnType<typeof MatchActions.GET_TEAM_MATCHES_FAILED>
	| ReturnType<typeof MatchActions.UPDATE_MATCH>
	| ReturnType<typeof MatchActions.UPDATE_MATCH_SUCCESS>
	| ReturnType<typeof MatchActions.UPDATE_MATCH_FAILED>
	| ReturnType<typeof MatchActions.ADD_MATCH_REPORT>
	| ReturnType<typeof MatchActions.ADD_MATCH_REPORT_SUCCESS>
	| ReturnType<typeof MatchActions.ADD_MATCH_REPORT_FAILED>
	| ReturnType<typeof MatchActions.UPDATE_MATCH_REPORT>
	| ReturnType<typeof MatchActions.UPDATE_MATCH_REPORT_SUCCESS>
	| ReturnType<typeof MatchActions.UPDATE_MATCH_REPORT_FAILED>
	| ReturnType<typeof MatchActions.GET_TOURNAMENT_MATCHES>
	| ReturnType<typeof MatchActions.GET_TOURNAMENT_MATCHES_SUCCESS>
	| ReturnType<typeof MatchActions.GET_TOURNAMENT_MATCHES_FAILED>
	| ReturnType<typeof MatchActions.GET_COMPETITION_MATCHES>
	| ReturnType<typeof MatchActions.GET_COMPETITION_MATCHES_SUCCESS>
	| ReturnType<typeof MatchActions.GET_COMPETITION_MATCHES_FAILED>
