import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
	const [count, setCount] = useState(7)

	const logout = e => {
		e.preventDefault()
		dispatch({
			type: 'LOGOUT',
		})
	}

	const addCount = () => {
		setCount(count + 1)
	}

	const menu = (
		<Menu selectable={false}>
			<Menu.Item key={'hello'}>
				<Link to={`/user/${user.refId}`}>
					<strong>
						<FormattedMessage id="topBar.profileMenu.hello" />, {user.username || 'Anonymous'}
					</strong>
				</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key={'create-team'}>
				<Link to="/create-team">
					<i className="fe fe-user-plus mr-2" />
					<FormattedMessage id="topBar.profileMenu.createTeam" />
				</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key={'edit-user'}>
				<Link to="/user-edit">
					<i className="fe fe-user mr-2" />
					<FormattedMessage id="topBar.profileMenu.editProfile" />
				</Link>
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key={'logout'}>
				<a href="#" onClick={logout}>
					<i className="fe fe-log-out mr-2" />
					<FormattedMessage id="topBar.profileMenu.logout" />
				</a>
			</Menu.Item>
		</Menu>
	)
	return (
		<Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
			<div className={styles.dropdown}>
				<Avatar className={styles.avatar} shape="square" size="large" src={user.avatar} icon={<UserOutlined />} />
			</div>
		</Dropdown>
	)
}

export default connect(mapStateToProps)(ProfileMenu)
