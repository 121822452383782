import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PlayerActions } from './types'
import { CountryType } from '../../components/uikit/flag/flags'

export interface PlayerState {
	refId: string | null
	email: string | null
	username: string | null
	firstName?: string | null
	lastName?: string | null
	avatar?: string | null
	banner?: string | null
	profiles?: Array<Record<string, any>> | null
	country?: CountryType | null
	roles?: Array<string> | null
	authorized?: boolean // false is default value
	status?: string | null // false is default value
	loading: boolean
	error: unknown | null
}
const playerInitialState: PlayerState = {
	refId: null,
	email: null,
	username: null,
	firstName: null,
	lastName: null,
	profiles: null,
	avatar: null,
	banner: null,
	country: null,
	roles: null,
	authorized: false, // false is default value
	status: null, // false is default value
	loading: false,
	error: null,
}

const playerReducer = reducerWithInitialState(playerInitialState)
	// LOADING CASES
	.case(PlayerActions.GET_PLAYER, state => ({ ...playerInitialState, loading: true }))
	.case(PlayerActions.GET_PLAYERS, state => ({ ...state, loading: true }))
	.case(PlayerActions.GET_PLAYER_TEAMS, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(PlayerActions.GET_PLAYER_SUCCESS, (state, player) => ({
		...state,
		...player,
		loading: false,
	}))
	// .case(PlayerActions.GET_PLAYER_TEAMS_SUCCESS, (state, currentPlayerTeams) => ({
	//   ...state,
	//   ...currentPlayerTeams,
	// }))

	// FAILED CASES
	.case(PlayerActions.GET_PLAYER_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(PlayerActions.GET_PLAYERS_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default playerReducer
