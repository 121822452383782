import localeAntd from 'antd/es/locale/zh_CN'

const messages = {
	'topBar.issuesHistory': '发布历史',
	'topBar.projectManagement': '项目管理',
	'topBar.typeToSearch': '搜索...',
	'topBar.findPages': '查找页面...',
	'topBar.actions': '动作',
	'topBar.status': '状态',
	'topBar.profileMenu.hello': '你好',
	'topBar.profileMenu.billingPlan': '结算计划',
	'topBar.profileMenu.role': '角色',
	'topBar.profileMenu.email': '电子邮件',
	'topBar.profileMenu.phone': '电话',
	'topBar.profileMenu.editProfile': '编辑个人资料',
	'topBar.profileMenu.logout': '登出',
	'topBar.profileMenu.createTeam': 'Create Team',
}

export default {
	locale: 'zh-CN',
	localeAntd,
	messages,
}
