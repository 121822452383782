import localeAntd from 'antd/es/locale/ru_RU'

const messages = {
	'topBar.issuesHistory': 'История заданий',
	'topBar.projectManagement': 'Управление проектом',
	'topBar.typeToSearch': 'Поиск...',
	'topBar.findPages': 'Поиск страниц...',
	'topBar.actions': 'Действия',
	'topBar.status': 'Статус',
	'topBar.profileMenu.hello': 'Привет',
	'topBar.profileMenu.billingPlan': 'Тарифный план',
	'topBar.profileMenu.role': 'Роль',
	'topBar.profileMenu.email': 'Емайл',
	'topBar.profileMenu.phone': 'Телефон',
	'topBar.profileMenu.createTeam': 'Create Team',
	'topBar.profileMenu.editProfile': 'Редактировать профиль',
	'topBar.profileMenu.logout': 'Выйти',
}

export default {
	locale: 'ru-RU',
	localeAntd,
	messages,
}
