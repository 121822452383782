import apiClient from '../api'
import { PostModel } from '../../models/posts'
import { PostType } from '../../store/posts/types'

export async function getSinglePosts(refId: string) {
	return apiClient
		.get(`/posts/${refId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No posts response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getPosts(type: PostType, relatedId: string, skip: number = 0, limit: number = 10) {
	return apiClient
		.get(`/posts`, {
			params: {
				type,
				relatedId,
				skip,
				limit,
			},
		})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No posts response data')
		})
		.catch(err => {
			throw err
		})
}

export async function addSinglePosts(postItem: PostModel) {
	return apiClient
		.post(`/addPosts`, postItem)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function deletePost(postId: string) {
	return apiClient
		.delete(`/post/${postId}`, {})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No post response data')
		})
		.catch(err => {
			throw err
		})
}
