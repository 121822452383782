import { RcFile } from 'antd/lib/upload/interface'
import { ProfileModel } from 'models/profile'
import { UserGuidModel } from 'models/guid'
import { HandleInvitePayload, UserActions, UserUpdateData } from './types'

export const register = (username: string, email: string, password: string) => {
	return UserActions.REGISTER({ username, email, password })
}
export const discordLogin = (token: string) => {
	console.log('discordLogin', token)
	return UserActions.DISCORD_LOGIN(token)
}

export const login = (email: string, password: string) => UserActions.LOGIN({ email, password })

export const updateUser = (user: UserUpdateData, avatar?: RcFile, banner?: RcFile) => {
	return UserActions.UPDATE_USER({ userData: user, avatar, banner })
}

export const handleInvite = (invite: HandleInvitePayload) => {
	return UserActions.HANDLE_INVITE(invite)
}

export const setActiveProfile = (activeProfile: ProfileModel) => {
	return UserActions.SET_ACTIVE_PROFILE(activeProfile)
}

export const addUserGuid = (guids: UserGuidModel[]) => {
	return UserActions.ADD_USER_GUID(guids)
}

export const getUserGuids = () => {
	return UserActions.GET_USER_GUIDS()
}

export const changeUserPassword = (password: string) => {
	return UserActions.CHANGE_USER_PASSWORD(password)
}

export const forgotPassword = (email: string) => {
	return UserActions.FORGOT_PASSWORD(email)
}
