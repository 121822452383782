import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import {
	getTeam,
	createTeam,
	userTeams,
	editTeam,
	invitePlayers,
	kickPlayer,
	changeRole,
	deleteTeam,
} from 'services/teams'
import { TeamModel } from 'models/team'
import { TeamsActionsTypes, TeamsActions, CreateTeamPayload, EditTeamPayload, ChangeRolePayload } from './types'
import { uploadImage } from '../../services/utils'
import { history } from '../index'
import { UserActions } from '../user/types'
import { TeamInviteModel } from '../../models/team-invite'

function* GET_TEAM(action: TeamsActionsTypes) {
	const refId = action.payload as string
	try {
		const teamData: TeamModel = yield call(getTeam, refId)
		yield put({
			type: TeamsActions.GET_TEAM_SUCCESS.toString(),
			payload: teamData,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
	}
}

function* GET_USER_TEAMS() {
	try {
		const teams: TeamModel[] = yield call(userTeams)
		yield put({
			type: TeamsActions.GET_USER_TEAMS_SUCCESS.toString(),
			payload: teams,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.GET_USER_TEAMS_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* CREATE_TEAM(action: TeamsActionsTypes) {
	const { teamData, banner, logo } = action.payload as CreateTeamPayload

	try {
		teamData.refId = uuidv4()
		if (logo) {
			// @ts-ignore

			const teamLogo = yield call(uploadImage, 'teams', 'logo', teamData.refId!, logo!)
			teamData.logo = teamLogo.file
		} else {
			teamData.logo = `${process.env.REACT_APP_CDN_URL}/files/placeholders/team_logo.png`
		}
		if (banner) {
			// @ts-ignore

			const teamBanner = yield call(uploadImage, 'teams', 'banner', teamData.refId!, banner!)
			teamData.banner = teamBanner.file
		}
		if (teamData.players?.length > 0) {
			teamData.players = teamData.players.map((player: { key: string }) => player.key)
		}
		// @ts-ignore

		const teamCreatedSuccess = yield call(createTeam, teamData)

		yield put({
			type: UserActions.GET_USER_SUCCESS.toString(),
			payload: teamCreatedSuccess.user,
		})
		yield put({
			type: TeamsActions.CREATE_TEAM_SUCCESS.toString(),
			payload: teamCreatedSuccess.team,
		})
		history.push(`/team/${teamCreatedSuccess.team.refId}`)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.CREATE_TEAM_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* EDIT_TEAM(action: TeamsActionsTypes) {
	const { teamData, banner, logo } = action.payload as EditTeamPayload
	try {
		if (logo) {
			// @ts-ignore
			const teamLogo = yield call(uploadImage, 'teams', 'logo', teamData.refId!, logo!)
			teamData.logo = teamLogo.file
		}
		if (banner) {
			// @ts-ignore
			const teamBanner = yield call(uploadImage, 'teams', 'banner', teamData.refId!, banner!)
			teamData.banner = teamBanner.file
		}
		// @ts-ignore
		const teamEditedSuccess = yield call(editTeam, teamData)
		yield put({
			type: TeamsActions.EDIT_TEAM_SUCCESS.toString(),
			payload: teamEditedSuccess,
		})
		//yield history.push(`/team/${teamEditedSuccess[0][2].refId}`)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.EDIT_TEAM_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* INVITE_PLAYERS(action: TeamsActionsTypes) {
	const players = action.payload as TeamInviteModel
	try {
		yield call(invitePlayers, players)
		yield put({
			type: TeamsActions.INVITE_PLAYERS_SUCCESS.toString(),
		})
		notification.success({
			message: 'Players were invited',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.INVITE_PLAYERS_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* KICK_PLAYER(action: TeamsActionsTypes) {
	const refId = action.payload as string
	const { currentTeam } = yield select(state => state.teams)
	try {
		yield call(kickPlayer, refId)
		yield put({
			type: TeamsActions.GET_TEAM.toString(),
			payload: currentTeam.refId,
		})
		yield put({
			type: TeamsActions.KICK_PLAYER_SUCCESS.toString(),
		})
		notification.info({
			message: 'Player was kicked from the team',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.KICK_PLAYER_FAILED.toString(),
			payload: error,
		})
	}
}

function* CHANGE_ROLE(action: TeamsActionsTypes) {
	const newRole = action.payload as ChangeRolePayload
	const { currentTeam } = yield select(state => state.teams)
	const { activeProfile } = yield select(state => state.user)
	try {
		yield call(changeRole, newRole)
		if (
			activeProfile._id === newRole.profileObjectId ||
			(activeProfile.role === 'owner' && newRole.newRole === 'owner')
		) {
			yield put({
				type: UserActions.GET_USER.toString(),
			})
		}
		yield put({
			type: TeamsActions.GET_TEAM.toString(),
			payload: currentTeam.refId,
		})
		yield put({
			type: TeamsActions.CHANGE_ROLE_SUCCESS.toString(),
		})
		notification.success({
			message: 'Role Changed',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.CHANGE_ROLE_FAILED.toString(),
			payload: error,
		})
	}
}

function* DELETE_TEAM(action: TeamsActionsTypes) {
	const { refId } = action.payload as TeamModel
	try {
		yield call(deleteTeam, refId)
		// @ts-ignore
		yield put({
			type: TeamsActions.DELETE_TEAM_SUCCESS.toString(),
		})
		history.go(0)
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: TeamsActions.DELETE_TEAM_FAILED.toString(),
			payload: error.message,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(TeamsActions.GET_TEAM, GET_TEAM),
		takeEvery(TeamsActions.CREATE_TEAM, CREATE_TEAM),
		takeEvery(TeamsActions.GET_USER_TEAMS, GET_USER_TEAMS),
		takeEvery(TeamsActions.EDIT_TEAM, EDIT_TEAM),
		takeEvery(TeamsActions.INVITE_PLAYERS, INVITE_PLAYERS),
		takeEvery(TeamsActions.KICK_PLAYER, KICK_PLAYER),
		takeEvery(TeamsActions.CHANGE_ROLE, CHANGE_ROLE),
		takeEvery(TeamsActions.DELETE_TEAM, DELETE_TEAM),
	])
}
