import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { AddPostsPayload, GetAllPostsPayload, GetSinglePostsPayload, PostsActions, PostsActionsTypes } from './types'
import { addSinglePosts, getPosts, getSinglePosts, deletePost } from '../../services/posts'
import { uploadImage } from '../../services/utils'
import { CommentActions } from '../comments/types'
import { CommentType } from '../../models/comments'
import { PostModel } from '../../models/posts'

function* GET_SINGLE_POSTS(action: PostsActionsTypes) {
	const { refId } = action.payload as GetSinglePostsPayload
	try {
		// @ts-ignore
		const singlePostsData: PostModel = yield call(getSinglePosts, refId)
		yield put({
			type: PostsActions.GET_SINGLE_POSTS_SUCCESS.toString(),
			payload: singlePostsData,
		})
		yield put({
			type: CommentActions.GET_COMMENTS.toString(),
			payload: {
				entityId: singlePostsData._id,
				type: CommentType.POST,
			},
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: PostsActions.GET_SINGLE_POSTS_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_ALL_POSTS(action: PostsActionsTypes) {
	try {
		const { type, relatedId, skip, limit } = action.payload as GetAllPostsPayload
		// @ts-ignore
		const postsData = yield call(getPosts, type, relatedId, skip, limit)
		yield put({
			type: PostsActions.GET_ALL_POSTS_SUCCESS.toString(),
			payload: postsData,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: PostsActions.GET_ALL_POSTS_FAILED.toString(),
			payload: error,
		})
	}
}

function* ADD_SINGLE_POSTS(action: PostsActionsTypes) {
	const { posts, cover } = action.payload as AddPostsPayload
	console.log('add posts saga', posts, cover)
	try {
		posts.refId = uuidv4()
		if (cover) {
			// @ts-ignore
			const coverImage = yield call(uploadImage, 'post', 'cover', posts.refId!, cover!)
			posts.coverImage = coverImage.file
		}
		const post: PostModel = yield call(addSinglePosts, posts)
		yield put({
			type: PostsActions.ADD_POSTS_SUCCESS.toString(),
			payload: post,
		})
		notification.success({
			message: 'News post added',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: PostsActions.ADD_POSTS_FAILED.toString(),
			payload: error,
		})
	}
}

function* DELETE_POST(action: PostsActionsTypes) {
	const postId = action.payload as string
	try {
		yield call(deletePost, postId)
		const { posts }: { posts: PostModel[] } = yield select(state => state.posts)
		yield put({
			type: PostsActions.DELETE_POST_SUCCESS.toString(),
			payload: posts.filter(post => post.refId !== postId),
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: PostsActions.DELETE_POST_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([
		takeEvery(PostsActions.GET_ALL_POSTS, GET_ALL_POSTS),
		takeEvery(PostsActions.GET_SINGLE_POSTS, GET_SINGLE_POSTS),
		takeLatest(PostsActions.ADD_POSTS, ADD_SINGLE_POSTS),
		takeLatest(PostsActions.DELETE_POST, DELETE_POST),
	])
}
