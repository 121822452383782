import React, { useEffect, useState } from 'react'
import { Badge, Tabs, Tooltip } from 'antd'
import { connect, ConnectedProps } from 'react-redux'
import { handleInvite } from 'store/user/actions'
import style from './style.module.scss'
import {
	deleteAllNotifications,
	readAllNotifications,
	deleteNotification,
	updateNotification,
} from '../../../store/notifications/actions'
import {
	NotificationAction,
	NotificationModel,
	NotificationStatus,
	NotificationType,
} from '../../../models/notification'
import NotificationList from './notification-list'

const { TabPane } = Tabs

interface RootState {
	notifications: {
		notifications: NotificationModel[]
	}
}

const mapStateToProps = ({ notifications }: RootState) => ({
	notifications: notifications.notifications,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

const NotificationsPanel = ({ dispatch, notifications }: PropsFromRedux) => {
	const [events, setEvents] = useState<NotificationModel[]>([])
	const [invites, setInvites] = useState<NotificationModel[]>([])
	const [matches, setMatches] = useState<NotificationModel[]>([])
	const [totalMatchNotifications, setTotalMatchNotifications] = useState(0)
	const [totalInvitesNotifications, setTotalInvitesNotifications] = useState(0)

	useEffect(() => {
		const matches: NotificationModel[] = []
		const invites: NotificationModel[] = []
		notifications.map(notification => {
			if (
				notification.type === NotificationType.MATCH_CHALLENGE ||
				notification.type === NotificationType.CHALLENGE_UPDATED ||
				notification.type === NotificationType.CHALLENGE_COMPLETED ||
				notification.type === NotificationType.MATCH_DONE ||
				notification.type === NotificationType.MATCH_UPDATED ||
				notification.type === NotificationType.MATCH_RESET
			) {
				matches.push(notification)
				if (notification.status === NotificationStatus.NEW) {
					setTotalMatchNotifications(totalMatchNotifications + 1)
				}
			} else if (
				notification.type === NotificationType.TEAM_INVITE ||
				notification.type === NotificationType.TEAM_INVITE_ACCEPT ||
				notification.type === NotificationType.TEAM_INVITE_DECLINE
			) {
				invites.push(notification)
				if (notification.status === NotificationStatus.NEW) {
					setTotalInvitesNotifications(totalInvitesNotifications + 1)
				}
			}
			return null
		})
		setInvites(invites)
		setMatches(matches)
		//
		// const reducer = (accumulator: any, item: any) => {
		// 	if (item.status === 'NEW') {
		// 		return accumulator + 1
		// 	}
		// 	return accumulator
		// }
		// const total = notificationMatches.reduce(reducer, 0)
	}, [notifications])

	const handleNotification = (notification: NotificationModel, action: NotificationAction) => {
		if (notification.type === NotificationType.TEAM_INVITE) {
			const inviteData = {
				refId: notification.relatedId,
				status: action,
			}
			dispatch(handleInvite(inviteData))
		} else if (action === NotificationAction.DELETE) {
			dispatch(deleteNotification(notification.refId))
		} else if (action === NotificationAction.READ) {
			dispatch(updateNotification(notification.refId, NotificationStatus.READ))
		}
	}

	const deleteAll = () => {
		dispatch(deleteAllNotifications())
		setTotalMatchNotifications(0)
		setTotalInvitesNotifications(0)
	}

	const readAll = () => {
		dispatch(readAllNotifications())
		setTotalMatchNotifications(0)
		setTotalInvitesNotifications(0)
	}

	const renderBadge = (notifications: NotificationModel[]) => {
		const newNotifications = notifications.filter(
			(notification: NotificationModel) => notification.status !== NotificationStatus.READ,
		)
		return newNotifications.length
	}

	const generalActions = (
		<div className="mr-3">
			<Tooltip placement="top" title="Mark all as read" className="mr-2">
				<a className="btn btn-sm btn-light" onClick={readAll}>
					<i className="fe fe-check-square" />
				</a>
			</Tooltip>
			<Tooltip placement="top" title="Delete all notifications">
				<a className="btn btn-sm btn-light" onClick={deleteAll}>
					<i className="fe fe-trash-2" />
				</a>
			</Tooltip>
		</div>
	)

	return (
		<div className={`${style.notificationsPanel} notifications-panel`}>
			<Tabs className={`${style.tabs} kit-tabs-bordered`} defaultActiveKey="1" tabBarExtraContent={generalActions}>
				{/*<TabPane tab="Alerts" key="1">*/}
				{/*  <div className="text-center mb-3 py-4 bg-light rounded">No Actions</div>*/}
				{/*</TabPane>*/}
				<TabPane
					className={`p-0 ${events?.length < 1 ? 'overflow-hidden' : ''}`}
					key="1"
					tab={
						<Badge size="small" count={0} className={`${style.badge}`} style={{ right: `-10px` }}>
							<span>Events</span>
						</Badge>
					}>
					<div className="text-center py-4 rounded">No Events</div>
				</TabPane>
				<TabPane
					className={`p-0 ${invites.length < 1 ? 'overflow-hidden' : ''}`}
					key="2"
					tab={
						<Badge size="small" count={renderBadge(invites)} className={`${style.badge}`} style={{ right: `-10px` }}>
							<span>Invites</span>
						</Badge>
					}>
					{invites.length < 1 ? (
						<div className="text-center py-4 rounded">No Invitations</div>
					) : (
						<NotificationList notifications={invites} handleNotification={handleNotification} />
					)}
				</TabPane>
				<TabPane
					key="3"
					className={`p-0 ${matches?.length < 1 ? 'overflow-hidden' : ''}`}
					tab={
						<Badge size="small" count={totalMatchNotifications} className={`${style.badge}`} style={{ right: `-10px` }}>
							<span>Matches</span>
						</Badge>
					}>
					{matches?.length < 1 ? (
						<div className="text-center py-4 rounded">No Matches</div>
					) : (
						<NotificationList notifications={matches} handleNotification={handleNotification} />
					)}
				</TabPane>
			</Tabs>
		</div>
	)
}

export default connect(mapStateToProps)(NotificationsPanel)
