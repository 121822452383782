import actionCreatorFactory from 'typescript-fsa'
import { RcFile } from 'antd/es/upload'
import { TeamModel } from '../../models/team'
import { TeamInviteModel } from '../../models/team-invite'
import { CountryType } from '../../components/uikit/flag/flags'

const actionCreator = actionCreatorFactory()

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface ChangeRolePayload {
	profileObjectId: string
	profileRefId: string
	newRole: string
}

export interface CreateTeamModel {
	refId?: string
	name: string
	tag: string
	country: CountryType
	recruiting: boolean
	nickname: string
	players: any[]
	website?: string
	logo?: string
	banner?: string
}

export interface CreateTeamPayload {
	teamData: CreateTeamModel
	logo?: RcFile
	banner?: RcFile
}

export interface EditTeamModel {
	name: string
	tag: string
	country: CountryType
	recruiting?: boolean
	nickname?: string
	website?: string
	logo?: string
	banner?: string
	refId: string
}

export interface EditTeamPayload {
	teamData: EditTeamModel
	logo?: RcFile
	banner?: RcFile
}

export interface TeamPayload {
	refId: string
	name: string
	tag: string
	country: CountryType
	recruiting: boolean
	banner?: string
	logo?: string
	website?: string
	status?: string // false is default value
}

export enum ActionsTypes {
	GET_TEAM = 'GET_TEAM',
	GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS',
	GET_TEAM_FAILED = 'GET_TEAM_FAILED',
	CREATE_TEAM = 'CREATE_TEAM',
	CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS',
	CREATE_TEAM_FAILED = 'CREATE_TEAM_FAILED',
	EDIT_TEAM = 'EDIT_TEAM',
	EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS',
	EDIT_TEAM_FAILED = 'EDIT_TEAM_FAILED',
	DELETE_TEAM = 'DELETE_TEAM',
	DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS',
	DELETE_TEAM_FAILED = 'DELETE_TEAM_FAILED',
	GET_USER_TEAMS = 'GET_USER_TEAMS',
	GET_USER_TEAMS_SUCCESS = 'GET_USER_TEAMS_SUCCESS',
	GET_USER_TEAMS_FAILED = 'GET_USER_TEAMS_FAILED',
	INVITE_PLAYERS = 'INVITE_PLAYERS',
	INVITE_PLAYERS_SUCCESS = 'INVITE_PLAYERS_SUCCESS',
	INVITE_PLAYERS_FAILED = 'INVITE_PLAYERS_FAILED',
	CHANGE_ROLE = 'CHANGE_ROLE',
	CHANGE_ROLE_SUCCESS = 'CHANGE_ROLE_SUCCESS',
	CHANGE_ROLE_FAILED = 'CHANGE_ROLE_FAILED',
	KICK_PLAYER = 'KICK_PLAYER',
	KICK_PLAYER_SUCCESS = 'KICK_PLAYER_SUCCESS',
	KICK_PLAYER_FAILED = 'KICK_PLAYER_FAILED',
}
export const TeamsActions = {
	GET_TEAM: actionCreator<string>(ActionsTypes.GET_TEAM),
	GET_TEAM_SUCCESS: actionCreator<TeamModel>(ActionsTypes.GET_TEAM_SUCCESS),
	GET_TEAM_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_TEAM_FAILED),
	CREATE_TEAM: actionCreator<CreateTeamPayload>(ActionsTypes.CREATE_TEAM),
	CREATE_TEAM_SUCCESS: actionCreator<TeamModel>(ActionsTypes.CREATE_TEAM_SUCCESS),
	CREATE_TEAM_FAILED: actionCreator<ErrorPayload>(ActionsTypes.CREATE_TEAM_FAILED),
	EDIT_TEAM: actionCreator<EditTeamPayload>(ActionsTypes.EDIT_TEAM),
	EDIT_TEAM_SUCCESS: actionCreator<TeamModel>(ActionsTypes.EDIT_TEAM_SUCCESS),
	EDIT_TEAM_FAILED: actionCreator<ErrorPayload>(ActionsTypes.EDIT_TEAM_FAILED),
	DELETE_TEAM: actionCreator<TeamModel>(ActionsTypes.DELETE_TEAM),
	DELETE_TEAM_SUCCESS: actionCreator(ActionsTypes.DELETE_TEAM_SUCCESS),
	DELETE_TEAM_FAILED: actionCreator<ErrorPayload>(ActionsTypes.DELETE_TEAM_FAILED),
	GET_USER_TEAMS: actionCreator(ActionsTypes.GET_USER_TEAMS),
	GET_USER_TEAMS_SUCCESS: actionCreator<TeamModel[]>(ActionsTypes.GET_USER_TEAMS_SUCCESS),
	GET_USER_TEAMS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_USER_TEAMS_FAILED),
	INVITE_PLAYERS: actionCreator<TeamInviteModel>(ActionsTypes.INVITE_PLAYERS),
	INVITE_PLAYERS_SUCCESS: actionCreator(ActionsTypes.INVITE_PLAYERS_SUCCESS),
	INVITE_PLAYERS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.INVITE_PLAYERS_FAILED),
	CHANGE_ROLE: actionCreator<ChangeRolePayload>(ActionsTypes.CHANGE_ROLE),
	CHANGE_ROLE_SUCCESS: actionCreator(ActionsTypes.CHANGE_ROLE_SUCCESS),
	CHANGE_ROLE_FAILED: actionCreator<ErrorPayload>(ActionsTypes.CHANGE_ROLE_FAILED),
	KICK_PLAYER: actionCreator<string>(ActionsTypes.KICK_PLAYER),
	KICK_PLAYER_SUCCESS: actionCreator(ActionsTypes.KICK_PLAYER_SUCCESS),
	KICK_PLAYER_FAILED: actionCreator<ErrorPayload>(ActionsTypes.KICK_PLAYER_FAILED),
}

export type TeamsActionsTypes =
	| ReturnType<typeof TeamsActions.GET_TEAM>
	| ReturnType<typeof TeamsActions.GET_TEAM_SUCCESS>
	| ReturnType<typeof TeamsActions.GET_TEAM_FAILED>
	| ReturnType<typeof TeamsActions.CREATE_TEAM>
	| ReturnType<typeof TeamsActions.CREATE_TEAM_SUCCESS>
	| ReturnType<typeof TeamsActions.CREATE_TEAM_FAILED>
	| ReturnType<typeof TeamsActions.EDIT_TEAM>
	| ReturnType<typeof TeamsActions.EDIT_TEAM_SUCCESS>
	| ReturnType<typeof TeamsActions.EDIT_TEAM_FAILED>
	| ReturnType<typeof TeamsActions.GET_USER_TEAMS>
	| ReturnType<typeof TeamsActions.GET_USER_TEAMS_SUCCESS>
	| ReturnType<typeof TeamsActions.GET_USER_TEAMS_FAILED>
	| ReturnType<typeof TeamsActions.INVITE_PLAYERS>
	| ReturnType<typeof TeamsActions.INVITE_PLAYERS_SUCCESS>
	| ReturnType<typeof TeamsActions.INVITE_PLAYERS_FAILED>
	| ReturnType<typeof TeamsActions.CHANGE_ROLE>
	| ReturnType<typeof TeamsActions.CHANGE_ROLE_SUCCESS>
	| ReturnType<typeof TeamsActions.CHANGE_ROLE_FAILED>
	| ReturnType<typeof TeamsActions.KICK_PLAYER>
	| ReturnType<typeof TeamsActions.KICK_PLAYER_SUCCESS>
	| ReturnType<typeof TeamsActions.KICK_PLAYER_FAILED>
	| ReturnType<typeof TeamsActions.DELETE_TEAM>
	| ReturnType<typeof TeamsActions.DELETE_TEAM_SUCCESS>
	| ReturnType<typeof TeamsActions.DELETE_TEAM_FAILED>
