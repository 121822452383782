import actionCreatorFactory from 'typescript-fsa'
import { NotificationModel } from '../../models/notification'

const actionCreator = actionCreatorFactory()

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface UpdateNotificationPayload {
	refId: string
	status: string
}

export enum ActionsTypes {
	SUBSCRIBE_TO_ALL_NOTIFICATIONS = 'SUBSCRIBE_TO_ALL_NOTIFICATIONS',
	DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
	DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS',
	DELETE_NOTIFICATION_FAILED = 'DELETE_NOTIFICATION_FAILED',
	GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
	GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
	GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED',
	NEW_SUBSCRIBED_NOTIFICATION = 'NEW_SUBSCRIBED_NOTIFICATION',
	NEW_CHAT_MESSAGE_NOTIFICATION = 'NEW_CHAT_MESSAGE_NOTIFICATION',
	UPDATE_NOTIFICATIONS_SUCCESS = 'UPDATE_NOTIFICATIONS_SUCCESS',
	UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION',
	UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS',
	UPDATE_NOTIFICATION_FAILED = 'UPDATE_NOTIFICATION_FAILED',
	DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS',
	DELETE_ALL_NOTIFICATIONS_SUCCESS = 'DELETE_ALL_NOTIFICATIONS_SUCCESS',
	DELETE_ALL_NOTIFICATIONS_FAILED = 'DELETE_ALL_NOTIFICATIONS_FAILED',
	READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS',
	READ_ALL_NOTIFICATIONS_SUCCESS = 'READ_ALL_NOTIFICATIONS_SUCCESS',
	READ_ALL_NOTIFICATIONS_FAILED = 'READ_ALL_NOTIFICATIONS_FAILED',
}
export const NotificationsActions = {
	SUBSCRIBE_TO_ALL_NOTIFICATIONS: actionCreator(ActionsTypes.SUBSCRIBE_TO_ALL_NOTIFICATIONS),
	DELETE_NOTIFICATION: actionCreator<string>(ActionsTypes.DELETE_NOTIFICATION),
	DELETE_NOTIFICATION_SUCCESS: actionCreator<NotificationModel[]>(ActionsTypes.DELETE_NOTIFICATION_SUCCESS),
	DELETE_NOTIFICATION_FAILED: actionCreator<ErrorPayload>(ActionsTypes.DELETE_NOTIFICATION_FAILED),
	GET_NOTIFICATIONS: actionCreator(ActionsTypes.GET_NOTIFICATIONS),
	GET_NOTIFICATIONS_SUCCESS: actionCreator<NotificationModel[]>(ActionsTypes.GET_NOTIFICATIONS_SUCCESS),
	UPDATE_NOTIFICATIONS_SUCCESS: actionCreator<NotificationModel[]>(ActionsTypes.UPDATE_NOTIFICATIONS_SUCCESS),
	UPDATE_NOTIFICATION: actionCreator<UpdateNotificationPayload>(ActionsTypes.UPDATE_NOTIFICATION),
	UPDATE_NOTIFICATION_SUCCESS: actionCreator<NotificationModel[]>(ActionsTypes.UPDATE_NOTIFICATION_SUCCESS),
	UPDATE_NOTIFICATION_FAILED: actionCreator<ErrorPayload>(ActionsTypes.UPDATE_NOTIFICATION_FAILED),
	GET_NOTIFICATIONS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_NOTIFICATIONS_FAILED),
	NEW_SUBSCRIBED_NOTIFICATION: actionCreator<NotificationModel>(ActionsTypes.NEW_SUBSCRIBED_NOTIFICATION),
	NEW_CHAT_MESSAGE_NOTIFICATION: actionCreator<NotificationModel>(ActionsTypes.NEW_CHAT_MESSAGE_NOTIFICATION),
	DELETE_ALL_NOTIFICATIONS: actionCreator(ActionsTypes.DELETE_ALL_NOTIFICATIONS),
	DELETE_ALL_NOTIFICATIONS_SUCCESS: actionCreator<NotificationModel[]>(ActionsTypes.DELETE_ALL_NOTIFICATIONS_SUCCESS),
	DELETE_ALL_NOTIFICATIONS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.DELETE_ALL_NOTIFICATIONS_FAILED),
	READ_ALL_NOTIFICATIONS: actionCreator(ActionsTypes.READ_ALL_NOTIFICATIONS),
	READ_ALL_NOTIFICATIONS_SUCCESS: actionCreator<NotificationModel[]>(ActionsTypes.READ_ALL_NOTIFICATIONS_SUCCESS),
	READ_ALL_NOTIFICATIONS_FAILED: actionCreator<ErrorPayload>(ActionsTypes.READ_ALL_NOTIFICATIONS_FAILED),
}

export type NotificationsActionsTypes =
	| ReturnType<typeof NotificationsActions.GET_NOTIFICATIONS>
	| ReturnType<typeof NotificationsActions.GET_NOTIFICATIONS_SUCCESS>
	| ReturnType<typeof NotificationsActions.GET_NOTIFICATIONS_FAILED>
	| ReturnType<typeof NotificationsActions.NEW_SUBSCRIBED_NOTIFICATION>
	| ReturnType<typeof NotificationsActions.DELETE_NOTIFICATION>
	| ReturnType<typeof NotificationsActions.DELETE_NOTIFICATION_SUCCESS>
	| ReturnType<typeof NotificationsActions.DELETE_NOTIFICATION_FAILED>
	| ReturnType<typeof NotificationsActions.NEW_CHAT_MESSAGE_NOTIFICATION>
	| ReturnType<typeof NotificationsActions.UPDATE_NOTIFICATIONS_SUCCESS>
	| ReturnType<typeof NotificationsActions.SUBSCRIBE_TO_ALL_NOTIFICATIONS>
	| ReturnType<typeof NotificationsActions.UPDATE_NOTIFICATION>
	| ReturnType<typeof NotificationsActions.UPDATE_NOTIFICATION_SUCCESS>
	| ReturnType<typeof NotificationsActions.UPDATE_NOTIFICATION_FAILED>
	| ReturnType<typeof NotificationsActions.DELETE_ALL_NOTIFICATIONS>
	| ReturnType<typeof NotificationsActions.DELETE_ALL_NOTIFICATIONS_SUCCESS>
	| ReturnType<typeof NotificationsActions.DELETE_ALL_NOTIFICATIONS_FAILED>
	| ReturnType<typeof NotificationsActions.READ_ALL_NOTIFICATIONS>
	| ReturnType<typeof NotificationsActions.READ_ALL_NOTIFICATIONS_SUCCESS>
	| ReturnType<typeof NotificationsActions.READ_ALL_NOTIFICATIONS_FAILED>
