import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { GuidsPayload, HelpersActions } from './types'

export interface HelpersState {
	guids?: GuidsPayload[]
}
const helpersInitialState: HelpersState = {
	guids: [],
}

const helpersReducer = reducerWithInitialState(helpersInitialState)
	// LOADING CASES
	.case(HelpersActions.GET_GUIDS, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(HelpersActions.GET_GUIDS_SUCCESS, (state, guids) => ({
		...state,
		guids,
		loading: false,
	}))

	// FAILED CASES
	.case(HelpersActions.GET_GUIDS_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default helpersReducer
