import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { ChallengeModel } from 'models/challenge'
import { ChallengesActions } from './types'

export interface ChallengesState {
	challenges: ChallengeModel[]
	competitionChallenges: ChallengeModel[]
	currentChallenge: ChallengeModel | null
	loading: boolean
	isChallenging: boolean
	error: unknown | null
}

const challengesInitialState: ChallengesState = {
	challenges: [],
	competitionChallenges: [],
	currentChallenge: null,
	loading: false,
	isChallenging: false,
	error: null,
}

const ChallengesReducer = reducerWithInitialState(challengesInitialState)
	// LOADING CASES
	.case(ChallengesActions.CHALLENGE, state => ({ ...state, loading: true }))
	.case(ChallengesActions.GET_CHALLENGES, state => ({ ...state, loading: true }))
	.case(ChallengesActions.GET_CHALLENGE, state => ({ ...state, loading: true }))
	.case(ChallengesActions.HANDLE_CHALLENGE, state => ({ ...state, loading: true }))
	.case(ChallengesActions.FORCE_CHALLENGE, state => ({ ...state, isChallenging: true }))
	.case(ChallengesActions.CANCEL_CHALLENGE, state => ({ ...state, loading: true }))
	.case(ChallengesActions.GET_COMPETITION_CHALLENGES, state => ({ ...state, loading: true }))

	// SUCCESS CASES
	.case(ChallengesActions.GET_CHALLENGES_SUCCESS, (state, challenges) => ({
		...state,
		challenges,
		loading: false,
	}))
	.case(ChallengesActions.FORCE_CHALLENGE_SUCCESS, (state, challenges) => ({
		...state,
		isChallenging: false,
	}))
	.case(ChallengesActions.GET_COMPETITION_CHALLENGES_SUCCESS, (state, competitionChallenges) => ({
		...state,
		competitionChallenges,
		loading: false,
	}))
	.case(ChallengesActions.GET_CHALLENGE_SUCCESS, (state, currentChallenge) => ({
		...state,
		currentChallenge,
		loading: false,
	}))
	// FAILED CASES
	.case(ChallengesActions.CHALLENGE_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(ChallengesActions.FORCE_CHALLENGE_FAILED, (state, error) => ({ ...state, error, isChallenging: false }))
	.case(ChallengesActions.GET_CHALLENGES_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(ChallengesActions.GET_CHALLENGE_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(ChallengesActions.HANDLE_CHALLENGE, (state, error) => ({ ...state, error, loading: false }))
	.case(ChallengesActions.CANCEL_CHALLENGE, (state, error) => ({ ...state, error, loading: false }))
	.case(ChallengesActions.GET_COMPETITION_CHALLENGES_FAILED, (state, error) => ({ ...state, error, loading: false }))

	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default ChallengesReducer
