import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Badge } from 'antd'
import ProfilePicker from '../../../uikit/profile-picker'
import FavPages from './FavPages'
import Search from './Search'
import LanguageSwitcher from './LanguageSwitcher'
import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'
import styles from './Actions/style.module.scss'
import { NotificationType, NotificationStatus } from '../../../../models/notification'

const TopBar = ({ isUserAuthorized, user, notifications }) => {
	const history = useHistory()
	const [count, setCount] = useState(0)

	const reducer = (accumulator, item) => {
		console.log('#reducer', item, 'reducer#')
		if (item.type === NotificationType.NEW_MESSAGE && item.status === NotificationStatus.NEW) {
			return accumulator + 1
		}
		return accumulator
	}

	useEffect(() => {
		const total = notifications.reduce(reducer, 0)
		setCount(total)
	}, [notifications])
	return (
		<div className={style.topbar}>
			<div className="mr-4">
				<FavPages />
			</div>
			<div className="mr-auto">
				<Search />
			</div>
			<div className="mr-4 d-md-block" />
			{isUserAuthorized && user.activeProfile && <ProfilePicker />}
			{/*{isUserAuthorized && (*/}
			{/*  <div className="mb-0 d-xl-block justify-content-end">*/}
			{/*    <button type="button" className="ant-btn mr-3 mb-3" onClick={() => history.push('/create-team')}>*/}
			{/*      <span>New team</span>*/}
			{/*    </button>*/}
			{/*  </div>*/}
			{/*)}*/}
			{/*<div className="mr-4 d-none d-sm-block">*/}
			{/*  <LanguageSwitcher />*/}
			{/*</div>*/}
			<div className="mr-4 d-none d-sm-block">{isUserAuthorized && <Actions />}</div>
			<div className="mr-4 d-none d-sm-block">
				{isUserAuthorized && (
					<Link to="/chat" className="kit__utils__link">
						<Badge count={count} className={`${styles.badge}`}>
							<i className={`${styles.icon} fa fa-envelope ${count > 0 ? 'tada' : ''}`} />
						</Badge>
					</Link>
				)}
			</div>
			<div className="">
				{isUserAuthorized ? (
					<UserMenu />
				) : (
					<>
						<Link to="/auth/login" className="font-size-16 kit__utils__link text-gray-1 mr-3">
							LOGIN
						</Link>
						<Link to="/auth/register" className="font-size-16 kit__utils__link text-primary">
							REGISTER
						</Link>
					</>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = ({ user, notifications }) => ({ user, notifications: notifications.notifications })

export default connect(mapStateToProps)(TopBar)
