import { all, call, put, takeEvery } from 'redux-saga/effects'
import { notification } from 'antd'
import { PlayersActions, PlayersActionsTypes } from './types'
import { UserModel } from '../../models/user'
import { getAllUsers } from '../../services/user'

function* GET_PLAYERS() {
	try {
		const players: UserModel[] = yield call(getAllUsers)
		yield put({
			type: PlayersActions.GET_PLAYERS_SUCCESS.toString(),
			payload: players,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: PlayersActions.GET_PLAYERS_FAILED.toString(),
			payload: error.message,
		})
	}
}

export default function* rootSaga() {
	yield all([takeEvery(PlayersActions.GET_PLAYERS, GET_PLAYERS)])
}
