import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { PollsActions, PollsActionsTypes, VotePayload } from './types'
import { PollModel } from '../../models/polls'
import { notification } from 'antd'
import { getPoll, votePoll } from '../../services/polls'

function* GET_POLL(action: PollsActionsTypes) {
	const pollId = action.payload as string
	try {
		const singlePoll: PollModel = yield call(getPoll, pollId)
		yield put({
			type: PollsActions.GET_POLL_SUCCESS.toString(),
			payload: singlePoll,
		})
	} catch (error) {
		yield put({
			type: PollsActions.GET_POLL_FAILED.toString(),
			payload: error,
		})
	}
}

function* VOTE_POLL(action: PollsActionsTypes) {
	const vote = action.payload as VotePayload
	try {
		const poll: PollModel = yield call(votePoll, vote)
		yield put({
			type: PollsActions.VOTE_POLL_SUCCESS.toString(),
			payload: poll,
		})
		notification.success({
			message: 'Vote added',
		})
	} catch (error) {
		yield put({
			type: PollsActions.VOTE_POLL_FAILED.toString(),
			payload: error,
		})
	}
}

export default function* rootSaga() {
	yield all([takeEvery(PollsActions.GET_POLL, GET_POLL), takeLatest(PollsActions.VOTE_POLL, VOTE_POLL)])
}
