import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PlayersActions } from './types'
import { UserModel } from '../../models/user'

export interface PlayersState {
	players: UserModel[]
	loading: boolean
	error: unknown | null
}
const initialState: PlayersState = {
	players: [],
	loading: false,
	error: null,
}

const playersReducer = reducerWithInitialState(initialState)
	// LOADING CASES
	.case(PlayersActions.GET_PLAYERS, state => ({ ...initialState, loading: true }))

	// SUCCESS CASES
	.case(PlayersActions.GET_PLAYERS_SUCCESS, (state, players) => ({
		...state,
		players,
		loading: false,
	}))

	// FAILED CASES
	.case(PlayersActions.GET_PLAYERS_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default playersReducer
