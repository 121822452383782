import actionCreatorFactory from 'typescript-fsa'
import { LadderModel } from '../../models'
import { MatchModel } from '../../models/match'
import { RawDraftContentState } from 'draft-js'
import { ErrorPayload } from '../matches/types'

const actionCreator = actionCreatorFactory()

export interface RefIdPayload {
	refId: string
}

export interface JoinLadderPayload {
	ladderId: string
	teamId: string
}

export interface UpdateLadderRulesPayload {
	ladder: LadderModel
	rules: RawDraftContentState
}

export enum ActionsTypes {
	GET_LADDER = 'GET_LADDER',
	GET_LADDER_SUCCESS = 'GET_LADDER_SUCCESS',
	GET_LADDER_FAILED = 'GET_LADDER_FAILED',
	ADD_LADDER = 'ADD_LADDER',
	ADD_LADDER_SUCCESS = 'ADD_LADDER_SUCCESS',
	ADD_LADDER_FAILED = 'ADD_LADDER_FAILED',
	JOIN_LADDER = 'JOIN_LADDER',
	JOIN_LADDER_SUCCESS = 'JOIN_LADDER_SUCCESS',
	JOIN_LADDER_FAILED = 'JOIN_LADDER_FAILED',
	LEAVE_LADDER = 'LEAVE_LADDER',
	LEAVE_LADDER_SUCCESS = 'LEAVE_LADDER_SUCCESS',
	LEAVE_LADDER_FAILED = 'LEAVE_LADDER_FAILED',
	UPDATE_LADDER = 'UPDATE_LADDER',
	UPDATE_LADDER_SUCCESS = 'UPDATE_LADDER_SUCCESS',
	UPDATE_LADDER_FAILED = 'UPDATE_LADDER_FAILED',
	GET_UPCOMING_LADDER_MATCHES = 'GET_UPCOMING_LADDER_MATCHES',
	GET_UPCOMING_LADDER_MATCHES_SUCCESS = 'GET_UPCOMING_LADDER_MATCHES_SUCCESS',
	GET_UPCOMING_LADDER_MATCHES_FAILED = 'GET_UPCOMING_LADDER_MATCHES_FAILED',
	GET_LATEST_LADDER_MATCHES = 'GET_LATEST_LADDER_MATCHES',
	GET_LATEST_LADDER_MATCHES_SUCCESS = 'GET_LATEST_LADDER_MATCHES_SUCCESS',
	GET_LATEST_LADDER_MATCHES_FAILED = 'GET_LATEST_LADDER_MATCHES_FAILED',
	GET_UPCOMING_LATEST_LADDER_MATCHES = 'GET_UPCOMING_LATEST_LADDER_MATCHES',
	GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS = 'GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS',
	GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED = 'GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED',
}

export const LaddersActions = {
	GET_LADDER: actionCreator<RefIdPayload>(ActionsTypes.GET_LADDER),
	GET_LADDER_SUCCESS: actionCreator<LadderModel>(ActionsTypes.GET_LADDER_SUCCESS),
	GET_LADDER_FAILED: actionCreator(ActionsTypes.GET_LADDER_FAILED),
	ADD_LADDER: actionCreator(ActionsTypes.ADD_LADDER),
	ADD_LADDER_SUCCESS: actionCreator(ActionsTypes.ADD_LADDER_SUCCESS),
	ADD_LADDER_FAILED: actionCreator(ActionsTypes.ADD_LADDER_FAILED),
	JOIN_LADDER: actionCreator<JoinLadderPayload>(ActionsTypes.JOIN_LADDER),
	JOIN_LADDER_SUCCESS: actionCreator(ActionsTypes.JOIN_LADDER_SUCCESS),
	JOIN_LADDER_FAILED: actionCreator(ActionsTypes.JOIN_LADDER_FAILED),
	LEAVE_LADDER: actionCreator<string>(ActionsTypes.LEAVE_LADDER),
	LEAVE_LADDER_SUCCESS: actionCreator(ActionsTypes.LEAVE_LADDER_SUCCESS),
	LEAVE_LADDER_FAILED: actionCreator<ErrorPayload>(ActionsTypes.LEAVE_LADDER_FAILED),
	UPDATE_LADDER: actionCreator<UpdateLadderRulesPayload>(ActionsTypes.UPDATE_LADDER),
	UPDATE_LADDER_SUCCESS: actionCreator<LadderModel>(ActionsTypes.UPDATE_LADDER_SUCCESS),
	UPDATE_LADDER_FAILED: actionCreator(ActionsTypes.UPDATE_LADDER_FAILED),
	GET_UPCOMING_LADDER_MATCHES: actionCreator<RefIdPayload>(ActionsTypes.GET_UPCOMING_LADDER_MATCHES),
	GET_UPCOMING_LADDER_MATCHES_SUCCESS: actionCreator(ActionsTypes.GET_UPCOMING_LADDER_MATCHES_SUCCESS),
	GET_UPCOMING_LADDER_MATCHES_FAILED: actionCreator(ActionsTypes.GET_UPCOMING_LADDER_MATCHES_FAILED),
	GET_LATEST_LADDER_MATCHES: actionCreator<RefIdPayload>(ActionsTypes.GET_LATEST_LADDER_MATCHES),
	GET_LATEST_LADDER_MATCHES_SUCCESS: actionCreator(ActionsTypes.GET_LATEST_LADDER_MATCHES_SUCCESS),
	GET_LATEST_LADDER_MATCHES_FAILED: actionCreator(ActionsTypes.GET_LATEST_LADDER_MATCHES_FAILED),
	GET_UPCOMING_LATEST_LADDER_MATCHES: actionCreator<RefIdPayload>(ActionsTypes.GET_UPCOMING_LATEST_LADDER_MATCHES),
	GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS: actionCreator<{ upcoming: MatchModel[]; latest: MatchModel[] }>(
		ActionsTypes.GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS,
	),
	GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED: actionCreator(ActionsTypes.GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED),
}

export type LaddersActionsTypes =
	| ReturnType<typeof LaddersActions.GET_LADDER>
	| ReturnType<typeof LaddersActions.GET_LADDER_SUCCESS>
	| ReturnType<typeof LaddersActions.GET_LADDER_FAILED>
	| ReturnType<typeof LaddersActions.ADD_LADDER>
	| ReturnType<typeof LaddersActions.ADD_LADDER_SUCCESS>
	| ReturnType<typeof LaddersActions.ADD_LADDER_FAILED>
	| ReturnType<typeof LaddersActions.JOIN_LADDER>
	| ReturnType<typeof LaddersActions.JOIN_LADDER_SUCCESS>
	| ReturnType<typeof LaddersActions.JOIN_LADDER_FAILED>
	| ReturnType<typeof LaddersActions.UPDATE_LADDER>
	| ReturnType<typeof LaddersActions.UPDATE_LADDER_SUCCESS>
	| ReturnType<typeof LaddersActions.UPDATE_LADDER_FAILED>
	| ReturnType<typeof LaddersActions.GET_UPCOMING_LADDER_MATCHES>
	| ReturnType<typeof LaddersActions.GET_UPCOMING_LADDER_MATCHES_SUCCESS>
	| ReturnType<typeof LaddersActions.GET_UPCOMING_LADDER_MATCHES_FAILED>
	| ReturnType<typeof LaddersActions.GET_LATEST_LADDER_MATCHES>
	| ReturnType<typeof LaddersActions.GET_LATEST_LADDER_MATCHES_SUCCESS>
	| ReturnType<typeof LaddersActions.GET_LATEST_LADDER_MATCHES_FAILED>
	| ReturnType<typeof LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES>
	| ReturnType<typeof LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES_SUCCESS>
	| ReturnType<typeof LaddersActions.GET_UPCOMING_LATEST_LADDER_MATCHES_FAILED>
	| ReturnType<typeof LaddersActions.LEAVE_LADDER>
	| ReturnType<typeof LaddersActions.LEAVE_LADDER_SUCCESS>
	| ReturnType<typeof LaddersActions.LEAVE_LADDER_FAILED>
