import React, { useState, useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Checkbox, Button } from 'antd'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { PlayerModel } from 'models/player'
import useDebounce from 'utils/hooks/debouce'
import { searchUser, searchTeam } from 'store/search/actions'
import { Link, useHistory } from 'react-router-dom'
import style from './style.module.scss'
import { TeamModel } from '../../../../../models/team'
import Flag from '../../../../uikit/flag/flag'

let searchInput: { focus: () => void; blur: () => void } | null = null

interface RootState {
	search: {
		userSearchResults: PlayerModel[]
		previousUserSearchResults: PlayerModel[]
		teamSearchResults: TeamModel[]
		loading: false
	}
}

const mapStateToProps = ({ search }: RootState) => ({
	userSearchResults: search.userSearchResults,
	previousUserSearchResults: search.previousUserSearchResults,
	teamSearchResults: search.teamSearchResults.filter(t => !t.single),
	loading: search.loading,
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface PropTypes extends PropsFromRedux {
	intl: any
}
const Search = ({ intl: { formatMessage }, userSearchResults, teamSearchResults, loading }: PropTypes) => {
	const [showSearch, setShowSearch] = useState<boolean>(false)
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown, false)
		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	})

	const [searchText, setSearchText, { signal }] = useDebounce('', 1000)

	useEffect(() => {}, [userSearchResults])

	useEffect(() => {}, [teamSearchResults])

	useEffect(() => {
		if (searchText !== '' && searchText.length > 2) {
			dispatch(searchUser(searchText))
			dispatch(searchTeam(searchText))
		}
	}, [signal])

	const showLiveSearch = () => {
		setShowSearch(true)
		setTimeout(() => {
			if (searchInput) searchInput.focus()
		}, 100)
	}

	const changeSearchText = (e: { target: { value: React.SetStateAction<string> } }) => {
		setSearchText(e.target.value)
	}

	const hideLiveSearch = () => {
		if (searchInput) searchInput.blur()
		setShowSearch(false)
		setSearchText('')
	}

	const handleKeyDown = (event: { keyCode: { toString: () => any } }) => {
		if (showSearch) {
			const key = event.keyCode.toString()
			if (key === '27') {
				hideLiveSearch()
			}
		}
	}

	const handleNode = (node: { focus: () => void; blur: () => void } | null) => {
		searchInput = node
	}

	const navigateToUser = (uid: string) => {
		hideLiveSearch()
		history.push(`/user/${uid}`)
	}

	const navigateToTeam = (tid: string) => {
		hideLiveSearch()
		history.push(`/team/${tid}`)
	}

	const renderUserCard = () => {
		if (userSearchResults?.length > 0) {
			return userSearchResults.map(result => (
				<div key={result.refId} className={`${style.item} col-6 pl-0`}>
					<a className={style.itemLink} onClick={() => navigateToUser(result.refId)}>
						<div className={style.resultThumb} style={{ backgroundImage: `url(${result.avatar})` }} />
						<div className="ml-3">
							<div className={style.resultText}>{result.username}</div>
							<div className={style.resultSource}>
								<Flag size={16} country={result.country} />
								<div className="ml-2">{result.country}</div>
							</div>
						</div>
						<div className={style.itemAction}>
							<span />
						</div>
					</a>
				</div>
			))
		}
		return <div>No users found</div>
	}

	const renderTeamCard = () => {
		if (teamSearchResults?.length > 0) {
			return teamSearchResults.map(result => (
				<div key={result.refId} className={`${style.item} col-6 pl-0`}>
					<a className={style.itemLink} onClick={() => navigateToTeam(result.refId)}>
						<div className={style.resultThumb} style={{ backgroundImage: `url(${result.logo})` }} />
						<div className="ml-3">
							<div className={style.resultText}>{result.name}</div>
							<div className={style.resultSource}>
								<Flag size={16} country={result.country} />
								<div className="ml-2">{result.country}</div>
							</div>
						</div>
						<div className={style.itemAction}>
							<span />
						</div>
					</a>
				</div>
			))
		}
		return <div>No teams found</div>
	}

	// const renderNoResults = () => {
	//   return (
	//     <div className={style.results}>
	//       <div className={style.resultsTitle}>
	//         <span>No Results Found</span>
	//       </div>
	//     </div>
	//   )
	// }

	// @ts-ignore
	return (
		<div className="d-flex mr-4">
			<Input
				className={style.extInput}
				placeholder={formatMessage({ id: 'topBar.typeToSearch' })}
				prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
				style={{ width: 200 }}
				onFocus={showLiveSearch}
			/>
			<div
				className={`${showSearch ? `${style.livesearch} ${style.livesearchVisible}` : style.livesearch}`}
				id="livesearch">
				<button className={style.close} type="button" onClick={hideLiveSearch}>
					<i className="icmn-cross" />
				</button>
				<div className="container-fluid">
					<div className={style.wrapper}>
						<input
							type="search"
							className={style.searchInput}
							value={searchText}
							onChange={changeSearchText}
							id="livesearchInput"
							placeholder={formatMessage({ id: 'topBar.typeToSearch' })}
							ref={handleNode}
						/>
						{!loading && userSearchResults.length < 1 && teamSearchResults.length < 1 && searchText && (
							<div className={style.results}>
								<div className={style.resultsTitle}>
									<span>No Results Found</span>
								</div>
							</div>
						)}
						{(userSearchResults.length > 0 || teamSearchResults.length > 0) && (
							<div className="row">
								<div className="col-6">
									<div className={style.results}>
										<div className={style.resultsTitle}>
											<h1>
												<i className="fe fe-user mr-3" aria-hidden="true" />
												Players
											</h1>
										</div>
										<div className="col-lg-12">
											<ul className="list-unstyled d-flex row flex-wrap overflow-auto">{renderUserCard()}</ul>
										</div>
									</div>
								</div>
								<div className="col-6">
									<div className={style.results}>
										<div className={style.resultsTitle}>
											<h1>
												<i className="fe fe-users mr-3" aria-hidden="true" />
												Teams
											</h1>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<ul className="list-unstyled d-flex row flex-wrap overflow-auto">{renderTeamCard()}</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default connect(mapStateToProps)(injectIntl(Search))
