import apiClient from 'services/api'
import { MatchReportModel } from '../../models/matchReport'
import { MatchUpdatePayload } from '../../store/matches/types'

export async function getMatch(matchId: string) {
	return apiClient
		.get(`/match/${matchId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No get match response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getTournamentMatches(id: string) {
	return apiClient
		.get(`/matches/competition/${id}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No tournament matches response data')
		})
		.catch(err => {
			throw err
		})
}

export async function getTeamMatches(teamId: string) {
	return apiClient
		.get(`/matches/${teamId}`)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No get challenges response data')
		})
		.catch(err => {
			throw err
		})
}

export async function updateMatch(match: MatchUpdatePayload) {
	return apiClient
		.put(`/match/${match.refId}`, match)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No response data')
		})
		.catch(err => {
			throw err
		})
}

export async function addMatchReport(matchReportModel: MatchReportModel) {
	return apiClient
		.post(`/matchReport`, matchReportModel)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function updateMatchReport(matchReport: MatchReportModel) {
	return apiClient
		.put(`/matchReport/${matchReport.refId}`, matchReport)
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error(response)
		})
		.catch(err => {
			throw err
		})
}

export async function getCompetitionMatches(competitionId: string, skip: number = 0, limit: number = 35) {
	return apiClient
		.get(`/matches/competition/${competitionId}`, {
			params: {
				skip,
				limit,
			},
		})
		.then(response => {
			if (response) {
				return response.data
			}
			throw new Error('No get competition matches response data')
		})
		.catch(err => {
			throw err
		})
}
