export enum NotificationType {
	CHALLENGE_COMPLETED = 'CHALLENGE-COMPLETED',
	CHALLENGE_UPDATED = 'CHALLENGE-UPDATED',
	GENERIC = 'GENERIC',
	MATCH_CHALLENGE = 'MATCH-CHALLENGE',
	MATCH_DONE = 'MATCH-DONE',
	MATCH_UPDATED = 'MATCH-UPDATED',
	MATCH_RESET = 'MATCH-RESET',
	NEW_MESSAGE = 'NEW-MESSAGE',
	TEAM_INVITE = 'TEAM-INVITE',
	TEAM_INVITE_ACCEPT = 'TEAM-INVITE-ACCEPT',
	TEAM_INVITE_DECLINE = 'TEAM-INVITE-DECLINE',
}

export enum NotificationStatus {
	NEW = 'NEW',
	READ = 'READ',
}

export interface NotificationModel {
	_id: string
	refId: string
	user: string
	from?: string
	message: string
	status: NotificationStatus
	created: any
	updated?: any
	type: NotificationType
	socketType: string
	relatedId: string
	parentId?: string
}

export enum NotificationAction {
	ACCEPT = 'ACCEPT',
	DECLINE = 'DECLINE',
	DELETE = 'DELETE',
	READ = 'READ',
}
