import { all, call, put, takeEvery, select, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import * as firebase from 'services/firebase'
import { login, register, updateUser, handleInvite, getUser } from 'services/auth'
import { getUserGuids, addUserGuid } from 'services/user'
import { removeEmpty, uploadImage } from 'services/utils'
import { history } from '../index'
import jwt, { JwtPayload } from 'jsonwebtoken'
import {
	HandleInvitePayload,
	UpdateUserPayload,
	UserActions,
	UserActionsTypes,
	UserLoginPayload,
	UserPayload,
	UserRegisterPayload,
	validJWT,
} from './types'
import { NotificationsActions } from '../notifications/types'
import { ProfileModel } from '../../models/profile'
import { ChatsActions } from '../chats/types'
import { UserGuidModel } from '../../models/guid'
import { TeamsActions } from '../teams/types'
import { forgotPassword } from 'services/firebase'
import { NotificationModel } from '../../models/notification'

function* LOGIN(action: UserActionsTypes) {
	const { email, password } = action.payload as UserLoginPayload
	try {
		yield call(firebase.login, email.trim(), password)
		yield call(firebase.currentAccount)
		const userData: UserPayload = yield call(login)
		if (!userData) throw new Error('User not found')
		yield put({
			type: UserActions.LOAD_CURRENT_ACCOUNT_SUCCESS.toString(),
			payload: userData,
		})
		history.push('/')
		// notification.success({
		//   message: 'Logged In',
		//   description: 'You have successfully logged in!',
		// })
	} catch (error) {
		notification.warning({
			message: error.message,
		})
	}
}

function* FORGOT_PASSWORD(action: UserActionsTypes) {
	const email = action.payload as string
	try {
		yield call(firebase.forgotPassword, email)
		yield put({
			type: UserActions.FORGOT_PASSWORD_SUCCESS.toString(),
		})
		notification.success({
			message: 'Password Reset',
			description: 'Check your email for a password reset link',
		})
		history.push('/auth/login')
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.FORGOT_PASSWORD_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* DISCORD_LOGIN(action: UserActionsTypes) {
	const token = action.payload as string
	try {
		const jwtPayload: JwtPayload | string = jwt.verify(token, 'YJMfaksdr8hRS2')
		// @ts-ignore
		yield call(firebase.discordLogin, jwtPayload.firebaseToken)
		yield call(firebase.currentAccount)
		const userData: UserPayload = yield call(login)

		yield put({
			type: UserActions.LOAD_CURRENT_ACCOUNT_SUCCESS.toString(),
			payload: userData,
		})
		history.push('/')
	} catch (error) {
		notification.warning({
			message: error.message,
		})
	}
}

function* REGISTER(action: UserActionsTypes) {
	const { email, password, username } = action.payload as UserRegisterPayload
	try {
		yield call(firebase.register, email, password, username)
		// @ts-ignore
		const user = yield call(register, email, username)
		const successPayload: UserPayload = {
			...user,
			authorized: true,
			status: 'valid', // false is default value
		}
		yield put({
			type: UserActions.REGISTER_SUCCESS.toString(),
			payload: successPayload,
		})
		history.push('/')
		notification.success({
			message: 'Succesful Registered',
			description: 'You have successfully registered!',
		})
		yield put({
			type: NotificationsActions.GET_NOTIFICATIONS.toString(),
		})
	} catch (e) {
		console.log('REGISTRATION ERROR', e.message)
		notification.warning({
			message: e.code,
			description: e.message,
		})
		yield put({
			type: UserActions.REGISTER_FAILED.toString(),
			payload: e.message,
		})
	}
}

function* LOAD_CURRENT_ACCOUNT() {
	const { user } = yield select(state => state)
	try {
		if (user.refId) {
			yield call(firebase.currentAccount)
			// @ts-ignore
			const userData = yield call(login)
			yield put({
				type: UserActions.LOAD_CURRENT_ACCOUNT_SUCCESS.toString(),
				payload: userData,
			})
		}
	} catch (error) {
		yield put({
			type: UserActions.LOAD_CURRENT_ACCOUNT_FAILED.toString(),
			payload: {
				loading: false,
				error: error.message,
			},
		})
		yield put({
			type: UserActions.LOGOUT.toString(),
		})
	}
}

function* LOGOUT() {
	yield call(firebase.logout)
	history.push('/')
	yield put({
		type: UserActions.LOGOUT_SUCCESS.toString(),
	})
}

function* UPDATE_USER(action: UserActionsTypes) {
	const { user } = yield select(state => state)
	const { avatar, banner, userData } = action.payload as UpdateUserPayload
	try {
		if (userData.country) {
			const singleTeam = user.profiles.find((profile: ProfileModel) => profile.team.single)
			yield put({
				type: TeamsActions.EDIT_TEAM.toString(),
				payload: {
					teamData: {
						refId: singleTeam.team.refId,
						country: userData.country,
					},
				},
			})
		}
		if (avatar) {
			// @ts-ignore
			const userAvatar = yield call(uploadImage, 'users', 'avatar', user.refId, avatar!)
			userData.avatar = userAvatar.file
		}
		if (banner) {
			// @ts-ignore
			const userBanner = yield call(uploadImage, 'users', 'banner', user.refId, banner!)
			userData.banner = userBanner.file
		}
		const cleanData = removeEmpty(userData)
		const userSuccess: UserPayload = yield call(updateUser, cleanData)

		yield put({
			type: UserActions.UPDATE_USER_SUCCESS.toString(),
			payload: userSuccess,
		})
		notification.success({
			message: 'Updated successfully',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.UPDATE_USER_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* SET_ACTIVE_PROFILE(action: UserActionsTypes) {
	const activeProfile = action.payload as ProfileModel
	try {
		yield call(updateUser, { activeProfile: activeProfile._id })
		yield put({
			type: UserActions.SET_ACTIVE_PROFILE_SUCCESS.toString(),
			payload: activeProfile,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.SET_ACTIVE_PROFILE_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* HANDLE_INVITE(action: UserActionsTypes) {
	const invite = action.payload as HandleInvitePayload
	const { notifications, user } = yield select(state => state)
	try {
		const newUserProfile: ProfileModel = yield call(handleInvite, invite)
		if (invite.status === 'ACCEPT') {
			yield put({
				type: UserActions.SET_ACTIVE_PROFILE.toString(),
				payload: newUserProfile,
			})
			user.profiles = [...new Set([...user.profiles, newUserProfile])]
			yield put({
				type: UserActions.UPDATE_USER_SUCCESS.toString(),
				payload: user,
			})
			notification.success({
				message: 'Invite accepted successfully',
			})
		} else {
			notification.info({
				message: 'Invite declined',
			})
		}

		const filteredNotifications = notifications.notifications.filter(
			(n: NotificationModel) => n.relatedId !== invite.refId,
		)

		yield put({
			type: NotificationsActions.GET_NOTIFICATIONS_SUCCESS.toString(),
			payload: filteredNotifications,
		})
		yield put({
			type: UserActions.HANDLE_INVITE_SUCCESS.toString(),
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.HANDLE_INVITE_FAILED.toString(),
			payload: error.message,
		})
	}
}

function* LOAD_CURRENT_ACCOUNT_SUCCESS(action: UserActionsTypes) {
	try {
		yield put({
			type: NotificationsActions.GET_NOTIFICATIONS.toString(),
		})
		yield put({
			type: ChatsActions.SUBSCRIBE_TO_ALL_CHATS.toString(),
		})
		yield put({
			type: NotificationsActions.SUBSCRIBE_TO_ALL_NOTIFICATIONS.toString(),
		})
	} catch (e) {
		console.log(e)
	}
}

function* GET_USER() {
	try {
		// @ts-ignore
		const userData = yield call(getUser)
		yield put({
			type: UserActions.GET_USER_SUCCESS.toString(),
			payload: userData,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.GET_USER_FAILED.toString(),
			payload: error,
		})
	}
}

function* ADD_USER_GUID(action: UserActionsTypes) {
	const guids = action.payload as UserGuidModel[]
	try {
		yield all(guids.map(guid => call(addUserGuid, guid)))
		yield put({
			type: UserActions.GET_USER.toString(),
		})
		yield put({
			type: UserActions.ADD_USER_GUID_SUCCESS.toString(),
		})
		notification.success({
			message: 'Game guid added.',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.ADD_USER_GUID_FAILED.toString(),
			payload: error,
		})
	}
}

function* CHANGE_USER_PASSWORD(action: UserActionsTypes) {
	const password = action.payload as string
	try {
		yield call(firebase.updateUserPassword, password)
		yield put({
			type: UserActions.CHANGE_USER_PASSWORD_SUCCESS.toString(),
		})
		notification.success({
			message: 'Password updated successfully.',
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.CHANGE_USER_PASSWORD_FAILED.toString(),
			payload: error,
		})
	}
}

function* GET_USER_GUIDS() {
	try {
		// @ts-ignore
		const userGuids = yield call(getUserGuids)
		yield put({
			type: UserActions.GET_USER_GUIDS_SUCCESS.toString(),
			payload: userGuids,
		})
	} catch (error) {
		notification.warning({
			message: error.message,
		})
		yield put({
			type: UserActions.GET_USER_GUIDS_FAILED.toString(),
			payload: error,
		})
	}
}

//
// function* saveSingleGuid(guid: UserGuidModel) {
//   try {
//     yield call(addUserGuid, guid)
//   } catch (error) {
//     console.log('FAILED TO SAVE SINGLE GUID')
// yield put({
//   type: UserActions.ADD_USER_GUID_FAILED.toString(),
//   payload: error,
// })
//   }
// }

export default function* rootSaga() {
	yield all([
		takeEvery(UserActions.LOGIN, LOGIN),
		takeEvery(UserActions.DISCORD_LOGIN, DISCORD_LOGIN),
		takeEvery(UserActions.GET_USER, GET_USER),
		takeEvery(UserActions.REGISTER, REGISTER),
		takeEvery(UserActions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
		takeEvery(UserActions.LOGOUT, LOGOUT),
		takeEvery(UserActions.UPDATE_USER, UPDATE_USER),
		takeEvery(UserActions.HANDLE_INVITE, HANDLE_INVITE),
		takeLatest(UserActions.SET_ACTIVE_PROFILE, SET_ACTIVE_PROFILE),
		takeLatest(UserActions.LOAD_CURRENT_ACCOUNT_SUCCESS, LOAD_CURRENT_ACCOUNT_SUCCESS),
		takeLatest(UserActions.ADD_USER_GUID, ADD_USER_GUID),
		takeLatest(UserActions.GET_USER_GUIDS, GET_USER_GUIDS),
		takeLatest(UserActions.CHANGE_USER_PASSWORD, CHANGE_USER_PASSWORD),
		takeLatest(UserActions.FORGOT_PASSWORD, FORGOT_PASSWORD),
		LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
	])
}
