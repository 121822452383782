import actionCreatorFactory from 'typescript-fsa'
import { PollModel } from '../../models/polls'

const actionCreator = actionCreatorFactory()

export interface InitialStateType {
	currentPoll: PollModel | null
	loading: boolean
	error: unknown | null
}

export interface ErrorPayload {
	loading: boolean
	error: string | null
}

export interface VotePayload {
	pollId: string
	optionId: string
}

export enum ActionsTypes {
	GET_POLL = 'GET_POLL',
	GET_POLL_SUCCESS = 'GET_POLL_SUCCESS',
	GET_POLL_FAILED = 'GET_POLL_FAILED',
	VOTE_POLL = 'VOTE_POLL',
	VOTE_POLL_SUCCESS = 'VOTE_POLL_SUCCESS',
	VOTE_POLL_FAILED = 'VOTE_POLL_FAILED',
}

export const PollsActions = {
	GET_POLL: actionCreator<string>(ActionsTypes.GET_POLL),
	GET_POLL_SUCCESS: actionCreator<PollModel>(ActionsTypes.GET_POLL_SUCCESS),
	GET_POLL_FAILED: actionCreator<ErrorPayload>(ActionsTypes.GET_POLL_FAILED),
	VOTE_POLL: actionCreator<VotePayload>(ActionsTypes.VOTE_POLL),
	VOTE_POLL_SUCCESS: actionCreator<PollModel>(ActionsTypes.VOTE_POLL_SUCCESS),
	VOTE_POLL_FAILED: actionCreator<ErrorPayload>(ActionsTypes.VOTE_POLL_FAILED),
}

export type PollsActionsTypes =
	| ReturnType<typeof PollsActions.GET_POLL>
	| ReturnType<typeof PollsActions.GET_POLL_SUCCESS>
	| ReturnType<typeof PollsActions.GET_POLL_FAILED>
	| ReturnType<typeof PollsActions.VOTE_POLL>
	| ReturnType<typeof PollsActions.VOTE_POLL_SUCCESS>
	| ReturnType<typeof PollsActions.VOTE_POLL_FAILED>
