import localeAntd from 'antd/es/locale/en_US'

const messages = {
	'topBar.issuesHistory': 'Issues History',
	'topBar.projectManagement': 'Project Management',
	'topBar.typeToSearch': 'Search...',
	'topBar.findPages': 'Find pages...',
	'topBar.actions': 'Actions',
	'topBar.status': 'Status',
	'topBar.profileMenu.hello': 'Hello',
	'topBar.profileMenu.billingPlan': 'Billing Plan',
	'topBar.profileMenu.role': 'Role',
	'topBar.profileMenu.email': 'Email',
	'topBar.profileMenu.createTeam': 'Create Team',
	'topBar.profileMenu.phone': 'Phone',
	'topBar.profileMenu.editProfile': 'Edit Profile',
	'topBar.profileMenu.logout': 'Logout',
}

export default {
	locale: 'en-US',
	localeAntd,
	messages,
}
