import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { InitialStateType, PollsActions } from './types'

const initialState: InitialStateType = {
	currentPoll: null,
	loading: false,
	error: null,
}

const pollsReducer = reducerWithInitialState(initialState)
	// SUCCESS CASES
	.case(PollsActions.GET_POLL, state => ({
		...state,
		loading: true,
	}))
	.case(PollsActions.VOTE_POLL, state => ({ ...state, loading: true }))
	// SUCCESS CASES
	.case(PollsActions.VOTE_POLL_SUCCESS, (state, currentPoll) => ({
		...state,
		currentPoll,
		loading: false,
	}))
	.case(PollsActions.GET_POLL_SUCCESS, (state, currentPoll) => ({
		...state,
		currentPoll,
		loading: false,
	}))

	// FAILED CASES
	.case(PollsActions.VOTE_POLL_FAILED, (state, error) => ({ ...state, error, loading: false }))
	.case(PollsActions.GET_POLL_FAILED, (state, error) => ({ ...state, error, loading: false }))
	// DEFAULT CASE - Return current state
	.default((state, action) => state)

export default pollsReducer
